export type HumanFriendlyError = {
  title: string;
  message: string;
};

export const SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS: Record<
  string,
  HumanFriendlyError
> = {
  HOME_SCREEN_NOT_FOUND: {
    title: 'Home Screen Unavailable',
    message:
      "We're sorry, but we couldn't load Jane home screen. Please try refreshing the page.",
  },
  NOT_LOGGED_IN: {
    title: 'Jane Not Authorized',
    message:
      'It appears you are not signed into your Jane app account. Please sign in to Jane to complete signing the patient chart.',
  },
  SOAP_TEMPLATE_NOT_FOUND: {
    title: 'Template Missing',
    message:
      'Template not found: "SOAP Template". Please check if the template exists and try again.',
  },
  NOTE_EDITOR_NOT_FOUND: {
    title: 'Note Editor Unavailable',
    message:
      "We couldn't locate the note editor within Jane. Please ensure it's present and try again.",
  },
  FAILED_TO_SIGN: {
    title: 'Chart Entry Signature Failed',
    message:
      'We encountered an issue while signing the chart entry. Please try again.',
  },
  GENERIC_ERROR: {
    title: 'Unexpected Error',
    message:
      'An unexpected error occurred while signing the chart entry. Please try again.',
  },
  TIMEOUT: {
    title: 'Request Timed Out',
    message:
      'Your request took too long to process. Please check your internet connection and try again.',
  },
} as const;
