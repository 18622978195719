import { FCC } from './types';
import { H1, H3 } from './typography';
import { cn } from './cn';

export const PageTitle: FCC<{ className?: string; subtitle?: string }> = ({
  children,
  subtitle,
  className,
}) => {
  return (
    <div className="py-2 my-3">
      <H1 className={cn('text-gray-11', className)}>
        <div>{children}</div>
      </H1>
      {subtitle && <H3 className="text-gray-10 font-thin">{subtitle}</H3>}
    </div>
  );
};
