import { SummarySectionMarkdown, TranscriptionCard } from '@eluve/blocks';
import { P, Tabs, TabsContent, TabsList, TabsTrigger } from '@eluve/components';
import {
  SummaryFeedbackModal,
  useAppointmentActivity,
  useAppointmentStore,
} from '@eluve/smart-blocks';
import { AppointmentSummaryKeys } from '@eluve/utils';
import { Loader2 } from 'lucide-react';
import React from 'react';
import { appConfig } from '../../../../../config';
import { BillingCodesSection } from './BillingCodesSection';
import { useAppointmentStatus } from './useAppointmentStatus';
import {
  SUMMARY_TAB,
  TRANSCRIPT_TAB,
  useAppointmentTabNav,
} from './useAppointmentTabNav';
import { useAppointmentTranscription } from './useAppointmentTranscription';
import { useEditSummary } from './useEditSummary';
import { useSummary } from './useSummary';

export interface TranscriptAndSummaryProps {}

export const TranscriptAndSummary: React.FC<TranscriptAndSummaryProps> = () => {
  // URL state
  const { tab: activeTab, setTab } = useAppointmentTabNav();

  // client state
  const appointmentId = useAppointmentStore((state) => state.appointmentId);
  const isInSession = useAppointmentStore((state) => state.isInSession);
  const isUploadingFile = useAppointmentStore((state) => state.isUploadingFile);

  // backend state
  const appointmentStatus = useAppointmentStatus();

  const { isSummarizationInProgress, isTranscriptionInProgress } =
    useAppointmentActivity();

  const transcript = useAppointmentTranscription();

  const summary = useSummary()?.summary ?? {};
  const editSummary = useEditSummary();

  const showFeedback = !isInSession && !isUploadingFile;

  if (appointmentStatus === 'NOT_STARTED') {
    return null;
  }

  return (
    <Tabs value={activeTab ?? undefined}>
      <TabsList className="w-full">
        <TabsTrigger
          value={TRANSCRIPT_TAB}
          onClick={() => setTab(TRANSCRIPT_TAB)}
          className="px-10 m-2 bg-gray-4"
        >
          Transcript
        </TabsTrigger>

        <TabsTrigger
          value={SUMMARY_TAB}
          onClick={() => setTab(SUMMARY_TAB)}
          disabled={
            appointmentStatus !== 'COMPLETED' ||
            isSummarizationInProgress ||
            isTranscriptionInProgress
          }
          className="px-10 m-2 bg-gray-4"
        >
          Summary
          {isSummarizationInProgress && (
            <Loader2 className="animate-spin h-5 text-brand-8" />
          )}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="transcript">
        <div className="flex flex-row gap-3 w-full">
          <TranscriptionCard
            transcript={transcript}
            transcriptionInProgress={isInSession || isTranscriptionInProgress}
          />
        </div>
      </TabsContent>
      <TabsContent value="summary">
        <div className="flex flex-col gap-2 w-full">
          {Object.entries(summary).map(([summaryKey, summaryValue]) => {
            return (
              <SummarySectionMarkdown
                className="summary-section-markdown-container"
                key={summaryKey}
                summaryKey={summaryKey as AppointmentSummaryKeys}
                sectionTitle={summaryKey}
                content={summaryValue ?? ''}
                loading={false}
                disabled={false}
                handleContentChange={(key, content) => {
                  editSummary(key, content);
                }}
                billingCodesEnabled={appConfig.VITE_BILLING_CODES_ENABLED}
              />
            );
          })}

          <BillingCodesSection />

          {showFeedback && (
            <div className="flex flex-row w-full justify-end items-center gap-2">
              <P className="text-gray-10 font-normal">
                Was this medical summary helpful?
              </P>
              <SummaryFeedbackModal appointmentId={appointmentId} />
            </div>
          )}
        </div>
      </TabsContent>
    </Tabs>
  );
};
