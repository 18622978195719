import { useFragment, useMutation } from '@apollo/client';
import { useCacheIdBuilder } from '@eluve/apollo-client';
import {
  AdminTenantFeatureFlagFragmentDoc,
  SetTenantFeatureFlagDocument,
} from '@eluve/client-gql-operations';
import { Switch, useToast } from '@eluve/components';
import { FeatureFlagsEnum } from '@eluve/graphql-types';
import React from 'react';

export interface TenantFeatureFlagToggleProps {
  tenantId: string;
  flag: FeatureFlagsEnum;
}

export const TenantFeatureFlagToggle: React.FC<
  TenantFeatureFlagToggleProps
> = ({ tenantId, flag }) => {
  const { toast } = useToast();

  const from = useCacheIdBuilder()({
    typeName: 'TenantFeatureFlags',
    key: {
      tenantId,
      flag,
    },
  });

  const [updateFeatureFlag] = useMutation(SetTenantFeatureFlagDocument, {
    optimisticResponse: (data) => ({
      insertTenantFeatureFlagsOne: {
        __typename: 'TenantFeatureFlags' as const,
        ...data,
      },
    }),
  });

  const submit = async (isEnabled: boolean) => {
    try {
      await updateFeatureFlag({
        context: {
          headers: {
            'x-hasura-role': 'eluve-admin',
          },
        },
        variables: {
          tenantId,
          flag,
          isEnabled,
        },
      });
    } catch (e) {
      toast({
        title: 'Error',
        variant: 'destructive',
      });
    }
  };

  const { data } = useFragment({
    fragment: AdminTenantFeatureFlagFragmentDoc,
    from,
  });

  return (
    <Switch
      onCheckedChange={(isChecked) => submit(isChecked)}
      checked={data?.isEnabled ?? false}
    />
  );
};
