import { useFragment } from '@apollo/client';
import { AppointmentActiveStateFragmentDoc } from '@eluve/client-gql-operations';
import { useAppointmentCacheId } from './appointment.context';

export const useAppointmentActivity = () => {
  const cacheId = useAppointmentCacheId();

  const { data, complete } = useFragment({
    fragment: AppointmentActiveStateFragmentDoc,
    from: cacheId,
  });

  if (!complete) {
    throw Error('Missing appointment status fragment');
  }

  const {
    isSummarizationInProgress,
    isTranscriptionInProgress,
    isPendingAudioFileUpload,
  } = data;

  return {
    isSummarizationInProgress: isSummarizationInProgress ?? false,
    isTranscriptionInProgress:
      Boolean(isTranscriptionInProgress) || Boolean(isPendingAudioFileUpload),
  };
};
