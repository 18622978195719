import { FeatureFlagsEnum } from '@eluve/graphql-types';
import { useParams } from 'react-router-dom';
import { useCacheIdBuilder } from '@eluve/apollo-client';
import { useFragment } from '@apollo/client';
import { TenantFeatureFlagFragmentDoc } from '@eluve/client-gql-operations';
import { FCC } from '@eluve/components';

export const FeatureFlagged: FCC<{
  flag: FeatureFlagsEnum;
}> = ({ children, flag }) => {
  const { tenantId } = useParams() as { tenantId: string };

  const from = useCacheIdBuilder()({
    typeName: 'TenantFeatureFlags',
    key: {
      tenantId,
      flag,
    },
  });

  const { data } = useFragment({
    from,
    fragment: TenantFeatureFlagFragmentDoc,
  });

  const isEnabled = data?.isEnabled ?? false;

  if (!isEnabled) {
    return null;
  }

  return children;
};
