type SoundBarsProps = {
  value: number;
};

// Takes in a value between 0 and 100 and
// displays 10 gray or green bars based on the value
// ie, 2 bars filled green if value is 22
export const SoundBars: React.FC<SoundBarsProps> = ({
  value,
}: SoundBarsProps) => {
  const bars = Array.from({ length: 10 }, (_, index) => (
    <div
      key={index}
      className={`flex-1 h-full rounded-sm ${
        value >= index * 10 + 1 ? 'bg-green-500' : 'bg-gray-300'
      } mr-1 w-1`}
    ></div>
  ));

  return <div className="flex w-full h-5">{bars}</div>;
};
