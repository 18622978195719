import { openDB, IDBPDatabase } from 'idb';

type BillingCode = {
  code: string;
  description: string;
  identifier: string;
};

type BillingCodes = BillingCode[];

interface DB {
  BillingCodes: {
    key: string;
    value: BillingCodes;
  };
}

class BillingCodesDB {
  private memoryCachedBillingCodes: BillingCodes | null = null;
  private db: IDBPDatabase<DB> | null = null;

  constructor(
    private readonly id: number,
    private readonly url: string,
  ) {}

  get = async (): Promise<BillingCodes> => {
    const cachedBillingCodes = await this.getCachedBillingCodes();
    if (cachedBillingCodes) {
      return cachedBillingCodes;
    }
    const fetchedBillingCodes = await this.fetchBillingCodes();
    this.cacheBillingCodes(fetchedBillingCodes);
    return fetchedBillingCodes;
  };

  private memoryCacheBillingCodes = async (
    billingCodes: BillingCodes,
  ): Promise<void> => {
    this.memoryCachedBillingCodes = billingCodes;
  };

  private getCachedBillingCodes = async (): Promise<BillingCodes | null> => {
    if (this.memoryCachedBillingCodes) {
      return this.memoryCachedBillingCodes;
    }
    const dbCachedBillingCodes = await this.getAllFromDB();
    if (dbCachedBillingCodes) {
      this.memoryCacheBillingCodes(dbCachedBillingCodes);
    }
    return dbCachedBillingCodes;
  };

  private cacheBillingCodes = async (
    billingCodes: BillingCodes,
  ): Promise<void> => {
    this.memoryCacheBillingCodes(billingCodes);
    this.putAllIntoDb(billingCodes);
  };

  private putAllIntoDb = async (billingCodes: BillingCodes) => {
    const db = await this.getDB();
    const tx = db.transaction('billingCodes', 'readwrite');
    await Promise.all([
      ...Object.values(billingCodes).map((bc) => {
        tx.store.add(bc);
      }),
      tx.done,
    ]);
  };

  private getAllFromDB = async (): Promise<BillingCodes | null> => {
    const db = await this.getDB();
    const tx = db.transaction('billingCodes', 'readonly');
    const store = tx.objectStore('billingCodes');
    const data = (await store.getAll()) as BillingCodes;
    return data?.length ? data : null;
  };

  private getDB = async (): Promise<IDBPDatabase<DB>> => {
    if (!this.db) {
      this.db = await openDB<DB>('BillingCodes', this.id, {
        upgrade(db, oldVersion) {
          if (oldVersion) {
            db.deleteObjectStore('billingCodes');
          }
          const store = db.createObjectStore('billingCodes', {
            keyPath: 'id',
            autoIncrement: true,
          });
          store.createIndex('code', 'code');
        },
      });
    }
    return this.db;
  };

  private fetchBillingCodes = async (): Promise<BillingCode[]> => {
    const response = await fetch(this.url);
    return (await response.json()) as BillingCode[];
  };
}

export const billingCodesDB = new BillingCodesDB(
  20240102,
  'https://storage.googleapis.com/eluve-sandbox-billing-codes/billing-codes.small.json',
);
