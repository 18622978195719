'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { TsRestClient, createTsRestClient } from './client';

const apiClientContext = createContext<TsRestClient | undefined>(undefined);

export const useApiClient = () => {
  const context = useContext(apiClientContext);
  if (!context) {
    throw Error('Tried to access ApiClientContext outside of provider');
  }
  return context;
};

const { Provider } = apiClientContext;

export const ApiClientContextProvider: React.FC<{
  baseUrl: string;
  children: React.ReactNode;
}> = ({ baseUrl, children }) => {
  const value = useMemo(() => {
    return createTsRestClient(baseUrl);
  }, [baseUrl]);

  return <Provider value={value}>{children}</Provider>;
};
