import { TypePolicies } from '@apollo/client';
import { TypeSafePolicies } from './types';
import { Query_Root } from '@eluve/graphql-types';

type CapitalizeKeys<T> = {
  [K in keyof T as Capitalize<string & K>]: T[K];
};

const policies: TypeSafePolicies<CapitalizeKeys<Query_Root>> = {
  AppointmentDoctorInteractions: {
    keyFields: ['appointmentId'],
  },
  TenantFeatureFlags: {
    keyFields: ['tenantId', 'flag'],
  },
  TenantUsers: {
    keyFields: ['tenantId', 'userId'],
  },
};

export const typePolicies = policies as TypePolicies;
