import { useApolloClient, useSubscription } from '@apollo/client';
import {
  AppointmentTranscriptionFragmentDoc,
  ListenAppointmentTranscriptStreamDocument,
  MaxChunkNumFragmentDoc,
} from '@eluve/client-gql-operations';
import { useMemo, useRef } from 'react';
import maxBy from 'lodash/maxBy';
import { useAppointmentCacheId, useAppointmentId } from '@eluve/smart-blocks';

/**
 * Listens to the new chunks in real time using a streaming subscription and updates the transcript
 * in apollo cache so that other components can easily use a fragment to read the full transcript
 * without having to do any other work
 */
export const useAppointmentTranscriptionSync = () => {
  const maxObservedChunk = useRef(-1);
  const appointmentId = useAppointmentId();
  const id = useAppointmentCacheId();
  const client = useApolloClient();
  const cache = client.cache;

  const initialValue = useMemo(() => new Date(0).toISOString(), []);

  useSubscription(ListenAppointmentTranscriptStreamDocument, {
    variables: {
      appointmentId,
      initialValue,
    },
    onData: ({ data }) => {
      // Chunk comparison here is in place for HMR during development.
      // When HMR occurs, the subscription will fire again with the same values
      // and we don't want to append the same transcript twice in development.
      // This should not be a problem in a deployed environment
      const transcripts = data?.data?.transcriptsStream ?? [];
      const maxChunk = maxBy(transcripts, (t) => t.chunkNum)?.chunkNum ?? -1;

      const newChunks = transcripts.filter(
        (t) => t.chunkNum! > maxObservedChunk.current,
      );

      const combined = newChunks.map((t) => t.transcript ?? '').join(' ');

      if (combined.length > 0) {
        maxObservedChunk.current = maxChunk;
      }

      // Update the transcript in the cache by appending the new chunk data
      cache.updateFragment(
        {
          fragment: AppointmentTranscriptionFragmentDoc,
          id,
        },
        (existing) => {
          if (!existing) {
            return existing;
          }

          return {
            ...existing,
            transcript: [existing.transcript, combined]
              .filter(Boolean)
              .join(' ')
              .trim(),
          };
        },
      );

      // Update the latest observed chunk number in the cache
      cache.updateFragment(
        {
          fragment: MaxChunkNumFragmentDoc,
          id,
        },
        () => {
          return {
            __typename: 'Appointments' as const,
            transcriptsAggregate: {
              __typename: 'TranscriptsAggregate' as const,
              aggregate: {
                max: {
                  chunkNum: maxChunk,
                },
              },
            },
          };
        },
      );
    },
  });
};
