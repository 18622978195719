import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  useToast,
} from '@eluve/components';
import {
  TenantUserRoleFragmentDoc,
  UpdateUserTenantRoleDocument,
} from '@eluve/client-gql-operations';
import { useFragment, useMutation } from '@apollo/client';
import { useCacheIdBuilder } from '@eluve/apollo-client';

type RoleSelect = {
  tenantId: string;
  userId: string;
};

const useTenantUserRole = (tenantId: string, userId: string) => {
  const from = useCacheIdBuilder()({
    typeName: 'TenantUsers',
    key: {
      tenantId,
      userId,
    },
  });

  const { data } = useFragment({
    fragment: TenantUserRoleFragmentDoc,
    from,
  });

  return data?.role ?? '';
};

export const RoleSelector: React.FC<RoleSelect> = ({ tenantId, userId }) => {
  const { toast } = useToast();
  const [updateUserTenantRole] = useMutation(UpdateUserTenantRoleDocument, {
    optimisticResponse: ({ tenantId, userId, role: newRole }) => {
      return {
        updateTenantUsersByPk: {
          __typename: 'TenantUsers' as const,
          tenantId,
          userId,
          role: newRole,
        },
      };
    },
  });

  const updateRole = async (
    tenantId: string,
    userId: string,
    newRole: string,
  ) => {
    try {
      await updateUserTenantRole({
        variables: {
          tenantId,
          userId,
          role: newRole,
        },
      });

      toast({
        duration: 2000,
        title: 'Updated User Role',
        variant: 'success',
      });
    } catch (e) {
      toast({
        duration: 2000,
        title: 'Error',
        description: 'There was an error updating the user role',
        variant: 'destructive',
      });
    }
  };

  const tenantRole = useTenantUserRole(tenantId, userId);

  return (
    <div className="flex justify-center">
      <Select
        value={tenantRole}
        onValueChange={(newRole: string) => {
          if (newRole === tenantRole) {
            return;
          }
          return updateRole(tenantId, userId, newRole);
        }}
      >
        <SelectTrigger className="text-gray-12">{tenantRole}</SelectTrigger>
        <SelectContent>
          <SelectItem className="focus:bg-brand-4" value="tenant-admin">
            tenant-admin
          </SelectItem>
          <SelectItem className="focus:bg-brand-4" value="tenant-member">
            tenant-member
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
