import { FCC, H5, cn } from '@eluve/components';
import { add, format, startOfDay } from 'date-fns';
import sortBy from 'lodash/sortBy';
import React, { useEffect } from 'react';

export type AgendaItem = {
  id: string;
  name: string;
  description?: string;
  start: Date;
  end: Date;
  isSummarized?: boolean;
};

export interface DailyAgendaProps {
  agendaItems?: AgendaItem[];
  startingHour?: number;
  endingHour?: number;
  Link?: FCC<AgendaItem>;
}

const AgendaBlock: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'border-t border-gray-6 mt-4 flex flex-col gap-2 p-2 mr-2',
        className,
      )}
    >
      {children}
    </div>
  );
};

const AgendaItem: React.FC<{
  name: string;
  description?: string;
  duration: string;
  className?: string;
  isSummarized?: boolean;
}> = ({ name, description, duration, className = '', isSummarized }) => {
  return (
    <div
      className={cn(
        'bg-gray-3 flex justify-between p-2 rounded-sm cursor-pointer',
        className,
      )}
    >
      <div>
        <div className="flex flex-row gap-0.5 ">
          <H5>{name?.length ? name : 'Patient Not Specified'}</H5>
          {isSummarized && (
            <div className="w-1.5 h-1.5 bg-green-700 rounded-full mt-1" />
          )}
        </div>
        <p className="font-light text-sm text-gray-11">{description}</p>
      </div>
      <div className="font-light text-sm text-gray-11">{duration}</div>
    </div>
  );
};

const TimeLabel: React.FC<{
  hour: number;
  children: React.ReactNode;
}> = ({ hour, children }) => {
  return (
    <div
      id={`agenda-time-label-${hour}`}
      className={cn(
        'whitespace-nowrap text-sm p-2 text-center mr-1 ml-2 text-gray-9',
        hour >= 6 && hour <= 20 && 'text-gray-11 font-semibold',
      )}
    >
      {children}
    </div>
  );
};

// Tailwind generates a minimum style-sheet of only the class names that are
// actually used in code. This means to ensure that the classes we need at runtime
// are always available, we need to include them in the code.
// A bit hacky but still way more performant than CSS-in-JS
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rows = [
  'row-end-[5]',
  'row-end-[6]',
  'row-end-[7]',
  'row-end-[8]',
  'row-end-[9]',
  'row-end-[10]',
  'row-end-[11]',
  'row-end-[12]',
  'row-end-[13]',
  'row-end-[14]',
  'row-end-[15]',
  'row-end-[16]',
  'row-end-[17]',
  'row-end-[18]',
  'row-end-[19]',
  'row-end-[20]',
  'row-end-[21]',
  'row-end-[22]',
  'row-end-[23]',
  'row-end-[24]',
  'row-end-[25]',
  'row-end-[26]',
];

export const DailyAgenda: React.FC<DailyAgendaProps> = ({
  startingHour = 0,
  endingHour = 23,
  agendaItems = [],
  Link = ({ children }) => children,
}) => {
  const seedDate = startOfDay(new Date());

  const numEntries = endingHour - startingHour;
  const timeLabels = Array.from(
    { length: numEntries + 1 },
    (_, i) => i + startingHour,
  );

  // scroll to 6am on load
  useEffect(() => {
    const element = document.getElementById('agenda-time-label-6');
    if (element) {
      element.scrollIntoView();
    }
  }, []);

  return (
    <div className="">
      <div className="grid gap-2 grid-cols-[min-content_min-content_1fr] w-full">
        <div
          className={cn(
            'row-start-1 col-start-2 w-[0.5px] bg-gray-6',
            `row-end-[${numEntries + 2}]`,
          )}
        ></div>
        {timeLabels.map((hour, i) => {
          // TODO(jesse): Still need to handle the case where an appointment could be longer than an hour
          // Find the appointments that start at this hour
          const relevantAppointments = sortBy(
            agendaItems.filter((a) => a.start.getHours() === hour),
            (a) => a.start,
          );

          return (
            <React.Fragment key={i}>
              <TimeLabel hour={hour}>
                {format(add(seedDate, { hours: hour }), 'h a')}
              </TimeLabel>
              <AgendaBlock>
                {relevantAppointments.map((agendaItem) => {
                  return (
                    <Link {...agendaItem} key={agendaItem.id}>
                      <AgendaItem
                        key={agendaItem.id}
                        name={agendaItem.name}
                        description={agendaItem.description}
                        duration={`${format(agendaItem.start, 'p')} - ${format(
                          agendaItem.end,
                          'p',
                        )}`}
                        isSummarized={agendaItem.isSummarized}
                      />
                    </Link>
                  );
                })}
              </AgendaBlock>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
