'use client';
import { useMutation, useQuery } from '@apollo/client';
import { FeedbackModal, Tag } from '@eluve/blocks';
import {
  GetFeedbackTagsDocument,
  InsertSummaryFeedbackDocument,
  InsertSummaryFeedbackTagsDocument,
  UpdateSummaryFeedbackDocument,
} from '@eluve/client-gql-operations';

import { FeedbackTypeEnum } from '@eluve/graphql-types';

import React, { useEffect, useState } from 'react';

export interface SummaryFeedbackModalProps {
  appointmentId: string;
  summarySection?: string;
}

export type SummaryFeedbackTags = Tag & { type: FeedbackTypeEnum | null };

export const SummaryFeedbackModal: React.FC<SummaryFeedbackModalProps> = ({
  appointmentId,
  summarySection,
}) => {
  const [tags, setTags] = useState<SummaryFeedbackTags[]>([]);
  const [selectedFeedbackType, setFeedbackType] =
    useState<FeedbackTypeEnum | null>(null);

  const [feedbackId, setFeedbackId] = useState<string>('');

  const { data: getFeedbackTagsData } = useQuery(GetFeedbackTagsDocument);

  const [insertFeedbackMutation] = useMutation(InsertSummaryFeedbackDocument);
  const [updateFeedbackMutation] = useMutation(UpdateSummaryFeedbackDocument);
  const [insertFeedbackTagMutation] = useMutation(
    InsertSummaryFeedbackTagsDocument,
  );

  useEffect(() => {
    setTags(
      getFeedbackTagsData?.feedbackTags?.map((tag) => ({
        ...tag,
        isTagged: false,
      })) ?? [],
    );
  }, [getFeedbackTagsData]);

  const insertFeedback = async (
    type: FeedbackTypeEnum,
    comment?: string,
    section?: string,
  ) => {
    const { data } = await insertFeedbackMutation({
      variables: {
        appointmentId,
        type,
        comment: comment ?? null,
        summarySection: section ?? null,
      },
    });

    setFeedbackId(data?.insertSummaryFeedbackOne?.id ?? '');
  };

  const onFeedbackClick = async (type: FeedbackTypeEnum) => {
    setFeedbackType(type);

    // save thumbs up / down feedback
    await insertFeedback(type);
  };

  const onTagPressedChange = async (tagId: number, isTagged: boolean) => {
    setTags(
      tags.map((t) => {
        if (t.id === tagId) {
          return { ...t, isTagged };
        }
        return t;
      }),
    );
  };

  const onAdditionalFeedbackSubmit = async (
    type: FeedbackTypeEnum | null,
    comment: string,
    tags: Tag[],
    rating: number | null,
  ) => {
    if (feedbackId) {
      await updateFeedbackMutation({
        variables: {
          id: feedbackId,
          comment,
          summarySection: summarySection ?? null,
          rating,
        },
      });
    } else if (selectedFeedbackType) {
      await insertFeedback(selectedFeedbackType, comment, summarySection);
    }

    if (!feedbackId) {
      console.error('Failed to save feedback');
      return;
    }

    const feedbackTagsInput = tags
      .filter((tag) => tag.isTagged)
      .map((tag) => ({ summaryFeedbackId: feedbackId, feedbackTagId: tag.id }));

    await insertFeedbackTagMutation({
      variables: {
        feedbackTags: feedbackTagsInput,
      },
    });
  };

  return (
    <FeedbackModal
      feedbackType={selectedFeedbackType}
      tags={[
        ...tags.filter((tag) => tag.type === selectedFeedbackType),
        ...tags.filter((tag) => tag.type !== selectedFeedbackType),
      ]}
      onFeedbackClick={onFeedbackClick}
      onTagPressedChange={onTagPressedChange}
      onAdditionalFeedbackSubmit={onAdditionalFeedbackSubmit}
    />
  );
};
