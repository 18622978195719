import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  CreateLayerDocument,
  GetTenantLayersDocument,
  TenantLayersFragmentDoc,
} from '@eluve/client-gql-operations';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layers } from 'lucide-react';
import sortBy from 'lodash/sortBy';
import { Button, FormItem, Input } from '@eluve/components';
import { useCacheIdBuilder } from '@eluve/apollo-client';
import { DepthContainer } from './DepthContainer';
import { TreeNodeContainer } from './TreeNodeContainer';

const OPTIMISTIC_ID = 'optimistic';

const CreateLayer: React.FC<{
  depth: number;
  parentPath: string;
  tenantId: string;
}> = ({ depth, parentPath, tenantId }) => {
  const cacheId = useCacheIdBuilder()({
    typeName: 'Tenants',
    key: tenantId,
  });

  const [isCreatingLayer, setIsCreatingLayer] = useState(false);
  const [newLayerName, setNewLayerName] = useState('');
  const [createLayer] = useMutation(CreateLayerDocument, {
    optimisticResponse: ({ input }) => ({
      insertLayersOne: {
        __typename: 'Layers' as const,
        id: OPTIMISTIC_ID,
        name: input.name!,
        path: `${input.path!}.${OPTIMISTIC_ID}`,
        depth: depth + 1,
      },
    }),
    update: (cache, { data }) => {
      cache.updateFragment(
        {
          fragment: TenantLayersFragmentDoc,
          fragmentName: 'TenantLayers',
          id: cacheId,
        },
        (existing) => {
          if (!existing || !data?.insertLayersOne) {
            return existing;
          }

          return {
            layers: [...existing.layers, data.insertLayersOne],
          };
        },
      );
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async () => {
    if (!newLayerName) {
      return;
    }
    try {
      setIsSubmitting(true);
      await createLayer({
        variables: {
          input: {
            path: parentPath,
            name: newLayerName,
          },
        },
      });
    } catch (e) {
      alert(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DepthContainer depth={depth}>
      {!isCreatingLayer && (
        <button
          type="button"
          className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 px-4 py-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          onClick={() => setIsCreatingLayer(true)}
        >
          <Layers className="mx-auto h-6 w-6 text-gray-400" />
          <span className="mt-2 block text-sm font-semibold text-gray-900">
            Add a new layer
          </span>
        </button>
      )}
      {isCreatingLayer && (
        <div className="w-full p-2">
          <FormItem>
            <Input
              onChange={(e) => setNewLayerName(e.target.value)}
              autoFocus
              onKeyUp={(evt) => {
                if (evt.key === 'Enter') {
                  submit();
                }
              }}
            />
            <div className="flex items-center gap-2">
              <Button onClick={submit} disabled={isSubmitting}>
                Save
              </Button>
              <Button
                variant={'outline'}
                disabled={isSubmitting}
                onClick={() => {
                  setIsCreatingLayer(false);
                  setNewLayerName('');
                }}
              >
                Cancel
              </Button>
            </div>
          </FormItem>
        </div>
      )}
    </DepthContainer>
  );
};

export const TenantLayers: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };
  const { data } = useSuspenseQuery(GetTenantLayersDocument, {
    variables: {
      tenantId,
    },
  });

  const layers = sortBy(data?.tenantsByPk?.layers ?? [], (x) => x.path);
  const bottomLayerPath: string = layers[layers.length - 1]?.path ?? '';

  return (
    <div className="grid gap-2">
      {layers.map((l, i) => (
        <TreeNodeContainer depth={i} key={l.id}>
          {l.name}
        </TreeNodeContainer>
      ))}
      {!bottomLayerPath.includes(OPTIMISTIC_ID) && (
        <CreateLayer
          key={bottomLayerPath}
          tenantId={tenantId}
          depth={layers.length}
          parentPath={bottomLayerPath}
        />
      )}
    </div>
  );
};
