// forked from @tiptap/react useEditor hook:
// https://github.com/ueberdosis/tiptap/blob/develop/packages/react/src/useEditor.ts

// @tiptap/react useEditor hook returns null at first then
// returns the editor when it's initialized. This leads to a
// flicker in the UI when the editor is rendered.

// The hook below is an exact copy, but we've updated it so
// that an Editor instance is always available.

// Related GitHub issue:
// https://github.com/ueberdosis/tiptap/issues/2040
import { EditorOptions } from '@tiptap/core';
import { Editor } from '@tiptap/react';
import { useEffect, useState, useRef, DependencyList } from 'react';

function useForceUpdate() {
  const [, setValue] = useState(0);

  return () => setValue((value) => value + 1);
}

export const useEditor = (
  options: Partial<EditorOptions> = {},
  deps: DependencyList = [],
) => {
  const defaultEditor = useRef(new Editor(options)).current;
  const [editor, setEditor] = useState<Editor>(defaultEditor);

  const forceUpdate = useForceUpdate();

  const {
    onBeforeCreate,
    onBlur,
    onCreate,
    onDestroy,
    onFocus,
    onSelectionUpdate,
    onTransaction,
    onUpdate,
  } = options;

  const onBeforeCreateRef = useRef(onBeforeCreate);
  const onBlurRef = useRef(onBlur);
  const onCreateRef = useRef(onCreate);
  const onDestroyRef = useRef(onDestroy);
  const onFocusRef = useRef(onFocus);
  const onSelectionUpdateRef = useRef(onSelectionUpdate);
  const onTransactionRef = useRef(onTransaction);
  const onUpdateRef = useRef(onUpdate);

  // This effect will handle updating the editor instance
  // when the event handlers change.
  useEffect(() => {
    if (onBeforeCreate) {
      editor.off('beforeCreate', onBeforeCreateRef.current);
      editor.on('beforeCreate', onBeforeCreate);
      onBeforeCreateRef.current = onBeforeCreate;
    }

    if (onBlur) {
      editor.off('blur', onBlurRef.current);
      editor.on('blur', onBlur);
      onBlurRef.current = onBlur;
    }

    if (onCreate) {
      editor.off('create', onCreateRef.current);
      editor.on('create', onCreate);
      onCreateRef.current = onCreate;
    }

    if (onDestroy) {
      editor.off('destroy', onDestroyRef.current);
      editor.on('destroy', onDestroy);
      onDestroyRef.current = onDestroy;
    }

    if (onFocus) {
      editor.off('focus', onFocusRef.current);
      editor.on('focus', onFocus);
      onFocusRef.current = onFocus;
    }

    if (onSelectionUpdate) {
      editor.off('selectionUpdate', onSelectionUpdateRef.current);
      editor.on('selectionUpdate', onSelectionUpdate);
      onSelectionUpdateRef.current = onSelectionUpdate;
    }

    if (onTransaction) {
      editor.off('transaction', onTransactionRef.current);
      editor.on('transaction', onTransaction);
      onTransactionRef.current = onTransaction;
    }

    if (onUpdate) {
      editor.off('update', onUpdateRef.current);
      editor.on('update', onUpdate);
      onUpdateRef.current = onUpdate;
    }
  }, [
    onBeforeCreate,
    onBlur,
    onCreate,
    onDestroy,
    onFocus,
    onSelectionUpdate,
    onTransaction,
    onUpdate,
    editor,
  ]);

  useEffect(() => {
    let isMounted = true;

    // Only create a new instance if the editor is not already initialized.
    if (editor === defaultEditor) {
      const instance = new Editor(options);
      setEditor(instance);

      instance.on('transaction', () => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            if (isMounted) {
              forceUpdate();
            }
          });
        });
      });
    }

    return () => {
      isMounted = false;
    };
  }, [editor, defaultEditor, options, deps, forceUpdate]);

  useEffect(() => {
    return () => {
      // Ensure the default editor is not destroyed.
      if (editor !== defaultEditor) {
        editor?.destroy();
      }
    };
  }, [editor, defaultEditor]);

  return editor;
};
