import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { MicVolume } from './MicVolume';

import {
  H2,
  H3,
  H5,
  P,
  Separator,
  Input,
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@eluve/components';

import { useSession, useUserIdFromSession } from '@eluve/session-helpers';

type MicrophoneOption = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

export const SettingsPage: React.FC = () => {
  const [micPermission, setMicPermission] = useState<boolean | null>(null);
  const [microphoneOptions, setMicrophoneOptions] = useState<
    MicrophoneOption[]
  >([]);
  const [selectedMic, setSelectedMic] = useState<string | null>(null);

  const handleMicChange = (deviceId: string) => {
    setSelectedMic(deviceId);
    localStorage.setItem('selectedMic', deviceId);
  };

  const checkMicPermission = async () => {
    try {
      // Firefox does not allow to query if the user has granted permission
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // remove stream after permission check
      stream.getTracks().forEach((track) => track.stop());
      setMicPermission(true);
    } catch (error) {
      setMicPermission(false);
    }
  };

  useEffect(() => {
    checkMicPermission();

    const getMicrophoneOptions = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(
        (device) => device.kind === 'audioinput',
      );
      setMicrophoneOptions(audioDevices);
    };

    getMicrophoneOptions();
  }, [micPermission]);

  useEffect(() => {
    const storedMicId = localStorage.getItem('selectedMic');
    setSelectedMic(storedMicId);
  }, [selectedMic]);

  const session = useSession();
  const { email } = session;
  const userId = useUserIdFromSession();

  let selectedMicTitle;
  if (micPermission === false) {
    selectedMicTitle =
      'Please enable your microphone in your browser settings.';
  } else if (micPermission === true) {
    selectedMicTitle =
      microphoneOptions.find((device) => device?.deviceId === selectedMic)
        ?.label || 'Select a microphone';
  }

  return (
    <div className="h-full w-full flex justify-center p-8">
      <div className="max-w-[600px] flex flex-col gap-4">
        <div className="flex flex-col w-full h-fit gap-3 p-10 h-content bg-white rounded-xl m-4 border border-gray-5">
          <div className="mb-1 flex flex-col gap-1">
            <H2>Settings</H2>
            <P>Manage your Eluve preferences</P>
          </div>
          <Separator />
          <div className="w-full">
            <div className="mb-1 flex flex-col gap-1">
              <H3>Audio</H3>
              <H5>Microphone</H5>
              <Select onValueChange={handleMicChange}>
                <SelectTrigger className="text-gray-12">
                  <SelectValue
                    className="text-gray-12"
                    placeholder={selectedMicTitle}
                  />
                </SelectTrigger>
                <SelectContent>
                  {microphoneOptions.map((device) => {
                    return (
                      <SelectItem
                        key={device.deviceId}
                        className="pl-7 focus:bg-brand-4"
                        value={device.deviceId}
                      >
                        {device.label}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div>
            <div className="w-full flex justify-end">
              {micPermission ? (
                <MicVolume deviceId={selectedMic} />
              ) : (
                <div className="mb-8"></div>
              )}
            </div>
          </div>
          <div className="mb-1 flex flex-col gap-1">
            <H3>Account Details</H3>
            <div className="flex flex-col gap-8">
              <div>
                <H5>Email</H5>
                <Input value={email} disabled className="text-gray-12" />
              </div>
              <div>
                <H5>User ID</H5>
                <Input value={userId} disabled className="text-gray-12" />
              </div>
            </div>
          </div>
          <Separator />
          <div>
            <H5 className="mb-2">Privacy & Security</H5>
            <P>
              Audio, transcripts, and clinical notes are stored securely on our
              servers. You may request deletion of them at any time.{' '}
              <Link
                className="underline"
                to="https://docs.google.com/document/d/e/2PACX-1vTF2FB9zMyRxYyiK7bc-a4G3JPqKoBS0xmOgKdw4JG_uK4YF2m9XnGvfl9ssBTS8DxjqYFjGNjJOm2y/pub"
                target="_blank"
              >
                More info on how we protect data
              </Link>
            </P>
          </div>
        </div>
      </div>
    </div>
  );
};
