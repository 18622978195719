import { useFragment } from '@apollo/client';
import { useCacheIdBuilder } from '@eluve/apollo-client';
import { TenantNameFragmentDoc } from '@eluve/client-gql-operations';

export const useTenantName = (tenantId: string) => {
  const from = useCacheIdBuilder()({
    typeName: 'Tenants',
    key: tenantId,
  });

  const { data } = useFragment({
    fragment: TenantNameFragmentDoc,
    from,
  });

  return data?.name ?? '';
};
