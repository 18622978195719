import { initContract } from '@ts-rest/core';
import { llmContract } from './llm.contract';
import { authContract } from './auth.contract';
import { gcsContract } from './gcs.contract';
import { patientsContract } from './patients.contract';
import { appointmentsContract } from './appointments.contract';
import { waitlistContract } from './waitlist.contract';

const c = initContract();

export const apiContract = c.router(
  {
    auth: authContract,
    gcs: gcsContract,
    llm: llmContract,
    patients: patientsContract,
    appointments: appointmentsContract,
    waitlist: waitlistContract,
  },
  {
    pathPrefix: '/api',
  },
);
