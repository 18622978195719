'use client';
import React, { useEffect, useState } from 'react';

export interface SessionStatusBannerProps {
  isInSession: boolean;
}

export const SessionStatusBanner: React.FC<SessionStatusBannerProps> = ({
  isInSession,
}) => {
  const [isRecordingIndicatorVisible, setIsRecordingIndicatorVisible] =
    useState(true);

  // used to animate pulsing for the 'in session' red dot indicator
  useEffect(() => {
    const interval = setInterval(() => {
      setIsRecordingIndicatorVisible((prevState) => !prevState);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`flex flex-row gap-1 items-center w-fit h-fit rounded-md border pointer-events-none ${
        isInSession ? 'border-red-100' : 'border-blue-100'
      }  px-1.5 py-0.5 leading-3`}
    >
      {/* icon */}
      {isInSession && (
        <div
          className={`w-2 h-2 bg-red-400 rounded-full transition-opacity duration-500 ${
            isRecordingIndicatorVisible ? 'opacity-100' : 'opacity-0'
          }`}
        />
      )}

      {!isInSession && (
        <div className="flex flex-row gap-0.5">
          <div className="w-0.5 h-2 bg-blue-400" />
          <div className="w-0.5 h-2 bg-blue-400" />
        </div>
      )}

      {/* text */}
      <div
        className={`text-xs ${isInSession ? 'text-red-400' : 'text-blue-400'} `}
      >
        {isInSession ? 'In Session' : 'Paused'}
      </div>
    </div>
  );
};
