import { Editor as TipTapEditor } from '@tiptap/core';
import { EditorProps } from '@tiptap/pm/view';
import { EditorContent, Extension, JSONContent } from '@tiptap/react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { EditorBubbleMenu } from './editor/bubble-menu';
import { defaultExtensions } from './editor/extensions';
import { useEditor } from './hooks/useEditor';
import { defaultEditorProps } from './props';

export type EluveEditorProps = {
  /**
   * The editor content
   */
  content?: JSONContent | string;
  /**
   * A list of extensions to use for the editor, in addition to the default Novel extensions.
   * Defaults to [].
   */
  extensions?: Extension[];
  /**
   * Props to pass to the underlying Tiptap editor, in addition to the default Novel editor props.
   * Defaults to {}.
   */
  editorProps?: EditorProps;
  /**
   * A callback function that is called whenever the editor is updated.
   * Defaults to () => {}.
   */
  onUpdate: (editor?: TipTapEditor) => void | Promise<void>;
};

export type EluveEditorHandle = {
  setEditable: (editable: boolean) => void;
};

export const EluveEditor = forwardRef<EluveEditorHandle, EluveEditorProps>(
  ({ extensions = [], editorProps = {}, content, onUpdate }, ref) => {
    const [editable, setEditable] = useState<boolean>(true);

    const editorExtensions = useMemo(
      () => [...defaultExtensions, ...extensions],
      [extensions],
    );
    const tiptapEditorProps = useMemo(
      () => ({ ...defaultEditorProps, ...editorProps }),
      [editorProps],
    );

    const editor = useEditor({
      extensions: editorExtensions,
      editorProps: tiptapEditorProps,
      onUpdate: ({ editor }) => onUpdate(editor),
      content,
    });

    useImperativeHandle(ref, () => ({
      setEditable(editable: boolean) {
        setEditable(editable);
      },
    }));

    // the editor isn't available right away, so we manage
    // the editable state in this component and set it on
    // the editor when the editor becomes available
    useEffect(() => {
      editor.setEditable(editable);
    }, [editor, editable]);

    return (
      <>
        <EditorContent editor={editor} />
        <EditorBubbleMenu editor={editor} />
      </>
    );
  },
);
