import { useQuery } from '@apollo/client';
import { DailyAgenda, DateNavigator } from '@eluve/blocks';
import { GetAppointmentRangeSummaryDocument } from '@eluve/client-gql-operations';
import { Button, Calendar } from '@eluve/components';
import { convertUrlStringDate, urlSafeDate } from '@eluve/date-utils';
import { useIsTenantAdminFromSession } from '@eluve/session-helpers';
import { add } from 'date-fns';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

export const HomePage: React.FC = () => {
  const isTenantAdmin = useIsTenantAdminFromSession();

  const [searchParams, setSearchParams] = useSearchParams();

  const dateSearchParam = searchParams.get('date') ?? urlSafeDate();

  const date = convertUrlStringDate(dateSearchParam);

  const setDate = (date: Date | undefined) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('date', urlSafeDate(date ?? new Date()));
    setSearchParams(urlSearchParams);
  };

  const range = [date.toISOString(), add(date, { days: 1 }).toISOString()];

  const { data } = useQuery(GetAppointmentRangeSummaryDocument, {
    skip: !dateSearchParam,
    variables: {
      min: range[0],
      max: range[1],
    },
  });

  return (
    <div className="flex justify-center justify-items-center w-full">
      <div className="flex justify-center justify-items-center container w-full h-screen -mt-16 pt-16">
        <div className="flex flex-col w-full gap-3 p-4 h-content bg-white rounded-xl m-4 border border-gray-5">
          <div className="flex justify-between border-b pb-2">
            <DateNavigator
              onDateChanged={(newDate) => setDate(newDate)}
              startingDate={date}
              key={date.toDateString()}
            />
            <Button variant="outline" onClick={() => setDate(new Date())}>
              Today
            </Button>
          </div>
          <div className="w-full overflow-y-scroll">
            <DailyAgenda
              Link={({ id, children }) => {
                return <Link to={`../appointments/${id}`}>{children}</Link>;
              }}
              agendaItems={(data?.appointments ?? []).map((a) => {
                const start = new Date(a.startDate);
                return {
                  id: a.id,
                  name: a.patient
                    ? [a.patient.firstName, a.patient.lastName]
                        .filter((v) => v)
                        .join(' ')
                    : '',
                  description: a.name ?? '',
                  start,
                  end: add(start, { hours: 1 }),
                };
              })}
            />
          </div>
        </div>
        <div className="hidden md:flex bg-gray-3 relative w-auto">
          <div className="w-80 p-4 flex flex-col gap-8">
            <div className="bg-gray-1 rounded-lg shadow-md">
              <Calendar mode="single" selected={date} onSelect={setDate} />
            </div>

            {isTenantAdmin && (
              <Link className="underline" to="../admin">
                Tenant Settings
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
