import React, { useRef, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Skeleton,
  P,
  H4,
  cn,
} from '@eluve/components';
import { Loader2 } from 'lucide-react';

export interface TranscriptionCardProps {
  className?: string;
  transcript: string | null;
  transcriptionInProgress: boolean;
}

const formatTranscript = (paragraph: string) => {
  const sentences = paragraph
    .split(/([.!?])/)
    .reduce((acc: string[], val, i, arr) => {
      if (i % 2 === 0) {
        acc.push(val + (arr[i + 1] || ''));
      }
      return acc;
    }, []);
  return (
    <div>
      {sentences.length &&
        sentences.map((sentence, index) => (
          <div key={index} className="pt-3 py-5">
            <H4 className="font-normal text-black">{sentence}</H4>
          </div>
        ))}
    </div>
  );
};

export const TranscriptionCard: React.FC<TranscriptionCardProps> = ({
  className,
  transcript,
  transcriptionInProgress,
}) => {
  const transcriptRef = useRef<HTMLDivElement>(null);
  // scrolls to the bottom of the transcript div when we're in the middle of a transcription
  useEffect(() => {
    if (transcriptionInProgress) {
      transcriptRef.current?.scrollTo({
        top: transcriptRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [transcript, transcriptionInProgress]);

  return (
    <Card className={cn('w-full rounded-xl border-gray-4', className)}>
      <CardHeader className="space-y-0 p-0 px-5 border-b rounded-t-xl bg-gray-1 h-10 flex flex-row justify-between items-center justify-items-center tracking-normal">
        <P>Transcript</P>
        {transcriptionInProgress && (
          <Loader2 className="animate-spin h-5 text-brand-8" />
        )}
      </CardHeader>

      <CardContent
        ref={transcriptRef}
        className="flex flex-col justify-between rounded-b-xl p-4 bg-white h-[40vh] overflow-y-auto"
      >
        <div className="px-7">
          {transcript && <div>{formatTranscript(transcript)}</div>}
          {transcriptionInProgress && (
            <div className="flex flex-col gap-5">
              <Skeleton className="h-3 my-2 w-[55%]" />
              <Skeleton className="h-3 my-2 w-[60%]" />
              <Skeleton className="h-3 my-2 w-[40%]" />
            </div>
          )}
        </div>

        {!transcript && !transcriptionInProgress && (
          <div className="flex w-full h-full justify-center items-center">
            <P className="text-gray-8">Transcript could not be generated.</P>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
