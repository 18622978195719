import { z } from 'zod';

const configSchema = z.object({
  VITE_SUPERTOKENS_WEBSITE_DOMAIN: z.string().url(),
  VITE_API_DOMAIN: z.string().url(),
  VITE_APOLLO_CLIENT_URI: z.string().url(),
  VITE_ENV: z.enum(['development', 'production', 'test']).default('production'),

  // Temporary solution until we obtain a proper tenant settings configuration
  VITE_BILLING_CODES_ENABLED: z
    .string()
    .nullish()
    .transform((str) => str === 'true'),
});

export const appConfig = configSchema.parse(import.meta.env);
