import { SessionSchema } from '@eluve/api-contract';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

/**
 * Provides access to the parsed session data for the currently
 * authenticated user. If the session is not available, an error will be thrown
 */
export const useSession = () => {
  const session = useSessionContext();
  // TODO(jesse): Need to do more testing to see if this will get caught
  // properly by the top level SessionAuth provider or if we need to do
  // some more defensive coding here during a refresh flow
  if (session.loading || !session.doesSessionExist) {
    throw Error('Attempted to access session but it is not available');
  }

  const parsedSession = SessionSchema.parse(session.accessTokenPayload);
  return parsedSession;
};

export const useTenantIdFromSession = () => {
  const session = useSession();
  return session['https://hasura.io/jwt/claims']['x-hasura-tenant-id'];
};

export const useUserIdFromSession = () => {
  const session = useSession();
  return session['https://hasura.io/jwt/claims']['x-hasura-user-id'];
};

export const useRolesFromSession = () => {
  const session = useSession();
  return session['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
};

export const useIsTenantAdminFromSession = () => {
  const roles = useRolesFromSession();
  return roles.includes('tenant-admin');
};
