import React, { Suspense } from 'react';
import { AppointmentLayout } from './AppointmentLayout';
import { useParams } from 'react-router-dom';
import { AppointmentContextProvider } from '@eluve/smart-blocks';
import { P, Skeleton } from '@eluve/components';

const SkeletonLine: React.FC = () => {
  return <Skeleton className="w-full h-6 bg-white" />;
};

export const AppointmentPage: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };
  return (
    <Suspense
      fallback={
        <div className="container mt-24 flex flex-col gap-2">
          <P>Loading Appointment...</P>
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
        </div>
      }
    >
      <AppointmentContextProvider
        tenantId={tenantId}
        appointmentId={appointmentId}
      >
        <AppointmentLayout />
      </AppointmentContextProvider>
    </Suspense>
  );
};
