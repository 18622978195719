import { useSession } from '@eluve/session-helpers';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { LogOut, ShieldCheck } from 'lucide-react';
import { Button } from '@eluve/components';
import { NotFoundPage } from '../../NotFoundPage';
import { AdminBreadcrumbs } from './components/AdminBreadcrumbs';

export const AdminLayout: React.FC = () => {
  const session = useSession();

  if (!session.isEluveAdmin) {
    return <NotFoundPage />;
  }

  return (
    <div className="min-h-full">
      <div className="bg-gray-800 pb-32">
        <header className="py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <ShieldCheck className="w-10 h-10 text-yellow-600" />
                <h1 className="text-3xl font-bold tracking-tight text-white">
                  Eluve Admin Dashboard
                </h1>
              </div>
              <div className="flex gap-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    const { firstName, lastName } = session;
                    throw new Error(
                      `Test Error: triggered by ${[firstName, lastName].join(
                        ' ',
                      )}`,
                    );
                  }}
                >
                  <span className="sr-only">test-error</span>
                  Trigger Sentry Test Error
                </Button>
                <Link to="/">
                  <Button>
                    Return to App <LogOut className="ml-2" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
          <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
            <div className="w-full">
              <AdminBreadcrumbs />
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
