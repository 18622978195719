import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useAppointmentStatus } from './useAppointmentStatus';

export const TRANSCRIPT_TAB = 'transcript' as const;
export const SUMMARY_TAB = 'summary' as const;

const AppointmentTabSchema = z
  .enum([TRANSCRIPT_TAB, SUMMARY_TAB])
  .nullable()
  .transform((val) => val ?? TRANSCRIPT_TAB);

type AppointmentTab = z.infer<typeof AppointmentTabSchema>;

/**
 * Read or set the current tab in a type safe way
 */
export const useAppointmentTabNav = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const appointmentStatus = useAppointmentStatus();

  const currentTab = searchParams.get('tab');

  const tab =
    !currentTab && appointmentStatus === 'COMPLETED'
      ? SUMMARY_TAB
      : AppointmentTabSchema.parse(currentTab);

  const setTab = (newTab: AppointmentTab) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('tab', newTab);
    setSearchParams(urlSearchParams, { replace: true });
  };

  return {
    tab,
    setTab,
  };
};
