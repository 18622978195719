import React from 'react';

export interface EluveLogoProps {
  className?: string;
}

export const EluveLogo: React.FC<EluveLogoProps> = ({ className }) => {
  return (
    <svg
      width="80"
      height="25"
      viewBox="0 0 80 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6739 19.1799H12.091C11.3759 20.5357 9.94582 21.164 8.5482 21.164C7.08558 21.164 5.81797 20.5026 4.9729 19.3452L16.8039 14.2857C15.9913 9.72222 12.611 7.14286 8.5807 7.14286C3.83532 7.14286 0 11.1772 0 16.1045C0 21.0317 3.83532 25 8.5807 25C12.741 25 15.6988 22.6521 16.6739 19.1799ZM11.8635 12.5L3.99784 15.873C4.09534 12.963 6.07799 10.9788 8.5482 10.9788C9.91331 10.9788 11.0834 11.541 11.8635 12.5ZM19.22 0V17.4603C19.22 22.123 21.8202 25.0992 26.7281 24.5701V20.6019C24.6155 20.7341 23.5429 19.8743 23.5429 17.4603V0H19.22ZM35.998 25C40.5484 25 43.5386 21.7262 43.5386 17.3942V7.57275H39.2483V17.5265C39.2483 19.4114 37.8832 20.7672 35.998 20.7672C33.1703 20.7672 32.8452 18.4854 32.8452 17.5265V7.57275H28.5549V17.3942C28.5549 21.8585 31.3176 25 35.998 25ZM56.3525 24.6032L62.658 7.57275H58.2376L57.3018 10.4075L57.3086 10.4099L52.6553 24.6032H56.3525ZM50.8771 22.2927L45.399 7.57275H49.8844L52.793 16.4499L50.8771 22.2927ZM79.87 19.1799H75.2871C74.572 20.5357 73.1419 21.164 71.7443 21.164C70.2817 21.164 69.0141 20.5026 68.169 19.3452L80 14.2857C79.1874 9.72222 75.8071 7.14286 71.7768 7.14286C67.0314 7.14286 63.1961 11.1772 63.1961 16.1045C63.1961 21.0317 67.0314 25 71.7768 25C75.9371 25 78.8949 22.6521 79.87 19.1799ZM75.0596 12.5L67.1939 15.873C67.2914 12.963 69.2741 10.9788 71.7443 10.9788C73.1094 10.9788 74.2795 11.541 75.0596 12.5Z"
        fill="#04749A"
      />
    </svg>
  );
};
