'use client';
import { useFragment, useMutation, useSubscription } from '@apollo/client';
import { useApiClient } from '@eluve/api-client-provider';
import { useDialog } from '@eluve/blocks';
import {
  ActiveAppointmentLayoutFragmentDoc,
  InsertAppointmentBillingCodesDocument,
  InsertHumanSummaryDocument,
  ListenAppointmentStatusDocument,
  UpdateAppointmentStatusDocument,
} from '@eluve/client-gql-operations';
import { Switch } from '@eluve/components';
import { MessageResponse } from '@eluve/post-messenger';
import { useSession } from '@eluve/session-helpers';
import {
  AppointmentControlsFooter,
  AppointmentStoreProvider,
  SessionRecordingWithPatientDetailsCard,
  useAppointmentCacheId,
  useIsNewSession,
  useUploadFile,
} from '@eluve/smart-blocks';
import { toTitleCase } from '@eluve/utils';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { appConfig } from '../../../../../config';
import { postMessenger } from '../../../utils/post-messenger';
import './ActiveAppointmentLayout.css';
import { EluveAdminPanel } from './EluveAdminPanel';
import { HelperText } from './HelperText';
import { TranscriptAndSummary } from './TranscriptAndSummary';
import { TRANSCRIPT_TAB, useAppointmentTabNav } from './useAppointmentTabNav';
import { useAppointmentTranscriptionSync } from './useAppointmentTranscriptionSync';
import { useListenForSummary } from './useListenForSummary';
import { useSetAppointmentStartedAt } from './useSetAppointmentStartedAt';
import { useSummary } from './useSummary';
import { useSummaryBillingCodes } from './useSummaryBillingCodes';

export interface ActiveAppointmentLayoutProps {
  appointmentId: string;
}

export const ActiveAppointmentLayout: React.FC<
  ActiveAppointmentLayoutProps
> = ({ appointmentId }) => {
  const { isEluveAdmin } = useSession();
  const apiClient = useApiClient();
  const location = useLocation();
  const { setAppointmentStartedAt } = useSetAppointmentStartedAt();

  const { setTab } = useAppointmentTabNav();

  const appointmentCacheId = useAppointmentCacheId();

  const {
    data: appointment,
    complete,
    missing,
  } = useFragment({
    fragment: ActiveAppointmentLayoutFragmentDoc,
    fragmentName: 'ActiveAppointmentLayout',
    from: appointmentCacheId,
  });

  if (!complete) {
    // TODO(jesse) Handle appropriately
    throw Error(
      `Invariant violation: appointment missing ${JSON.stringify(missing)}`,
    );
  }

  useListenForSummary();
  // Listen for meaningful status changes for this appointment
  useSubscription(ListenAppointmentStatusDocument, {
    variables: {
      appointmentId,
    },
  });

  // Sync chunked transcript data into the apollo cache for use across the component tree
  useAppointmentTranscriptionSync();

  const { summary } = useSummary();

  const interaction = appointment?.doctorInteraction;
  const additionalUserNotes = interaction?.additionalNotes ?? null;

  const patientExternalId =
    appointment?.patient?.external_patients_info?.[0]?.externalPatientId;

  const externalEhr =
    appointment?.patient?.external_patients_info?.[0]?.external_ehr;

  const isNewSession = useIsNewSession();
  const { uploadFileToGCS } = useUploadFile();

  const regenerateSummary = async () => {
    const result = await apiClient.llm.summarizeAppointment({
      body: {},
      params: {
        appointmentId,
      },
    });

    if (result.status !== 201) {
      throw new Error('Failed to start summarization job');
    }

    setTab(TRANSCRIPT_TAB);

    console.log(`Regenerating job with id ${result.body.jobId}`);
  };

  // uploading file states
  const [advancedMode, setAdvancedMode] = useState<boolean>(false);

  const {
    isOpen: isAppointmentControlsOpen,
    closeDialog: closeAppointmentControls,
    toggle: toggleAppointmentControls,
  } = useDialog();

  const [insertHumanSummary] = useMutation(InsertHumanSummaryDocument);
  const [updateStatus] = useMutation(UpdateAppointmentStatusDocument);

  const { appointmentBillingCodes, refetchAppointmentBillingCodes } =
    useSummaryBillingCodes();

  const [insertAppointmentBillingCodes] = useMutation(
    InsertAppointmentBillingCodesDocument,
    {
      onCompleted: () => {
        // TODO(sargis): Remove this in favor of Apollo cache.
        refetchAppointmentBillingCodes();
      },
      optimisticResponse: {
        insertAppointmentBillingCodes: {
          returning: [
            {
              id: '',
              __typename: 'AppointmentBillingCodes',
              visible: true,
              code: '',
              label: '',
              appointmentId,
            },
          ],
        },
      },
    },
  );

  const handleSaveDraft = async () => {
    await insertHumanSummary({
      variables: {
        appointmentId,
        summary,
      },

      // TODO(jesse): Evaluate optimistic response
    });

    if (appointmentBillingCodes.length) {
      const billingCodesToSave = appointmentBillingCodes.map((bc) => ({
        id: bc.id || undefined,
        appointmentId,
        label: bc.label,
        code: bc.code,
        visible: bc.visible,
      }));

      await insertAppointmentBillingCodes({
        variables: {
          appointmentBillingCodes: billingCodesToSave,
        },
      });
    }
  };

  const withSignature = (value: string | null): string => {
    const { protocol } = window.location;
    let host = window.location.host;
    if (host.includes('localhost')) {
      // Jane does not allow IPs or localhost in URLs
      host = 'web.eluve.local:4200';
    }
    const url = `${protocol}//${host}${location.pathname}`;
    const signature = `*This note was drafted in part by Eluve, an AI Assistant for medical professionals. To see this chart in Eluve, visit: [${url}](${url})*`;
    if (!value) {
      return signature;
    }
    return `${value}<p><br/><p/>${signature}`;
  };

  const syncToEHR = async ({
    sign = false,
  }: {
    sign: boolean;
  }): Promise<MessageResponse<{ chartUrl?: string }>> => {
    const data = {
      summary: {
        ...summary,
        ADDITIONAL_NOTES: withSignature(additionalUserNotes),
      },
      patientExternalId,
      vendor: externalEhr?.vendor,
      domain: externalEhr?.domain,
      sign,
    };
    const response = await postMessenger.sendRequest<{ chartUrl?: string }>(
      'SYNC_TO_EHR',
      data,
      { timeout: 10000 },
    );
    return response;
  };

  const getPatientEhrUrl = (): string | undefined => {
    if (patientExternalId && externalEhr && externalEhr.vendor === 'JANE') {
      return `https://${externalEhr.domain}/admin#patients/${patientExternalId}/charts`;
    }
    return undefined;
  };

  const handleSessionStart = async () => {
    setTab(TRANSCRIPT_TAB);
    await setAppointmentStartedAt({
      variables: {
        id: appointmentId,
      },
    });
  };

  const handleSessionStop = async () => {
    await updateStatus({
      variables: {
        appointmentId,
        status: 'COMPLETED',
      },
      optimisticResponse: {
        updateAppointmentsByPk: {
          __typename: 'Appointments',
          id: appointmentId,
          status: 'COMPLETED',
        },
      },
    });
  };

  return (
    <AppointmentStoreProvider
      initializer={(set) => ({
        appointmentId,
        isInSession: false,

        isUploadingFile: false,

        syncToEHR,
        regenerateSummary,
        uploadFileToGCS,
        editorHasChanges: false,
        handleSessionStart: async () => {
          set({
            isInSession: true,
          });

          await handleSessionStart();
        },
        handleSessionPause: () => set({ isInSession: false }),
        handleSessionStop: async () => {
          set({
            isInSession: false,
          });

          await handleSessionStop();
        },
      })}
    >
      <div>
        <div
          className="flex flex-col justify-center items-center mb-24"
          onClick={() => {
            closeAppointmentControls();
          }}
        >
          <div className="w-full container flex flex-col gap-10 pt-10 mb-20 pb-24">
            {/**
             * ELUVE ADMIN CONTROLS SWITCH
             */}
            {isEluveAdmin && (
              <Switch
                className="self-end scale-50"
                checked={advancedMode}
                onCheckedChange={(checked: boolean) => {
                  setAdvancedMode(checked);
                }}
              />
            )}

            {/**
             * ELUVE ADMIN PANEL
             */}
            {isEluveAdmin && advancedMode && <EluveAdminPanel />}

            {/**
             * TOP CARD
             */}
            <SessionRecordingWithPatientDetailsCard
              appointmentId={appointmentId}
              currentAppointmentPatientId={appointment.patient?.id}
              patientFirstName={appointment.patient?.firstName}
              patientLastName={appointment.patient?.lastName}
              startTime={
                appointment.startDate
                  ? new Date(appointment.startDate)
                  : new Date()
              }
              location={{
                name: appointment.location?.name,
                id: appointment.location?.id,
              }}
            />

            {/**
             * SESSION DATA (transcript and summary)
             */}
            <TranscriptAndSummary />

            {/**
             * HELPER TEXT
             */}
            <HelperText />
          </div>
        </div>

        {/**
         * STICKY FOOTER
         */}
        <AppointmentControlsFooter
          className={`appointment-controls-footer`}
          appointmentId={appointmentId}
          patientId={appointment.patient?.id ?? ''}
          patientFirstName={appointment.patient?.firstName ?? ''}
          patientLastName={appointment.patient?.lastName ?? ''}
          locationId={appointment.location?.id}
          patientExternalId={patientExternalId}
          additionalUserNotes={additionalUserNotes}
          handleSaveDraft={handleSaveDraft}
          syncToEHR={syncToEHR}
          visible={!isNewSession}
          isOpen={isAppointmentControlsOpen}
          toggleDialog={toggleAppointmentControls}
          patientEhrUrl={getPatientEhrUrl()}
          ehrName={toTitleCase(externalEhr?.vendor ?? '')}
          billingCodesEnabled={appConfig.VITE_BILLING_CODES_ENABLED}
        />
      </div>
    </AppointmentStoreProvider>
  );
};
