import React from 'react';
import { useAppointmentSelectableLocations } from './useAppointmentSelectableLocations';
import { useAppointmentContext } from './appointment.context';
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
  useToast,
} from '@eluve/components';
import { Check, ChevronsUpDown, X } from 'lucide-react';
import { useFragment, useMutation } from '@apollo/client';
import {
  AppointmentLocationFragmentDoc,
  UpdateAppointmentLocationDocument,
} from '@eluve/client-gql-operations';

export const AppointmentLocationSelector: React.FC = () => {
  const { toast } = useToast();
  const { tenantId, appointmentId, appointmentCacheId } =
    useAppointmentContext();
  const locationOptions = useAppointmentSelectableLocations(tenantId);

  const [open, setOpen] = React.useState(false);

  const { data } = useFragment({
    fragment: AppointmentLocationFragmentDoc,
    from: appointmentCacheId,
  });

  const locationId = data?.locationId;

  const [updateAppointmentLocation] = useMutation(
    UpdateAppointmentLocationDocument,
    {
      optimisticResponse: ({ locationId }) => ({
        updateAppointmentsByPk: {
          __typename: 'Appointments' as const,
          id: appointmentId,
          locationId,
        },
      }),
    },
  );

  const handleSelect = async (locationId: string | null) => {
    try {
      updateAppointmentLocation({
        variables: {
          appointmentId,
          locationId,
        },
      });
    } catch (e) {
      toast({
        title: 'Something went wrong.',
        variant: 'destructive',
      });
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[300px] justify-between"
        >
          {locationId
            ? locationOptions.find((l) => l.id === locationId)?.label
            : 'Select location...'}
          {locationId && (
            <X
              onClick={() => handleSelect(null)}
              className="ml-2 h-4 w-4 shrink-0 opacity-50"
            />
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command>
          <CommandInput placeholder="Search locations..." />
          <CommandEmpty>No locations found.</CommandEmpty>
          <CommandGroup>
            {locationOptions.map((location) => (
              <CommandItem
                key={location.id}
                value={location.label}
                onSelect={() => {
                  handleSelect(location.id);
                  setOpen(false);
                }}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    locationId === location.id ? 'opacity-100' : 'opacity-0',
                  )}
                />
                {location.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
