import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  CreateLocationDocument,
  GetLocationPageDataDocument,
  LocationFragment,
  TenantLocationsFragmentDoc,
} from '@eluve/client-gql-operations';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TreeNodeContainer } from './TreeNodeContainer';
import { useCacheIdBuilder } from '@eluve/apollo-client';
import { DepthContainer } from './DepthContainer';
import { Button, FormItem, Input, P } from '@eluve/components';
import { TreeNode, buildTree } from './treeUtils';
import fromPairs from 'lodash/fromPairs';
import { MapPinned } from 'lucide-react';

const OPTIMISTIC_ID = 'optimistic';

const CreateLocation: React.FC<{
  tenantId: string;
  layerName: string;
  layerId: string;
  parentName: string;
  parentPath: string;
  depth: number;
}> = ({ layerName, parentPath, depth, layerId, parentName, tenantId }) => {
  const [isCreatingLocation, setIsCreatingLocation] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const cacheId = useCacheIdBuilder()({
    typeName: 'Tenants',
    key: tenantId,
  });

  const [createLocation] = useMutation(CreateLocationDocument, {
    optimisticResponse: ({ input }) => ({
      insertLocationsOne: {
        __typename: 'Locations' as const,
        name: input.name!,
        depth,
        path: `${input.path!}.${OPTIMISTIC_ID}`,
        id: OPTIMISTIC_ID,
        isRoot: false,
        timezone: '',
        tenantId,
        externalEhrId: null,
      },
    }),
    update: (cache, { data }) => {
      cache.updateFragment(
        {
          fragment: TenantLocationsFragmentDoc,
          fragmentName: 'TenantLocations',
          id: cacheId,
        },
        (existing) => {
          if (!existing || !data?.insertLocationsOne) {
            return existing;
          }

          return {
            locations: [...existing.locations, data.insertLocationsOne],
          };
        },
      );
    },
  });

  const submit = async () => {
    if (!newLocationName) {
      return;
    }
    try {
      setIsSubmitting(true);
      await createLocation({
        variables: {
          input: {
            path: parentPath,
            name: newLocationName,
            layerId,
          },
        },
      });
      setIsCreatingLocation(false);
      setNewLocationName('');
    } catch (e) {
      alert(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DepthContainer depth={depth}>
      {!isCreatingLocation && (
        <button
          type="button"
          className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 px-4 py-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          onClick={() => setIsCreatingLocation(true)}
        >
          <MapPinned className="mx-auto h-6 w-6 text-gray-400" />
          <span className="mt-2 block text-sm font-semibold text-gray-900">
            Add a new {layerName} in {parentName}
          </span>
        </button>
      )}
      {isCreatingLocation && (
        <div className="w-full p-2">
          <FormItem>
            <Input
              onChange={(e) => setNewLocationName(e.target.value)}
              onKeyUp={(evt) => {
                if (evt.key === 'Enter') {
                  submit();
                }
              }}
              autoFocus
            />
            <div className="flex items-center gap-2">
              <Button onClick={submit} disabled={isSubmitting}>
                Save
              </Button>
              <Button
                variant={'outline'}
                disabled={isSubmitting}
                onClick={() => {
                  setIsCreatingLocation(false);
                  setNewLocationName('');
                }}
              >
                Cancel
              </Button>
            </div>
          </FormItem>
        </div>
      )}
    </DepthContainer>
  );
};

const LocationTree: React.FC<{
  layers: Record<number, { name: string; id: string }>;
  tree: TreeNode<LocationFragment>[];
  tenantId: string;
  parent?: LocationFragment;
}> = ({ layers, tree, parent, tenantId }) => {
  const childLayer = parent ? layers[parent.depth! + 1] : undefined;

  if (!layers[1]) {
    // If there are no custom layers we can just redirect them to the Layer creation page
    return (
      <div className="grid gap-2">
        <P>
          In order to create new locations you need to configure a Layer
          Hierarchy
        </P>
        <Link to="../layers">
          <Button className="w-fit">Go to Layers Page</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="grid gap-2">
      {tree.map((t) => {
        const { data, children } = t;
        const { depth, name, id } = data;

        return (
          <>
            <TreeNodeContainer depth={depth!}>
              <Link className="underline" to={id}>
                {name}
              </Link>
            </TreeNodeContainer>
            <LocationTree
              tree={children ?? []}
              layers={layers}
              parent={data}
              tenantId={tenantId}
            />
          </>
        );
      })}
      {childLayer && (
        <CreateLocation
          tenantId={tenantId}
          depth={parent!.depth! + 1}
          layerName={childLayer.name}
          layerId={childLayer.id}
          parentPath={parent!.path!}
          parentName={parent!.name!}
        />
      )}
    </div>
  );
};

export const Locations: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };

  const { data } = useSuspenseQuery(GetLocationPageDataDocument, {
    variables: {
      tenantId,
    },
  });

  const layers = data?.tenantsByPk?.layers ?? [];

  const layerLookup = fromPairs(
    layers.map((l) => [l.depth!, { name: l.name, id: l.id }]),
  );

  const locations = sortBy(data?.tenantsByPk?.locations ?? [], (l) => l.path);

  const treeData = buildTree(locations);

  return (
    <LocationTree tree={treeData} layers={layerLookup} tenantId={tenantId} />
  );
};
