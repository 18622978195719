import { useQuery, useMutation } from '@apollo/client';
import {
  AddUserToTenantDocument,
  GetAdminTenantSummaryDocument,
  RemoveUserFromTenantDocument,
  GetTenantUsersByUserIdDocument,
} from '@eluve/client-gql-operations';
import { useToast, Button, DataTable, P, PageTitle } from '@eluve/components';
import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Check, ArrowUpDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import {
  useTenantIdFromSession,
  useUserIdFromSession,
} from '@eluve/session-helpers';

type TenantRow = {
  id: string;
  name: string;
  userCount: number;
  appointmentCount: number;
};

export const AdminTenants: React.FC = () => {
  const { toast } = useToast();
  const { data: adminTenantSummary } = useQuery(GetAdminTenantSummaryDocument);
  const [addUserToTenant] = useMutation(AddUserToTenantDocument);
  const [removeUserFromTenant] = useMutation(RemoveUserFromTenantDocument);
  const tenantIdFromSession = useTenantIdFromSession();
  const userId = useUserIdFromSession();

  const { data: adminTenants, refetch: refetchAdminTenants } = useQuery(
    GetTenantUsersByUserIdDocument,
    {
      variables: {
        userId,
      },
    },
  );

  const provisionTenant = async (tenantId: string) => {
    try {
      await addUserToTenant({
        variables: {
          tenantId: tenantId,
          userId,
          role: 'tenant-admin',
        },
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: `There was an error provisioning the tenant: ${JSON.stringify(
          error,
        )}`,
        variant: 'destructive',
      });
    } finally {
      await refetchAdminTenants();
    }
  };

  const deprovisionTenant = async (tenantId: string) => {
    try {
      await removeUserFromTenant({
        variables: {
          tenantId: tenantId,
          userId,
        },
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: `There was an error deprovisioning the tenant: ${JSON.stringify(
          error,
        )}`,
        variant: 'destructive',
      });
    } finally {
      await refetchAdminTenants();
    }
  };

  const isProvisioned = (tenantId: string): boolean => {
    return Boolean(
      adminTenants?.tenantUsers.map((tu) => tu.tenantId).includes(tenantId),
    );
  };

  const isSelected = (tenantId: string): boolean => {
    return tenantIdFromSession === tenantId;
  };

  const rows = useMemo(
    () =>
      (adminTenantSummary?.tenants ?? []).map<TenantRow>((t) => {
        return {
          id: t.id,
          name: t.name,
          appointmentCount: t.appointmentsAggregate?.aggregate?.count ?? 0,
          userCount: t.tenantUsersAggregate?.aggregate?.count ?? 0,
        };
      }),
    [adminTenantSummary],
  );

  const columns: ColumnDef<TenantRow>[] = [
    {
      accessorKey: 'defaultTenant',
      header: () => {
        return <div>Active Tenant</div>;
      },
      cell: ({ row }) => {
        return (
          <div>
            {isSelected(row.original.id) && (
              <Check className="text-green-500" />
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Name <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        return (
          <Link to={`tenants/${row.original.id}`}>{row.original.name}</Link>
        );
      },
    },
    {
      accessorKey: 'userCount',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            # Users <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        return <P>{row.original.userCount}</P>;
      },
    },
    {
      accessorKey: 'appointmentCount',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            # Appointments <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        return <P>{row.original.appointmentCount}</P>;
      },
    },
    {
      accessorKey: 'actions',
      cell: ({ row }) => {
        return (
          <div>
            {isProvisioned(row.original.id) && (
              <div>
                <Link to={`tenant/${row.original.id}/appointments`}>
                  <Button className="m-1" variant="default">
                    View Appointments
                  </Button>
                </Link>
                <Link to={`tenant/${row.original.id}/users`}>
                  <Button className="m-1" variant="default">
                    Manage Users
                  </Button>
                </Link>
              </div>
            )}
            {isProvisioned(row.original.id) ? (
              <Button
                className="m-1"
                variant="destructive"
                onClick={() => deprovisionTenant(row.original.id)}
              >
                Deprovision
              </Button>
            ) : (
              <Button
                className="m-1"
                variant="destructive"
                onClick={() => provisionTenant(row.original.id)}
              >
                Provision
              </Button>
            )}
          </div>
        );
      },
      header: () => {
        return <div>Actions</div>;
      },
    },
  ];

  return (
    <div>
      <PageTitle>All Tenants</PageTitle>
      <div>
        <DataTable data={rows} columns={columns} />
      </div>
      <div className="mt-5">
        <Button className="m-1" variant="default">
          <Link to={`audit-trail`}>View Audit Trail</Link>
        </Button>
      </div>
    </div>
  );
};
