import { useFragment } from '@apollo/client';
import { AppointmentPatientFragmentDoc } from '@eluve/client-gql-operations';
import { useAppointmentCacheId } from './appointment.context';

export const useAppointmentPatient = () => {
  const from = useAppointmentCacheId();

  const { data } = useFragment({
    fragment: AppointmentPatientFragmentDoc,
    fragmentName: 'appointmentPatient',
    from,
  });

  return data.patient;
};
