import { useQuery } from '@apollo/client';
import { GetUserLocationsDocument } from '@eluve/client-gql-operations';
import { useTenantIdFromSession } from '@eluve/session-helpers';

export const useUserLocations = () => {
  const tenantId = useTenantIdFromSession();
  const { data: userLocations } = useQuery(GetUserLocationsDocument, {
    variables: {
      tenantId: tenantId!,
    },
    skip: !tenantId,
  });
  return { locations: userLocations?.tenantsByPk?.locations };
};
