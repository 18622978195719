import React, { useState } from 'react';
import SuperTokens from 'supertokens-auth-react/ui';
import { ThirdPartyEmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui';
import {
  Button,
  Input,
  P,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  cn,
  useToast,
} from '@eluve/components';
import { useApiClient } from '@eluve/api-client-provider';
import { useNavigate, Link } from 'react-router-dom';
import { appConfig } from '../config';

export const LoginPage: React.FC = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const { toast } = useToast();
  const [partnerLogin, setPartnerLogin] = useState('');

  const attemptPartnerLogin = async () => {
    try {
      const response = await apiClient.auth.attemptPartnerLogin({
        body: {
          email: partnerLogin,
        },
      });

      if (response.status === 200) {
        if (response.body.redirectUrl) {
          window.location.href = response.body.redirectUrl;
        } else {
          const searchParams = new URLSearchParams();
          searchParams.append('email', partnerLogin);
          toast({
            title: 'Email not recognized. Join our Waitlist!',
          });
          navigate(`/waitlist?${searchParams.toString()}`);
        }
      } else {
        throw Error('Unsuccessful response');
      }
    } catch (e) {
      toast({
        title: 'Sign in failed',
        variant: 'destructive',
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && partnerLogin) {
      attemptPartnerLogin();
    }
  };

  return (
    <div className="h-screen grid place-items-center">
      <Tabs defaultValue="partner">
        <TabsList>
          <TabsTrigger value="partner">Partner Login</TabsTrigger>
          <TabsTrigger
            value="regular"
            disabled={appConfig.VITE_ENV !== 'development'}
          >
            {appConfig.VITE_ENV === 'development'
              ? 'Regular Login'
              : 'Regular Login (Coming Soon)'}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="partner">
          <div
            className={cn([
              'p-4 shadow-lg rounded-md w-full max-w-sm flex flex-col gap-5',
              'sm:max-w-lg',
              'md:max-w-xl',
            ])}
          >
            <P className="text-gray-10 font-light text-lg">
              If you are a member of an early Eluve partner get started by
              entering your organizational email below
            </P>
            <P>
              Not a member yet?{' '}
              <Link to="/waitlist" className="underline">
                Join our waitlist
              </Link>{' '}
              to be notified when we launch
            </P>
            <Input
              type="email"
              placeholder="Partner Email"
              onChange={(e) => setPartnerLogin(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Button disabled={!partnerLogin} onClick={attemptPartnerLogin}>
              Submit
            </Button>
          </div>
        </TabsContent>
        <TabsContent value="regular">
          {SuperTokens.getRoutingComponent([ThirdPartyEmailPasswordPreBuiltUI])}
        </TabsContent>
      </Tabs>
    </div>
  );
};
