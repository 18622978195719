import { Card, CardContent, CardHeader, P, cn } from '@eluve/components';
import { useSummaryBillingCodes } from './useSummaryBillingCodes';

export const BillingCodesSection: React.FC = () => {
  const { appointmentBillingCodes } = useSummaryBillingCodes();
  const visibleBillingCodes = appointmentBillingCodes.filter(
    (bc) => bc.visible,
  );

  return (
    Boolean(visibleBillingCodes.length) && (
      <div className={cn('viewer', null)}>
        <Card className="w-full rounded-xl border-gray-4">
          <CardHeader className="p-0 px-5 border-b rounded-t-xl bg-gray-1 h-10 flex flex-row justify-between items-center justify-items-center tracking-normal">
            <P>Billing Codes</P>
          </CardHeader>
          <CardContent className="flex flex-col justify-between rounded-b-xl p-4 gap-5 bg-white">
            <ul className="list-disc list-inside">
              {visibleBillingCodes.map((billingCode, index) => (
                <li key={index}>
                  <span className="billing-code bg-secondary">
                    {billingCode.code}: {billingCode.label}
                  </span>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </div>
    )
  );
};
