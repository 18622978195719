import { useFragment } from '@apollo/client';
import { AppointmentTranscriptionFragmentDoc } from '@eluve/client-gql-operations';
import { useAppointmentCacheId } from '@eluve/smart-blocks';

/**
 * Gives convenient access to the full concatenated transcript for an appointment
 * by reading it from the apollo cache
 * @returns
 */
export const useAppointmentTranscription = () => {
  const from = useAppointmentCacheId();
  const { data, complete } = useFragment({
    fragment: AppointmentTranscriptionFragmentDoc,
    from,
  });

  if (!complete) {
    return '';
  }

  return data.transcript ?? '';
};
