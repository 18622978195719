import { createZustandContext } from '@eluve/stores';

type AppointmentStoreState = {
  appointmentId: string;
  isInSession: boolean;

  syncToEHR: (opt: { sign: boolean }) => Promise<any>;
  regenerateSummary: () => Promise<void>;

  isUploadingFile: boolean;

  editorHasChanges: boolean;

  handleSessionStart: () => void;
  handleSessionStop: () => Promise<void>;
  handleSessionPause: () => void;
  uploadFileToGCS: (
    file: File,
    mimeType: string,
    fileExt: string,
    chunkNumber: number,
  ) => Promise<void>;
};

export const {
  StoreProvider: AppointmentStoreProvider,
  useStore: useAppointmentStore,
  useStoreApi: useAppointmentStoreApi,
} = createZustandContext<AppointmentStoreState>();
