'use client';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  P,
} from '@eluve/components';
import { ChevronDown } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import {
  ReactMediaRecorderHookProps,
  useReactMediaRecorder,
} from 'react-media-recorder-2';
import { useLatest } from 'react-use';
import {
  TOP_SUPPORTED_AUDIO,
  TOP_SUPPORTED_AUDIO_EXTENSION,
} from './getSupportedMimeTypes';
import { humanReadableSortableDateTime } from './humanReadableSortableDateTime';

export interface SimpleMediaRecorderProps {
  isActive: boolean;
  isCompleted: boolean;
  isInSession: boolean;
  isNewSession: boolean;
  handleSessionStart: () => void;
  handleSessionStop: () => Promise<void>;
  handleSessionPause: () => void;
  latestChunkNumber: number;
  uploadFileToGCS: (
    file: File,
    mimeType: string,
    fileExt: string,
    chunkNumber: number,
  ) => Promise<void>;
}

export const SimpleMediaRecorder: React.FC<SimpleMediaRecorderProps> = ({
  isActive,
  isCompleted,
  isInSession,
  isNewSession,
  handleSessionStart,
  handleSessionStop,
  handleSessionPause,
  uploadFileToGCS,
  latestChunkNumber,
}) => {
  const [chunkNumber, setChunkNumber] = useState(latestChunkNumber + 1);
  const chunkNumberLatest = useLatest<number>(chunkNumber);

  const onStopRecording: ReactMediaRecorderHookProps['onStop'] = (
    mediaBlobUrl: string,
    blob: Blob,
  ) => {
    const audioFile = new File(
      [blob],
      `ehr-ai-${humanReadableSortableDateTime()}.${TOP_SUPPORTED_AUDIO_EXTENSION}`,
      { type: TOP_SUPPORTED_AUDIO },
    );

    uploadFileToGCS(
      audioFile,
      TOP_SUPPORTED_AUDIO,
      TOP_SUPPORTED_AUDIO_EXTENSION,
      chunkNumberLatest.current,
    );

    setChunkNumber(chunkNumberLatest.current + 1);
  };

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const storedMicDeviceId = localStorage.getItem('selectedMic');
  const { status, startRecording, stopRecording } = useReactMediaRecorder({
    audio: storedMicDeviceId
      ? { deviceId: { exact: storedMicDeviceId } }
      : true,
    // You can also set advanced properties like this:
    // audio: {echoCancellation: true},
    blobPropertyBag: { type: TOP_SUPPORTED_AUDIO },
    askPermissionOnMount: false,
    onStop: onStopRecording,
  });

  const latestStatus = useLatest(status);

  const startSession = async () => {
    startRecording();
    handleSessionStart();

    // TODO(jesse): Replace console.logs in this file with a debug style logger
    // which can be properly enabled/disabled with a feature flag
    // However, I think its valuable to have this logging in the console for now
    // is it really helps illustrate what is actually happening inside this component
    console.log('Setting 5 second interval for chunking...');
    if (intervalRef.current) {
      console.log('Clearing interval for new session');
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    // To minimize gaps between chunks, start recording every 5 seconds
    // for the first 30 seconds then chunk every 30 seconds after
    let counter = 0;
    const chunkAudio = (seconds: number) => {
      if (latestStatus.current === 'recording') {
        console.log(`Stopping recording for ${seconds} second chunk`);
        counter++;
        stopRecording();

        if (counter === 6 && intervalRef.current) {
          clearInterval(intervalRef.current);
          console.log('setting to 30 second chunking interval');
          intervalRef.current = setInterval(() => chunkAudio(30), 30000);
        }

        // Immediately start recording again
        startRecording();
      } else {
        console.log(
          `${seconds} seconds elapsed and no longer recording`,
          latestStatus,
        );
        // For now lets clear it
        if (intervalRef.current) {
          console.log('Clearing interval');
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    };
    intervalRef.current = setInterval(() => chunkAudio(5), 5000);
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        console.log('Clearing interval for unmount');
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const pauseSession = () => {
    stopRecording();
    handleSessionPause();
  };

  const stopSession = async () => {
    stopRecording();
    handleSessionStop();
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex space-x-4">
        {isActive && !isInSession && isNewSession && status !== 'recording' && (
          <Button onClick={startSession}>Start Session</Button>
        )}

        {isActive &&
          !isInSession &&
          !isNewSession &&
          status !== 'recording' && (
            <div className="flex flex-row">
              <Button
                variant="secondary"
                className="rounded-r-none"
                onClick={startSession}
              >
                Resume Session
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger className="bg-brand-3 border-none text-brand-9 hover:bg-brand-4 hover:text-brand-9 inline-flex items-center justify-center rounded-r-md disabled:pointer-events-none disabled:opacity-50 px-2">
                  <ChevronDown className="h-5" />
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="end"
                  className="py-2 px-5 hover:bg-gray-3 hover:cursor-pointer"
                  onClick={stopSession}
                >
                  <P>Stop and Generate Note</P>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}

        {isActive && isInSession && (
          <div className="flex flex-row">
            <Button className="rounded-r-none" onClick={stopSession}>
              Stop and Generate Note
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger className="bg-brand-10 text-gray-1 hover:bg-brand-10/80 inline-flex items-center justify-center rounded-r-md disabled:pointer-events-none disabled:opacity-50 px-2  focus-visible:ring-0">
                <ChevronDown className="h-5" />
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="end"
                className="py-2 px-5 hover:bg-gray-3 hover:cursor-pointer"
                onClick={pauseSession}
              >
                <P>Pause Session</P>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}

        {isCompleted && (
          <div className="flex flex-row gap-1 items-center w-fit h-fit rounded-md border pointer-events-none p-3 leading-3 border-brand-9 text-brand-9 font-semibold text-sm">
            Session Completed
          </div>
        )}
      </div>
    </div>
  );
};
