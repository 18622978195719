import { cn } from './cn';
import { FCC } from './types';

export const H1: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h1 className={cn('scroll-m-20 text-3xl font-bold', className)}>
      {children}
    </h1>
  );
};

export const H2: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h2
      className={cn(
        'scroll-m-20 text-lg sm:text-xl font-bold leading-7 tracking-tight text-gray-12',
        className,
      )}
    >
      {children}
    </h2>
  );
};

export const H3: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h3
      className={cn(
        'scroll-m-20 text-base sm:text-lg font-medium leading-6 tracking-tight text-gray-12',
        className,
      )}
    >
      {children}
    </h3>
  );
};

export const H4: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h4
      className={cn(
        'scroll-m-20 text-sm sm:text-base font-semibold tracking-wide text-gray-12',
        className,
      )}
    >
      {children}
    </h4>
  );
};

export const H5: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h5
      className={cn(
        'scroll-m-20 text-xs sm:text-sm font-semibold leading-5 tracking-wide text-gray-12',
        className,
      )}
    >
      {children}
    </h5>
  );
};

export const P: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <p
      className={cn(
        'scroll-m-20 text-xs sm:text-sm tracking-wide font-medium text-gray-11',
        className,
      )}
    >
      {children}
    </p>
  );
};
