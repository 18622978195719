import { useQuery } from '@apollo/client';
import { GetTenantNameDocument } from '@eluve/client-gql-operations';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

export const AdminTenantLayout: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };

  useQuery(GetTenantNameDocument, {
    variables: {
      tenantId,
    },
  });

  return <Outlet />;
};
