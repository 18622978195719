'use client';
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  H5,
  P,
  cn,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@eluve/components';
import { getPatientName } from '@eluve/utils';
import { clsx } from 'clsx';
import { format } from 'date-fns';
import React from 'react';

interface PatientDetailsCardProps {
  appointmentStatus: 'signed' | 'active';
  date: Date;
  patientId?: string;
  patientFirstName?: string;
  patientLastName?: string | null;
  externalPatientId?: string | null;
  userFirstName: string | null;
  userLastName: string;
  signedAt: Date | null;
  externalChartUrl: string | null;
}

const getPatientExternalEhrId = (externalEhrId?: string | null): string => {
  if (!externalEhrId) {
    return '';
  }
  return `ID No: ${externalEhrId}`;
};

export const PatientDetailsCard: React.FC<PatientDetailsCardProps> = ({
  appointmentStatus,
  patientFirstName,
  patientLastName,
  date,
  patientId,
  externalPatientId,
  userFirstName,
  userLastName,
  signedAt,
  externalChartUrl,
}) => {
  return (
    <Card className="w-full rounded-xl border-gray-4 min-h-900">
      <CardHeader className="space-y-0 p-0 px-5 border-b rounded-t-xl bg-gray-1 h-10 flex flex-row justify-between items-center justify-items-center tracking-normal">
        <div>
          <P>Patient Profile</P>
        </div>
      </CardHeader>
      <CardContent className="flex flex-row justify-between rounded-b-xl px-5 py-7 gap-5 bg-white">
        <div className="flex flex-col h-full justify-between">
          <div>
            {patientId && (
              <div>
                <H5>{getPatientName(patientFirstName, patientLastName)}</H5>
                <P>{getPatientExternalEhrId(externalPatientId)}</P>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <P>DATE</P>
          <P className="text-gray-12">{format(date, 'MM/dd/yyyy')}</P>
        </div>

        <div className="flex flex-col gap-1">
          <P>TIME</P>
          <P className="text-gray-12 justify-end">{format(date, 'h:mm a')}</P>
        </div>
        <div className="flex flex-col gap-1 mr-14">
          <P>Status</P>

          <div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Badge
                    className={cn(
                      'w-fit rounded-md pointer-events-none px-1 py-0.5',
                      clsx({
                        'bg-green-100 text-green-700':
                          appointmentStatus === 'signed',
                        'bg-blue-300 text-blue-700':
                          appointmentStatus === 'active',
                      }),
                    )}
                  >
                    {appointmentStatus === 'signed' ? 'Signed' : 'Active'}
                  </Badge>
                </TooltipTrigger>
                <TooltipContent>
                  <P>
                    {userFirstName &&
                      userLastName &&
                      `Signed by ${userFirstName} ${userLastName}`}
                  </P>
                  <P>
                    {signedAt &&
                      `on ${format(signedAt, 'yyyy-MM-dd')} at 
                      ${format(signedAt, 'h:mm a')}`}
                  </P>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div>
            {externalChartUrl && (
              <a
                className="text-blue-600"
                href={externalChartUrl}
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-xs">View External Chart</p>
              </a>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
