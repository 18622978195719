import React, { useEffect, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder-2';
import { Button, SoundBars } from '@eluve/components';

type MicVolumeProps = {
  deviceId: string | null;
};

export const MicVolume: React.FC<MicVolumeProps> = ({ deviceId }) => {
  const [isTesting, setIsTesting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [volume, setVolume] = useState(0);
  const audioStream = useRef<MediaStream | null>(null);
  const { previewAudioStream, startRecording, stopRecording } =
    useReactMediaRecorder({
      audio: deviceId ? { deviceId: { exact: deviceId } } : true,
      askPermissionOnMount: false,
    });

  const toggleRecording = () => {
    if (isTesting && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      setVolume(0);
      stopRecording();
      setIsRecording(false);
    } else {
      startRecording();
    }
    setIsTesting(!isTesting);
  };

  audioStream.current = previewAudioStream;

  useEffect(() => {
    if (!isTesting) {
      return;
    }
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();

    const updateVolume = () => {
      if (!audioStream.current) {
        return;
      }
      const sourceStream = audioContext.createMediaStreamSource(
        audioStream.current,
      );
      sourceStream.connect(analyser);

      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyser.smoothingTimeConstant = 0.9;
      analyser.minDecibels = -70;
      analyser.getByteFrequencyData(dataArray);
      const average =
        dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
      const volume = Math.floor((average / 255) * 100);
      setVolume(volume);
    };

    if (!isRecording) {
      setIsRecording(true);
      const id = setInterval(updateVolume, 50); // Update volume every 50 milliseconds
      setIntervalId(id);
    }
  }, [isTesting, isRecording]);

  return (
    <div className="ml-auto flex space-x-between items-center">
      <div className="p-1" onClick={toggleRecording}>
        <Button variant="outline" className="h-5">
          <span>{isTesting ? 'Stop Audio' : 'Test Audio'}</span>
        </Button>
      </div>
      <div className="text-gray-8 text-sm p-1">Sound Level</div>
      <div className="p-1">
        <SoundBars value={volume} />
      </div>
    </div>
  );
};
