import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const TenantSessionSchema = z.object({});

export const authContract = c.router(
  {
    createTenantSession: {
      method: 'POST',
      path: '/switch-tenant',
      responses: {
        201: TenantSessionSchema,
        403: z.object({
          message: z.string(),
        }),
      },
      body: z.object({
        tenantId: z.string(),
      }),
      summary:
        'Attempts to create a session for a user scoped to a specified tenant',
    },
    attemptPartnerLogin: {
      method: 'POST',
      path: '/attempt-partner-login',
      responses: {
        200: z.object({
          redirectUrl: z.string().url().optional(),
        }),
      },
      body: z.object({
        email: z.string().email(),
      }),
      summary:
        'Attempts to sign in with a white listed partner using an enterprise SSO flow',
    },
    sso: {
      method: 'POST',
      path: '/sso',
      responses: {
        201: TenantSessionSchema,
      },
      body: z.object({
        code: z.string(),
        state: z.string(),
      }),
    },
  },
  {
    pathPrefix: '/auths',
  },
);
