import { useFragment } from '@apollo/client';
import { AppointmentPatientFragmentDoc } from '@eluve/client-gql-operations';
import { useAppointmentCacheId } from '@eluve/smart-blocks';

/**
 * For the current appointment, get external patient id
 */
export const useExternalPatientId = () => {
  const from = useAppointmentCacheId();
  const { complete, data } = useFragment({
    fragment: AppointmentPatientFragmentDoc,
    fragmentName: 'appointmentPatient',
    from,
  });

  if (!complete) {
    throw Error('Used is new session without required data');
  }

  return complete && data?.patient?.external_patients_info?.length
    ? data?.patient?.external_patients_info[0]?.externalPatientId
    : undefined;
};
