import { useMutation } from '@apollo/client';
import { SetAppointmentStartedAtDocument } from '@eluve/client-gql-operations';

export const useSetAppointmentStartedAt = () => {
  const [setAppointmentStartedAt] = useMutation(
    SetAppointmentStartedAtDocument,
    {
      optimisticResponse: ({ id }) => {
        return {
          updateAppointments: {
            returning: [
              {
                id,
                startedAt: new Date().toISOString(),
              },
            ],
          },
        };
      },
    },
  );

  return { setAppointmentStartedAt };
};
