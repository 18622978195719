import { useMutation } from '@apollo/client';
import { useApiClient } from '@eluve/api-client-provider';
import {
  CreateTranscriptDocument,
  InsertUserUploadDocument,
  MarkUserUploadAvailableDocument,
  InsertAppointmentAudioFileDocument,
} from '@eluve/client-gql-operations';
import { useAppointmentId } from './appointment.context';

export const useUploadFile = () => {
  const apiClient = useApiClient();

  const appointmentId = useAppointmentId();
  const [insertUserUpload] = useMutation(InsertUserUploadDocument);
  const [insertAppointmentAudioFile] = useMutation(
    InsertAppointmentAudioFileDocument,
  );
  const [setUserUploadAvailable] = useMutation(MarkUserUploadAvailableDocument);
  const [createTranscript] = useMutation(CreateTranscriptDocument);

  const onUploadSuccess = async (
    gcsFilePath: string,
    fileId: string,
    chunkNumber: number,
  ): Promise<void> => {
    // mark as available in the user uploads table
    await setUserUploadAvailable({
      variables: {
        gcsFilePath: gcsFilePath,
        id: fileId,
      },
    });

    await createTranscript({
      variables: {
        appointmentId,
        audioFileId: fileId,
        chunkNum: chunkNumber,
      },
    });
  };

  const uploadFileToGCS = async (
    file: File,
    mimeType: string,
    fileExt: string,
    chunkNumber: number,
  ): Promise<void> => {
    const userUploadData = await insertUserUpload();

    const userUploadId = userUploadData.data?.insertUserUploadsOne?.id;
    if (!userUploadId) {
      throw new Error('Failed to create user upload');
    }

    await insertAppointmentAudioFile({
      variables: {
        appointmentId,
        fileId: userUploadId,
      },
    });

    const response = await apiClient.gcs.generateSignedUrl({
      body: {
        fileExt,
        userUploadId,
      },
    });

    if (response.status !== 201) {
      throw new Error('Failed to get signed URL');
    }

    const { gcsFilePath, url: signedUrl } = response.body;

    const headers = new Headers();
    headers.append('Content-Type', mimeType);

    try {
      const response = await fetch(signedUrl, {
        method: 'PUT',
        headers,
        body: file,
      });

      if (response.ok) {
        console.log('File uploaded successfully.');
        await onUploadSuccess(gcsFilePath, userUploadId, chunkNumber);
      } else {
        console.log('File upload failed.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  return {
    uploadFileToGCS,
  };
};
