import { Card, CardContent, PageTitle, Skeleton } from '@eluve/components';
import { TenantLayers } from './components/TenantLayers';
import { Suspense } from 'react';

export const LayersPage: React.FC = () => {
  return (
    <div className="container">
      <PageTitle subtitle="Organize Eluve to best fit your practice">
        Layers
      </PageTitle>

      <Card className="pt-4">
        <CardContent>
          <Suspense fallback={<Skeleton />}>
            <TenantLayers />
          </Suspense>
        </CardContent>
      </Card>
    </div>
  );
};
