import { useApolloClient } from '@apollo/client';
import { useSummary } from './useSummary';
import { LatestCombinedSummaryFragmentDoc } from '@eluve/client-gql-operations';
import {
  useAppointmentCacheId,
  useAppointmentStoreApi,
} from '@eluve/smart-blocks';
import { AppointmentSummaryKeys } from '@eluve/utils';

export const useEditSummary = () => {
  const cache = useApolloClient().cache;
  const id = useAppointmentCacheId();
  const storeApi = useAppointmentStoreApi();
  const { summary } = useSummary();

  return (key: AppointmentSummaryKeys, content: string) => {
    cache.updateFragment(
      {
        fragment: LatestCombinedSummaryFragmentDoc,
        id,
      },
      (existing) => {
        return {
          combinedSummaries: [
            {
              __typename: 'CombinedSummaries' as const,
              // TODO(jesse): We may want to have a more specific id in the cache for combined summaries
              // or we may want to update the API to have an object relationship to simply grab the most
              // recent (since its the only one we seem to care about in the UI)
              id,
              summary: {
                ...summary,
                [key]: content,
              },
            },
          ],
        };
      },
    );

    storeApi.setState({
      editorHasChanges: true,
    });
  };
};
