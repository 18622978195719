'use client';

import { initClient } from '@ts-rest/core';
import { apiContract } from '@eluve/api-contract';

export const createTsRestClient = (baseUrl: string) => {
  return initClient(apiContract, {
    baseUrl,
    credentials: 'include',
    baseHeaders: {},
  });
};

export type TsRestClient = ReturnType<typeof createTsRestClient>;
