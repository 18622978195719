import { useLazyQuery } from '@apollo/client';
import { GetLatestCombinedSummaryDocument } from '@eluve/client-gql-operations';
import { useToast } from '@eluve/components';
import { useAppointmentActivity, useAppointmentId } from '@eluve/smart-blocks';
import { useRef } from 'react';
import { usePrevious } from 'react-use';
import { SUMMARY_TAB, useAppointmentTabNav } from './useAppointmentTabNav';

export const useListenForSummary = () => {
  const { isSummarizationInProgress } = useAppointmentActivity();
  const isCompleteRef = useRef(false);
  const appointmentId = useAppointmentId();
  const { setTab } = useAppointmentTabNav();
  const { toast } = useToast();

  const [getLatest] = useLazyQuery(GetLatestCombinedSummaryDocument, {
    variables: {
      appointmentId,
    },
  });

  const updateSummary = async () => {
    await getLatest();
    setTab(SUMMARY_TAB);
    toast({
      title: 'Summary is ready!',
      description: 'Your summary is ready to be reviewed.',
      variant: 'success',
      duration: 5000, // milliseconds
    });
  };

  const wasSummarizationInProgress = usePrevious(
    isSummarizationInProgress ?? false,
  );
  if (
    wasSummarizationInProgress &&
    !isSummarizationInProgress &&
    !isCompleteRef.current
  ) {
    updateSummary();
  }
};
