import { useQuery } from '@apollo/client';
import { GetAdminTenantByIdSummaryDocument } from '@eluve/client-gql-operations';
import {
  Button,
  P,
  Card,
  CardHeader,
  CardContent,
  PageTitle,
} from '@eluve/components';
import { useTenantName } from '@eluve/smart-blocks';
import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { TenantFeatureFlags } from '../../components/TenantFeatureFlags';

export const AdminTenant: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };

  const { data } = useQuery(GetAdminTenantByIdSummaryDocument, {
    variables: {
      tenantId: tenantId,
    },
  });

  const name = useTenantName(tenantId);

  return (
    <div>
      <PageTitle>{name}</PageTitle>

      <div className="grid gap-2">
        <Card className="w-full rounded-xl border-gray-4 min-h-900 mt-5">
          <CardHeader className="space-y-0 p-0 px-5 border-b rounded-t-xl bg-gray-1 h-10 flex flex-row justify-between items-center justify-items-center tracking-normal">
            <div>
              <P>Tenant Summary</P>
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col items-left mt-4">
              <div>
                <P>Tenant ID: {data?.tenantsByPk?.id}</P>
              </div>
              <div>
                <P>Name: {data?.tenantsByPk?.name}</P>
              </div>
              <div>
                <P>
                  Number of appointments:{' '}
                  {data?.tenantsByPk?.appointmentsAggregate?.aggregate?.count ??
                    0}
                </P>
              </div>
              <div>
                <P>
                  Number of users:{' '}
                  {data?.tenantsByPk?.tenantUsersAggregate?.aggregate?.count ??
                    0}
                </P>
              </div>
              <div className="mt-4">
                <Button variant="default">
                  <Link to={`appointments`}>View Appointments</Link>
                </Button>
                <Button variant="default" className="ml-2">
                  <Link to={`users`}>Manage Users</Link>
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
        <TenantFeatureFlags tenantId={tenantId} />
      </div>
    </div>
  );
};
