import { useFragment, useQuery } from '@apollo/client';
import {
  ActiveAppointmentLayoutFragmentDoc,
  GetAppointmentBillingCodesDocument,
} from '@eluve/client-gql-operations';
import { useAppointmentId, useAppointmentCacheId } from '@eluve/smart-blocks';
import { useSummary } from './useSummary';

/**
 * Returns billing codes associated with a summary
 */
export type AppointmentBillingCode = {
  id: string;
  label: string;
  code: string;
  visible: boolean;
  appointmentId: string;
};

const extractBillingCodesFromString = (value: string) => {
  const result: { code: string; label: string }[] = [];
  if (value && value.includes('span')) {
    const spanPattern: RegExp = /<span.*?>(.*?)<\/span>/gs;
    const spans: string[] = value.match(spanPattern) || [];
    spans.forEach((span) => {
      const dataTypeMatch = span.match(/data-type="(.*?)"/);
      if (dataTypeMatch && dataTypeMatch[1] === 'billing-codes') {
        const dataCodeMatch = span.match(/data-code="(.*?)"/);
        const dataDescriptionMatch = span.match(/data-description="(.*?)"/);
        if (dataCodeMatch && dataDescriptionMatch) {
          const code = dataCodeMatch[1];
          const label = dataDescriptionMatch[1];
          if (!result.find((r) => r.code === code)) {
            result.push({
              code,
              label,
            });
          }
        }
      }
    });
  }
  return result;
};

const extractBillingCodesFromSummary = (
  summary: Record<string, string | null | undefined> = {},
) => {
  const result: { code: string; label: string }[] = [];
  for (const value of Object.values(summary)) {
    const billingCodes = extractBillingCodesFromString(value ?? '');
    for (const billingCode of billingCodes) {
      const { code, label } = billingCode;
      if (!result.find((r) => r.code === code)) {
        result.push({
          code,
          label,
        });
      }
    }
  }
  return result;
};

const convertToBillingCodeType = (
  billingCodes: { code: string; label: string }[],
  appointmentId: string,
) => {
  const result: AppointmentBillingCode[] = [];
  for (const billingCode of billingCodes) {
    const { code, label } = billingCode;
    if (!result.find((r) => r.code === code)) {
      result.push({
        code,
        label,
        appointmentId,
        id: '',
        visible: true,
      });
    }
  }
  return result;
};

const combineBillingCodes = (
  billingCodesToAdd: AppointmentBillingCode[],
  existingBillingCodes: AppointmentBillingCode[] = [],
): AppointmentBillingCode[] => {
  const updatedPersistedBillingCodes = existingBillingCodes.map((bc) => {
    const found = billingCodesToAdd.find((bcToAdd) => bcToAdd.code === bc.code);
    return { ...bc, visible: Boolean(found) };
  });

  const newBillingCodes = billingCodesToAdd.filter((bcToAdd) => {
    const found = existingBillingCodes.find((bc) => bcToAdd.code === bc.code);
    return !found;
  });

  return [...updatedPersistedBillingCodes, ...newBillingCodes];
};

export const useSummaryBillingCodes = () => {
  const appointmentId = useAppointmentId();
  const { summary } = useSummary();

  const appointmentCacheId = useAppointmentCacheId();
  const {
    data: appointmentBillingCodesData,
    refetch: refetchAppointmentBillingCodes,
  } = useQuery(GetAppointmentBillingCodesDocument, {
    variables: { appointmentId },
    skip: !appointmentId,
  });

  const { data: appointment } = useFragment({
    fragment: ActiveAppointmentLayoutFragmentDoc,
    fragmentName: 'ActiveAppointmentLayout',
    from: appointmentCacheId,
  });

  const interaction = appointment?.doctorInteraction;
  const additionalUserNotes = interaction?.additionalNotes ?? null;
  // TODO(sargis): need to handle edited but not saved additional notes

  const summaryBillingCodes = extractBillingCodesFromSummary(summary);
  const additionalUserNotesBillingCodes = extractBillingCodesFromString(
    additionalUserNotes ?? '',
  );

  const combinedBillingCodes = [
    ...summaryBillingCodes,
    ...additionalUserNotesBillingCodes.filter(
      (bc) => !summaryBillingCodes.find((sbc) => sbc.code === bc.code),
    ),
  ];

  return {
    appointmentBillingCodes: combineBillingCodes(
      convertToBillingCodeType(combinedBillingCodes, appointmentId),
      appointmentBillingCodesData?.appointmentBillingCodes,
    ),
    refetchAppointmentBillingCodes,
  };
};
