import {
  ApolloClient,
  ApolloClientOptions,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Session from 'supertokens-auth-react/recipe/session';
import { Observable } from 'apollo-link';
import { SessionSchema } from '@eluve/api-contract';

export type ApolloClientFactoryOptions = {
  uri: string;
  desiredRole?: string;
  enableSubscriptions?: boolean;
  cacheInstance: InMemoryCache;
} & Pick<ApolloClientOptions<NormalizedCacheObject>, 'defaultOptions'>;

const defaultApolloClientOptions: ApolloClientOptions<NormalizedCacheObject>['defaultOptions'] =
  {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  };

export const sessionBasedClientFactory: (
  options: ApolloClientFactoryOptions,
) => ApolloClient<NormalizedCacheObject> = (options) => {
  const {
    uri,
    cacheInstance,
    defaultOptions = defaultApolloClientOptions,
    enableSubscriptions = true,
    desiredRole,
  } = options;

  let jwt: string | undefined;
  let role: string | undefined;

  const ensureTokenExists = async () => {
    const payload = await Session.getAccessTokenPayloadSecurely();
    const result = SessionSchema.safeParse(payload);
    if (result.success) {
      role = desiredRole
        ? desiredRole
        : result.data['https://hasura.io/jwt/claims']['x-hasura-default-role'];
    }

    jwt = await Session.getAccessToken();
  };

  const authLink = setContext(async (_request, { headers }) => {
    await ensureTokenExists();

    const newHeaders = {
      ...headers,
      Authorization: `Bearer ${jwt}`,
      'x-hasura-role': headers?.['x-hasura-role'] ?? role,
    };

    return {
      headers: newHeaders,
    };
  });

  /**
   * If the request fails due to a JWTExpired error from Hasura, we will attempt to refresh the token
   * and then re-try the request one time
   */
  const retryErrorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (
      graphQLErrors &&
      graphQLErrors.some((e) => e.message.includes('JWTExpired'))
    ) {
      return new Observable((observer) => {
        ensureTokenExists()
          .then(() => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                Authorization: `Bearer ${jwt}`,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            role = undefined;
            observer.error(error);
          });
        // Typing this is wonky but this is the recommended approach from a bunch of research into Apollo client workarounds
        // for async refresh code
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any;
    }

    return;
  });

  const authFlowLink = authLink.concat(retryErrorLink);

  const httpLink = authFlowLink.concat(
    createHttpLink({
      uri,
      fetch,
    }),
  );

  let finalLink = httpLink;

  // If subscriptions are enabled we need to set up a websocket and then split control
  // over the operations so that subscriptions will be handled by the WS Link
  if (enableSubscriptions) {
    const [protocol, endpoint] = uri.split('//');
    const wsUri = protocol.startsWith('https')
      ? `wss://${endpoint}`
      : `ws://${endpoint}`;

    const wsLink = new GraphQLWsLink(
      createClient({
        url: wsUri,
        retryAttempts: 2,
        shouldRetry: () => true,
        connectionParams: async () => {
          const accessTokenPayload =
            await Session.getAccessTokenPayloadSecurely();

          const jwt = await Session.getAccessToken();

          const result = SessionSchema.safeParse(accessTokenPayload);

          const role = result.success
            ? result.data['https://hasura.io/jwt/claims'][
                'x-hasura-default-role'
              ]
            : 'anonymous';

          return {
            headers: {
              Authorization: `Bearer ${jwt}`,
              'x-hasura-role': desiredRole ? desiredRole : role,
            },
          };
        },
      }),
    );

    finalLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );
  }

  return new ApolloClient({
    cache: cacheInstance,
    link: finalLink,
    defaultOptions,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });
};
