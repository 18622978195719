import { PostMessenger } from '@eluve/post-messenger';

const postMessenger = new PostMessenger({ name: 'web-app' });

let isPostMessengerConnected = false;

type Ehr = {
  domain: string;
  vendor: string;
};

const initPostMessenger = async (ehrs: Ehr[]) => {
  if (isPostMessengerConnected) {
    return;
  }
  isPostMessengerConnected = true;
  await postMessenger.connect({
    targetOrigin: window.location.origin,
    targetWindow: window.parent,
    data: {
      ehrs,
    },
  });
  console.log(`PostMessenger connected at ${new Date().toISOString()}`);
};

export { initPostMessenger, postMessenger };
