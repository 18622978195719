import { Card, CardContent, PageTitle, Skeleton } from '@eluve/components';
import React, { Suspense } from 'react';
import { Locations } from './components/Locations';

export const LocationsPage: React.FC = () => {
  return (
    <div className="container">
      <PageTitle subtitle="Add locations for your practice to help keep things organized">
        Locations
      </PageTitle>

      <Card className="pt-4">
        <CardContent>
          <Suspense fallback={<Skeleton />}>
            <Locations />
          </Suspense>
        </CardContent>
      </Card>
    </div>
  );
};
