import { z } from 'zod';

// TODO(jesse): Consider splitting to a dedicated lib for
// shared schemas
export const AppointmentSummarySchema = z
  .object({
    SUBJECTIVE: z.string().nullish(),
    OBJECTIVE: z.string().nullish(),
    ASSESSMENT: z.string().nullish(),
    PLAN: z.string().nullish(),
    CLIENT_RECAP: z.string().nullish(),
  })
  // Don't allow additional keys on this object
  .strict();

export type AppointmentSummary = z.infer<typeof AppointmentSummarySchema>;
export type AppointmentSummaryKeys = keyof AppointmentSummary;
