import React from 'react';
import { DepthContainer } from './DepthContainer';
import { FCC } from '@eluve/components';

export const TreeNodeContainer: FCC<{ depth: number; bottom?: boolean }> = ({
  children,
  depth,
  bottom = false,
}) => {
  return (
    <DepthContainer depth={depth}>
      <div className="p-4 border border-gray-8 rounded-lg flex-1 shadow-md">
        {children}
      </div>
    </DepthContainer>
  );
};
