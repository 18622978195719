'use client';
// This is a modified version of the AutoComplete component from https://github.com/armandsalle/my-site/blob/main/src/components/autocomplete.tsx

import {
  CommandGroup,
  CommandItem,
  CommandList,
  CommandInput,
  cn,
  Skeleton,
  P,
} from '@eluve/components';
import { Command as CommandPrimitive } from 'cmdk';
import {
  useState,
  useRef,
  useCallback,
  type KeyboardEvent,
  useEffect,
} from 'react';
import { calculateAge } from '@eluve/date-utils';

export type AutoCompleteOption = Record<'value' | 'label', string> &
  Record<string, string | undefined | null>;

type AutoCompleteProps = {
  options: AutoCompleteOption[];
  value?: AutoCompleteOption;
  onValueChange?: (value: AutoCompleteOption) => void;
  isLoading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  createNewPatient: (fullName: string) => void;
};

const getPatientDetails = ({
  dob,
  phoneNumber,
}: {
  dob?: string | null;
  phoneNumber?: string | null;
}): string => {
  const patientDetails: string[] = [];
  if (dob) {
    patientDetails.push(dob);
    const age = calculateAge(dob);
    patientDetails.push(`${age} yo`);
  }
  if (phoneNumber) {
    patientDetails.push(phoneNumber);
  }
  return patientDetails.join(' | ');
};

export const AutoComplete = ({
  options,
  placeholder,
  value,
  onValueChange,
  disabled,
  isLoading = false,
  createNewPatient,
}: AutoCompleteProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState<AutoCompleteOption>(
    value as AutoCompleteOption,
  );
  const [inputValue, setInputValue] = useState<string>(value?.label || '');

  useEffect(() => {
    if (value?.label) {
      setInputValue(value?.label);
    }
  }, [value]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (!input) {
        return;
      }

      // Keep the options displayed when the user is typing
      if (!isOpen) {
        setOpen(true);
      }

      if (event.key === 'Escape') {
        input.blur();
      }
    },
    [isOpen],
  );

  const handleBlur = useCallback(() => {
    setOpen(false);
    setInputValue(selected?.label);
  }, [selected]);

  const handleSelectOption = useCallback(
    (selectedOption: AutoCompleteOption) => {
      setInputValue(selectedOption.label);

      setSelected(selectedOption);
      onValueChange?.(selectedOption);

      // This is a hack to prevent the input from being focused after the user selects an option
      // We can call this hack: "The next tick"
      setTimeout(() => {
        inputRef?.current?.blur();
      }, 0);
    },
    [onValueChange],
  );

  return (
    <CommandPrimitive
      onKeyDown={handleKeyDown}
      filter={(value, search) => {
        if (value === '*') {
          return 1;
        }
        if (
          value &&
          search &&
          value.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        ) {
          return 1;
        }
        return 0;
      }}
    >
      <div>
        <CommandInput
          ref={inputRef}
          value={inputValue}
          onValueChange={isLoading ? undefined : setInputValue}
          onBlur={handleBlur}
          onFocus={() => setOpen(true)}
          placeholder={placeholder}
          disabled={disabled}
          className="text-base"
        />
      </div>
      <div className="relative">
        {isOpen ? (
          <div className="absolute top-1 z-10 w-full rounded-xl bg-stone-50 outline-none animate-in fade-in-0 zoom-in-95">
            <CommandList
              className={
                options.length
                  ? 'ring-1 ring-slate-200 rounded-lg'
                  : 'ring-slate-200 rounded-lg'
              }
            >
              {isLoading ? (
                <CommandPrimitive.Loading>
                  <div className="p-1">
                    <Skeleton className="h-8 w-full" />
                  </div>
                </CommandPrimitive.Loading>
              ) : null}
              {!isLoading ? (
                <CommandGroup className="p-3">
                  {options.map((option, index) => {
                    // index is required to make each search attribute unique
                    // wihtout index, all identical results will be highlighted
                    const searchableFields: string[] = [`${index}`];

                    // Allow user to search patients by any attribute
                    for (const key in option) {
                      const value = option[key];
                      if (value && key !== 'value') {
                        searchableFields.push(value);
                      }
                    }
                    const searchableField = searchableFields.join('');
                    return (
                      <CommandItem
                        key={option.value}
                        value={searchableField}
                        onMouseDown={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        onSelect={() => handleSelectOption(option)}
                        className={cn(
                          'flex items-center gap-2 w-full aria-selected:bg-blue-100 pl-3 pr-3',
                        )}
                      >
                        <div className="w-full">
                          <div className="flex flex-row justify-between items-center w-full mb-1">
                            <P className="text-gray-12">{option.label}</P>
                            <P className="text-gray-9 text-xs">
                              {option.externalEhrId
                                ? `ID ${option.externalEhrId}`
                                : ''}
                            </P>
                          </div>
                          <div className="flex">
                            <div className="flex flex-col">
                              <P className="text-gray-9 text-xs">
                                {getPatientDetails({
                                  dob: option.dob,
                                  phoneNumber: option.phoneNumber,
                                })}
                              </P>
                            </div>
                          </div>
                        </div>
                      </CommandItem>
                    );
                  })}
                  <div>
                    <CommandItem
                      value={'*'}
                      onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      onSelect={() => {
                        if (inputValue && inputValue.trim()) {
                          createNewPatient(inputValue.trim());
                        }
                      }}
                      className={cn(
                        'flex flex-col items-center gap-2 w-full aria-selected:bg-blue-100 pl-3 pr-3 bg-gray-100 text-center',
                      )}
                    >
                      <div className="select-none rounded-sm px-2 py-3 text-sm text-center">
                        <div className="m-1">
                          {inputValue ? (
                            <P>
                              Create a new patient:{' '}
                              <strong>{inputValue}</strong>
                            </P>
                          ) : (
                            <P>Start typing patient name</P>
                          )}
                        </div>
                      </div>
                    </CommandItem>
                  </div>
                </CommandGroup>
              ) : null}
            </CommandList>
          </div>
        ) : null}
      </div>
    </CommandPrimitive>
  );
};
