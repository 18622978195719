'use client';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  H4,
  Textarea,
  Toggle,
} from '@eluve/components';
import { FeedbackTypeEnum } from '@eluve/graphql-types';

import { ThumbsDown, ThumbsUp } from 'lucide-react';

import React, { useState } from 'react';
import { FeedbackLogoThumbsDown } from './FeedbackLogoThumbsDown';
import { FeedbackLogoThumbsUp } from './FeedbackLogoThumbsUp';
import { RatingSelector } from './RatingSelector';

export type Tag = {
  id: number;
  name: string;
  isTagged: boolean;
};

export interface FeedbackModalProps {
  feedbackType: FeedbackTypeEnum | null;
  tags: Tag[];
  onFeedbackClick: (type: FeedbackTypeEnum) => Promise<void>;
  onTagPressedChange: (tagId: number, isTagged: boolean) => Promise<void>;
  onAdditionalFeedbackSubmit: (
    type: FeedbackTypeEnum | null,
    comment: string,
    tags: Tag[],
    rating: number | null,
  ) => Promise<void>;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  tags,
  feedbackType,
  onFeedbackClick,
  onTagPressedChange,
  onAdditionalFeedbackSubmit,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [feedbackComment, setFeedbackComment] = useState<string>('');
  const [rating, setRating] = useState<number | null>(null);

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const toggleDialog = (open: boolean) => {
    setIsDialogOpen(open);
  };

  const handleFeedbackClick = async (type: FeedbackTypeEnum) => {
    // only allow one feedback per instance? -- this is what chat gpt does
    if (feedbackType !== null) {
      return;
    }

    setIsDialogOpen(true);

    await onFeedbackClick(type);
  };

  const handleAdditionalFeedbackSubmit = async () => {
    await onAdditionalFeedbackSubmit(
      feedbackType,
      feedbackComment,
      tags,
      rating,
    );

    setIsDialogOpen(false);
  };

  const isPositiveFeedback = feedbackType === 'POSITIVE';
  const isNegativeFeedback = feedbackType === 'NEGATIVE';

  return (
    <Dialog open={isDialogOpen} onOpenChange={toggleDialog}>
      <div className="flex flex-row items-center px-[2px] py-[1.5px] border border-gray-5 w-fit rounded-3xl">
        <Button
          className={`w-6 h-6 rounded-xl p-1 bg-transparent text-gray-10 hover:bg-brand-4 ${
            isPositiveFeedback && 'bg-brand-4 text-brand-11 hover:bg-brand-4'
          }`}
          onClick={() => handleFeedbackClick('POSITIVE')}
        >
          <ThumbsUp className="w-3.5 h-3.5" />
        </Button>

        <Button
          className={`w-6 h-6 rounded-xl p-1 bg-transparent text-gray-10 hover:bg-brand-4 ${
            isNegativeFeedback && 'bg-brand-4 text-brand-11 hover:bg-brand-4'
          }`}
          onClick={() => handleFeedbackClick('NEGATIVE')}
        >
          <ThumbsDown className="w-3.5 h-3.5" />
        </Button>
      </div>

      <DialogContent className="sm:max-w-[700px] flex flex-col items-center justify-center p-4 sm:p-10 gap-3 sm:gap-10">
        {isPositiveFeedback ? (
          <FeedbackLogoThumbsUp />
        ) : (
          <FeedbackLogoThumbsDown />
        )}

        <H2> Any additional feedback? </H2>

        <div className="flex flex-col gap-14 w-full">
          <div className="flex flex-col gap-3">
            <H4 className="font-normal tracking-tight">
              How would you rate your Eluve experience for this session?
            </H4>

            <RatingSelector
              onRatingSelected={(value) => setRating(value)}
              labelFor0="Poor"
              labelFor5="Okay"
              labelFor10="Excellent"
            />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <Textarea
              className="w-full h-[100px] resize-y box-shadow-none text-gray-12 outline-none focus-visible:ring-0"
              value={feedbackComment}
              placeholder="Tell us more!"
              onChange={(e) => setFeedbackComment(e.target.value)}
            />

            <div>
              {tags.map((tag) => (
                <Toggle
                  key={tag.id}
                  variant="outline"
                  className="text-xs sm:text-sm text-gray-12 mr-2 my-0.5 rounded-3xl h-8 hover:text-brand-11 hover:bg-brand-4 hover:border-brand-4 data-[state=on]:text-brand-11 data-[state=on]:bg-brand-4 data-[state=on]:border-brand-4"
                  pressed={tag.isTagged}
                  onPressedChange={(isTagged) => {
                    onTagPressedChange(tag.id, isTagged);
                  }}
                >
                  {capitalizeFirstLetter(tag.name)}
                </Toggle>
              ))}
            </div>
          </div>
        </div>

        <DialogFooter className="flex flex-col items-end w-full">
          <Button onClick={handleAdditionalFeedbackSubmit}>
            Submit Feedback
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
