import { useFragment } from '@apollo/client';
import { BaseAppointmentInfoFragmentDoc } from '@eluve/client-gql-operations';
import { useAppointmentCacheId } from './appointment.context';

/**
 * Indicates whether or not this is a brand new session based started at timestamp
 */
export const useIsNewSession = () => {
  const from = useAppointmentCacheId();
  const { data, complete } = useFragment({
    fragment: BaseAppointmentInfoFragmentDoc,
    from,
  });

  if (!complete) {
    throw Error('Used is new session without required data');
  }

  return !data?.startedAt;
};
