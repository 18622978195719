import { useQuery } from '@apollo/client';
import { GetAdminTenantAppointmentsDocument } from '@eluve/client-gql-operations';
import { Button, DataTable, P, PageTitle } from '@eluve/components';
import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ArrowUpDown } from 'lucide-react';
import dayjs from 'dayjs';

type TenantRow = {
  id: string;
  name: string;
  patientId: string | null;
  userId?: string;
  userName?: string;
  noteSignedAt?: string | null;
  lastTranscriptCreatedAt: string;
};

const columns: ColumnDef<TenantRow>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Name <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <Link to={`../appointments/${row.original.id}`} relative="path">
          {row.original.name ? row.original.name : row.original.id}
        </Link>
      );
    },
  },
  {
    accessorKey: 'patientId',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Patient ID <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{row.original.patientId}</P>;
    },
  },
  {
    accessorKey: 'userName',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Signed By (name)
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{row.original.userName}</P>;
    },
  },
  {
    accessorKey: 'userId',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Signed By (ID) <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{row.original.userId}</P>;
    },
  },
  {
    accessorKey: 'noteSignedAt',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Signed At <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{row.original.noteSignedAt}</P>;
    },
  },
  {
    accessorKey: 'lastTranscriptCreatedAt',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Last Transcript Created At <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{row.original.lastTranscriptCreatedAt}</P>;
    },
  },
];

const formatTimestampToLocale = (timestamp?: string | null): string => {
  return timestamp ? dayjs(timestamp).format('MMMM D, YYYY h:mm A') : '';
};

export const AdminAppointments: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };
  const { data } = useQuery(GetAdminTenantAppointmentsDocument, {
    variables: {
      tenantId: tenantId!,
    },
  });

  const rows = useMemo(
    () =>
      (data?.tenantsByPk?.appointments ?? []).map<TenantRow>((t) => {
        return {
          id: t.id,
          name: t.name,
          patientId: t.patientId,
          noteSignedAt: formatTimestampToLocale(
            t.doctorInteraction?.noteSignedAt,
          ),
          userId: t.user?.id,
          userName: [t.user?.firstName, t.user?.lastName]
            .filter(Boolean)
            .join(' '),
          lastTranscriptCreatedAt: formatTimestampToLocale(
            t.transcripts[0]?.createdAt,
          ),
        };
      }),
    [data],
  );

  return (
    <div>
      <PageTitle>Appointments</PageTitle>
      <div>
        <DataTable data={rows} columns={columns} />
      </div>
    </div>
  );
};
