import { useFragment } from '@apollo/client';
import { useCacheIdBuilder } from '@eluve/apollo-client';
import { AppointmentNameFragmentDoc } from '@eluve/client-gql-operations';

export const useAppointmentName = (appointmentId: string) => {
  const from = useCacheIdBuilder()({
    typeName: 'Appointments',
    key: appointmentId,
  });

  const { data } = useFragment({
    fragment: AppointmentNameFragmentDoc,
    from,
  });

  return data?.name ?? '';
};
