import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const supportedPatientVendorsSchema = z.enum(['JANE']);

export type SupportedPatientVendors = z.infer<
  typeof supportedPatientVendorsSchema
>;

export const patientsContract = c.router(
  {
    importPatient: {
      method: 'POST',
      path: '/',
      responses: {
        201: z.object({
          id: z.string(),
        }),
      },
      body: z.object({
        data: z.record(z.unknown()),
        vendor: supportedPatientVendorsSchema,
        domain: z.string(),
      }),
      summary: 'Create a patient',
    },
  },
  {
    pathPrefix: '/patients',
  },
);
