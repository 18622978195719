import { useApiClient } from '@eluve/api-client-provider';
import React, { useRef, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { doesSessionExist } from 'supertokens-auth-react/recipe/session';
import { useToast } from '@eluve/components';

export interface SsoPageProps {}

export const SsoPage: React.FC<SsoPageProps> = () => {
  const [search] = useSearchParams();
  const { toast } = useToast();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  // Needed for React 18 strict mode double renders...
  const attemptingFlow = useRef(false);

  const code = search.get('code') ?? '';
  const state = search.get('state') ?? '';

  useEffect(() => {
    const sso = async () => {
      attemptingFlow.current = true;
      await apiClient.auth.sso({
        body: {
          code,
          state,
        },
      });

      const validSession = await doesSessionExist();
      if (validSession) {
        navigate('/');
      } else {
        toast({
          title: 'Sign in failed',
          variant: 'destructive',
        });

        navigate('/login');
      }
    };

    if (!attemptingFlow.current) {
      sso();
    }
  }, [code, navigate, apiClient.auth, state, toast]);

  return (
    <div className="h-full grid place-items-center">
      <div className="text-2xl">Validating Session...</div>
    </div>
  );
};
