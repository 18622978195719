import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const llmContract = c.router(
  {
    summarizeAppointment: {
      method: 'POST',
      path: '/:appointmentId/summarize',
      body: z.object({}).optional(),
      responses: {
        201: z.object({
          jobId: z.string(),
        }),
      },
    },
  },
  { pathPrefix: '/llm' },
);
