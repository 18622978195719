// TODO(paulmest): clean this up. We probably only need one of these two functions.
interface MediaParams {
  media: string;
  types: string[];
  codecs: string[];
}

interface SupportedMimeTypes {
  supported: string[];
}

const getSupportedMimeTypes = ({
  media,
  types,
  codecs,
}: MediaParams): SupportedMimeTypes => {
  const isSupported = MediaRecorder.isTypeSupported;
  const supported: string[] = [];
  types.forEach((type) => {
    const mimeType = `${media}/${type}`;
    codecs.forEach((codec) =>
      [
        `${mimeType};codecs=${codec}`,
        `${mimeType};codecs=${codec.toUpperCase()}`,
        // /!\ false positive /!\
        // `${mimeType};codecs:${codec}`,
        // `${mimeType};codecs:${codec.toUpperCase()}`
      ].forEach((variation) => {
        if (isSupported(variation)) supported.push(variation);
      }),
    );
    if (isSupported(mimeType)) supported.push(mimeType);
  });
  return { supported };
};

// Usage ------------------

const videoTypes = ['webm', 'ogg', 'mp4', 'x-matroska'];
const audioTypes = [
  'webm',
  'ogg',
  'mp3',
  'x-matroska',
  'aac',
  'flac',
  'wav',
  'pcm',
  'mp4',
];
const codecs = [
  'should-not-be-supported',
  'vp9',
  'vp9.0',
  'vp8',
  'vp8.0',
  'avc1',
  'av1',
  'h265',
  'h.265',
  'h264',
  'h.264',
  'opus',
  'pcm',
  'aac',
  'mpeg',
  'mp4a',
];

const getSupportedAudioTypes = () => {
  // const mediaRecorder = document.createElement('video').captureStream().getVideoTracks()[0].getCapabilities().encoderId;
  const supportedTypes: string[] = [];

  const testTypes = [
    { type: 'audio/webm', ext: 'webm' },
    { type: 'audio/ogg', ext: 'ogg' },
    { type: 'audio/wav', ext: 'wav' },
    { type: 'audio/mp3', ext: 'mp3' },
    { type: 'audio/mpeg', ext: 'mp3' },
    { type: 'audio/x-m4a', ext: 'm4a' },
    { type: 'audio/aac', ext: 'aac' },
  ];

  testTypes.forEach(({ type, ext }) => {
    const mimeType = `audio/${ext}`;
    if (
      MediaRecorder.isTypeSupported(type) ||
      MediaRecorder.isTypeSupported(mimeType)
    ) {
      supportedTypes.push(mimeType);
    }
  });

  return supportedTypes;
};

const supportedAudioTypes = getSupportedAudioTypes();
// console.log(supportedAudioTypes);

const supportedVideos = getSupportedMimeTypes({
  media: 'video',
  types: videoTypes,
  codecs: codecs,
});
const supportedAudios = getSupportedMimeTypes({
  media: 'audio',
  types: audioTypes,
  codecs: codecs,
});

// console.log("-- Top supported Video : ", supportedVideos.supported[0]);
// console.log("-- Top supported Audio : ", supportedAudios.supported[0]);
// console.log("-- All supported Videos : ", supportedVideos.supported);
// console.log("-- All supported Audios : ", supportedAudios.supported);

export const TOP_SUPPORTED_AUDIO = supportedAudios.supported[0];
export const TOP_SUPPORTED_AUDIO_EXTENSION = supportedAudios.supported[0]
  .split(';')[0]
  .split('/')[1];
