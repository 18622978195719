import { PageTitle } from '@eluve/components';
import React from 'react';

export const LocationPage: React.FC = () => {
  return (
    <div className="container">
      <PageTitle>Location Name Here</PageTitle>
    </div>
  );
};
