'use client';
import { useMutation } from '@apollo/client';
import {
  InsertDoctorInteractionDocument,
  InsertDoctorInteractionSignedNoteDocument,
} from '@eluve/client-gql-operations';
import {
  Button,
  Dialog,
  DialogContent,
  H2,
  P,
  cn,
  useToast,
} from '@eluve/components';
import {
  extensions,
  EluveEditor,
  EluveEditorHandle,
} from '@eluve/eluve-editor';
import { Extension } from '@tiptap/react';
import { MessageResponse } from '@eluve/post-messenger';
import { useSession } from '@eluve/session-helpers';
import { Editor as EditorClass } from '@tiptap/core';
import { CheckCircle2Icon, Loader2 } from 'lucide-react';
import React, { useRef, useState } from 'react';
import {
  useAppointmentStore,
  useAppointmentStoreApi,
} from './appointment.store';
import {
  HumanFriendlyError,
  SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS,
} from './humanFriendlyErrors';
import { useAppointmentActivity } from './useAppointmentActivity';

export interface AppointmentControlsFooterProps {
  className?: string;
  appointmentId: string;
  patientId?: string;
  patientFirstName: string;
  patientLastName?: string;
  patientExternalId?: string;
  locationId?: string;
  additionalUserNotes: string | null;
  visible: boolean;
  isOpen: boolean;
  toggleDialog: () => void;
  handleSaveDraft: () => Promise<void>;
  syncToEHR: (options: {
    sign: boolean;
  }) => Promise<MessageResponse<{ chartUrl?: string }>>;
  patientEhrUrl?: string;
  ehrName?: string;
  billingCodesEnabled: boolean;
}

export const AppointmentControlsFooter: React.FC<
  AppointmentControlsFooterProps
> = ({
  className,
  appointmentId,
  patientId,
  patientFirstName,
  patientLastName,
  additionalUserNotes,
  visible,
  isOpen,
  toggleDialog,
  handleSaveDraft,
  syncToEHR,
  patientExternalId,
  locationId,
  patientEhrUrl,
  ehrName,
  billingCodesEnabled = false,
}) => {
  const [additionalNotesEdited, setAdditionalNotesEdited] = useState<
    string | null
  >(null);

  const { toast } = useToast();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [signNoteDialogOpen, setSignNoteDialogOpen] = useState<boolean>(false);
  const [signingInProgress, setSigningInProgress] = useState<boolean>(false);
  const [signingEhrError, setSigningEhrError] =
    useState<HumanFriendlyError | null>(null);
  const patientFullName = [patientFirstName, patientLastName]
    .filter(Boolean)
    .join(' ');

  const { firstName: userFirstName, lastName: userLastName } = useSession();

  const { isTranscriptionInProgress, isSummarizationInProgress } =
    useAppointmentActivity();

  const storeApi = useAppointmentStoreApi();

  const editorHasChanges = useAppointmentStore(
    (state) => state.editorHasChanges,
  );

  const editorRef = useRef<EluveEditorHandle | null>(null);

  const [saveAdditionalNotes] = useMutation(InsertDoctorInteractionDocument);

  const [insertSignedNote] = useMutation(
    InsertDoctorInteractionSignedNoteDocument,
    {
      optimisticResponse: ({ additionalNotes, externalChartUrl }) => ({
        insertAppointmentDoctorInteractionsOne: {
          __typename: 'AppointmentDoctorInteractions' as const,
          appointmentId,
          appointment: {
            __typename: 'Appointments' as const,
            id: appointmentId,
            doctorInteraction: {
              __typename: 'AppointmentDoctorInteractions' as const,
              appointmentId,
              additionalNotes,
              externalChartUrl,
              noteSignedAt: new Date().toISOString(),
              user: {
                __typename: 'Users' as const,
                id: '-1',
                firstName: userFirstName,
                lastName: userLastName,
              },
            },
          },
        },
      }),
    },
  );

  const onEditorContentUpdated = (
    editor?: EditorClass | undefined,
  ): void | Promise<void> => {
    if (editor) {
      const content = editor.storage.markdown.getMarkdown();
      setAdditionalNotesEdited(content);
    }
  };

  const saveDraft = async () => {
    if (additionalNotesEdited) {
      // save additional notes
      await saveAdditionalNotes({
        variables: {
          appointmentId,
          additionalNotes: additionalNotesEdited,
        },
      });

      toggleDialog();
    }

    // handle save draft upstream
    await handleSaveDraft();
    // TODO(jesse): Its awkward needing to put this here. If we extracted things out to a feature
    // lib then all the related components could be in a more optimal spot and this wouldn't be needed
    storeApi.setState({
      editorHasChanges: false,
    });
  };

  if (!visible) {
    return null;
  }

  const showSuccessToast = (text: string) => {
    toast({
      duration: 4000, // milliseconds
      variant: 'success',

      title: (
        <div className="w-full flex items-center">
          <CheckCircle2Icon className="mr-2 w-10 h-10" />
          <div className="flex flex-col gap-0.5">
            <span className="first-letter:capitalize font-semibold text-base">
              Sync Successful
            </span>
            <span className="font-normal">{text}</span>
          </div>
        </div>
      ),
    });
  };

  const showErrorToast = (title: string) => {
    toast({
      duration: 2000, // 2 seconds
      title,
      description: 'Please try again',
      variant: 'destructive',
    });
  };

  const onSignNoteClick = () => {
    // Open the dialog with the confirmation content
    saveDraft();
    setConfirmationDialogOpen(true);
  };

  const submitNoteOnEluve = async (
    externalChartUrl?: string | null,
  ): Promise<boolean> => {
    const { errors } = await insertSignedNote({
      variables: {
        appointmentId,
        additionalNotes: additionalNotesEdited ?? additionalUserNotes,
        externalChartUrl: externalChartUrl || null,
      },
    });
    if (!errors) {
      editorRef.current?.setEditable(false);
      return true;
    } else {
      return false;
    }
  };

  const confirmAndSignNote = async () => {
    // hide the confirmation dialog
    setConfirmationDialogOpen(false);
    setSigningEhrError(null);

    if (!patientExternalId) {
      const isSubmitted = await submitNoteOnEluve();
      if (isSubmitted) {
        showSuccessToast('Your note has been successfully submitted in Eluve');
      } else {
        showErrorToast('Failed to sign note.');
      }
      return;
    }

    setSignNoteDialogOpen(true);
    setSigningInProgress(true);

    try {
      const response = await syncToEHR({ sign: true });
      console.log('Sync to EHR response', response);

      if (!response.ok) {
        let humanFriendlyErrorMessage: HumanFriendlyError =
          SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS.GENERIC_ERROR;
        if (
          response.error &&
          response.error in SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS
        ) {
          humanFriendlyErrorMessage =
            SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS[response.error];
        }
        setSigningEhrError(humanFriendlyErrorMessage);
        return;
      }

      setSignNoteDialogOpen(false);

      const isSubmitted = await submitNoteOnEluve(response.data?.chartUrl);

      if (isSubmitted) {
        showSuccessToast(
          `Your note has been successfully synced to ${ehrName}`,
        );
      } else {
        showErrorToast('Failed to sign note.');
      }
    } catch (error) {
      setSigningEhrError(SYNC_ERRORS_TO_HUMAN_FRIENDLY_ERRORS.TIMEOUT);
      return;
    } finally {
      setSigningInProgress(false);
    }
  };

  const additionalExtensions: Extension[] = [];
  if (billingCodesEnabled) {
    additionalExtensions.push(
      extensions.BillingCodes.configure({
        html: true,
        HTMLAttributes: {
          class: 'billing-code bg-secondary',
        },
      }) as Extension,
    );
  }

  const eluveExtensionExists = document.getElementById('eluveExtExists');

  return (
    <div
      className={cn(
        'flex w-full bg-gray-2 fixed bottom-0 border-t py-6 pb-8 gap-4 justify-center',
        className,
      )}
    >
      <div className="flex flex-col w-full container gap-2 items-end">
        <div className="flex flex-col w-full gap-2">
          <P className="text-gray-12 gap-2">Additional Notes</P>

          <div
            id="eluve-editor-container"
            className={`bg-white rounded-lg overflow-y-auto transition-all delay-100 duration-300 border border-gray-4 ${
              isOpen ? 'h-96' : 'h-20'
            }`}
            onClick={() => {
              toggleDialog();
            }}
          >
            <EluveEditor
              ref={editorRef}
              content={additionalNotesEdited ?? additionalUserNotes ?? ''}
              onUpdate={onEditorContentUpdated}
              extensions={additionalExtensions}
            />
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <Button
            variant="secondary"
            onClick={saveDraft}
            disabled={!editorHasChanges && !additionalNotesEdited}
            className="w-36"
          >
            Save Draft
          </Button>
          <div
            title={(() => {
              if (!patientId) {
                return 'Select a patient sign';
              }
              if (!locationId) {
                return 'Select a location to sign';
              }
              return 'Sign note';
            })()}
          >
            <Button
              disabled={
                !patientId ||
                isTranscriptionInProgress ||
                isSummarizationInProgress ||
                !locationId
              }
              onClick={onSignNoteClick}
              className="w-36"
            >
              {patientExternalId && eluveExtensionExists
                ? 'Sync to EHR'
                : 'Sign Note'}
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        open={confirmationDialogOpen}
        onOpenChange={setConfirmationDialogOpen}
      >
        <DialogContent className="flex flex-col items-center justify-center p-10 gap-5">
          <H2>Are you sure?</H2>
          {patientFullName && patientExternalId && (
            <P className="font-medium text-base text-gray-10 text-center">
              After signing this note, this note will be added as a signed chart
              entry in {ehrName} for{' '}
              <a
                className="text-indigo-400"
                href={patientEhrUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {patientFullName}
              </a>
              .
            </P>
          )}
          <P className="font-medium text-base text-gray-10 text-center">
            You will no longer be able to edit this note in Eluve.
          </P>

          <div className="flex w-full justify-center items-center gap-3">
            <Button
              type="button"
              className="border border-gray-5 bg-white text-gray-10 hover:bg-white"
              onClick={() => setConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-blue-500 border-none text-white hover:bg-blue-500"
              onClick={confirmAndSignNote}
            >
              Yes, Sign Note
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={signNoteDialogOpen} onOpenChange={setSignNoteDialogOpen}>
        <DialogContent className="flex flex-col items-center justify-center p-10 gap-5">
          {signingInProgress && (
            <div>
              <div className="flex flex-col items-center">
                <H2>Signing On {ehrName}</H2>
              </div>
              <P className="m-3 font-medium text-base text-gray-10 text-center">
                We are in the process of signing on {ehrName}. This should only
                take a few moments.
              </P>
              <div className="flex justify-center items-center">
                <Loader2 className="m-8 animate-spin h-12 w-12 text-blue-500" />
              </div>
            </div>
          )}

          {signingEhrError && (
            <div>
              <div className="m-3 flex flex-col items-center">
                <H2>{signingEhrError.title}</H2>
              </div>
              <div className="mb-6">
                <P className="font-medium text-base text-gray-10 text-center">
                  {signingEhrError.message}
                </P>
              </div>
              <div className="flex w-full justify-center items-center gap-3">
                <Button
                  type="button"
                  className="border border-gray-5 bg-white text-gray-10 hover:bg-white"
                  onClick={() => setSignNoteDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="bg-blue-500 border-none text-white hover:bg-blue-500"
                  onClick={confirmAndSignNote}
                >
                  Try Again
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
