import { useFragment } from '@apollo/client';
import { LatestCombinedSummaryFragmentDoc } from '@eluve/client-gql-operations';
import { useAppointmentCacheId } from '@eluve/smart-blocks';
import { AppointmentSummary, AppointmentSummarySchema } from '@eluve/utils';

/**
 * Returns the current summary for an appointment if it is available
 *
 */
export const useSummary = (): { summary: AppointmentSummary } => {
  const from = useAppointmentCacheId();

  const { data } = useFragment({
    fragment: LatestCombinedSummaryFragmentDoc,
    from,
  });

  const summary = data?.combinedSummaries?.[0]?.summary ?? null;

  const parsed = AppointmentSummarySchema.safeParse(summary);

  // Ensure that object keys are always in a predictable order since
  // the UI binds to the entries of this
  return parsed.success
    ? {
        summary: {
          SUBJECTIVE: parsed.data.SUBJECTIVE,
          OBJECTIVE: parsed.data.OBJECTIVE,
          ASSESSMENT: parsed.data.ASSESSMENT,
          PLAN: parsed.data.PLAN,
          CLIENT_RECAP: parsed.data.CLIENT_RECAP,
        },
      }
    : {
        summary: {
          SUBJECTIVE: null,
          OBJECTIVE: null,
          ASSESSMENT: null,
          CLIENT_RECAP: null,
        },
      };
};
