import { useCacheIdBuilder } from '@eluve/apollo-client';
import { FCC } from '@eluve/components';
import { createContext, useContext, useMemo } from 'react';

type AppointmentContextState = {
  tenantId: string;
  appointmentId: string;
  tenantCacheId: string;
  appointmentCacheId: string;
};

const AppointmentContext = createContext<AppointmentContextState | null>(null);

export const useAppointmentContext = () => {
  const context = useContext(AppointmentContext);
  if (!context) {
    throw new Error(
      'useAppointmentContext must be used within a AppointmentContextProvider',
    );
  }

  return context;
};

/**
 * Provides the Apollo cache id of the current appointment
 * for more convenient use in interacting with Fragments across the Component
 * tree
 */
export const useAppointmentCacheId = () => {
  const { appointmentCacheId } = useAppointmentContext();
  return appointmentCacheId;
};

/**
 * Provides the id of the current appointment
 */
export const useAppointmentId = () => {
  const { appointmentId } = useAppointmentContext();
  return appointmentId;
};

export const AppointmentContextProvider: FCC<{
  tenantId: string;
  appointmentId: string;
}> = ({ tenantId, appointmentId, children }) => {
  const appointmentCacheId = useCacheIdBuilder()({
    typeName: 'Appointments',
    key: appointmentId,
  });

  const tenantCacheId = useCacheIdBuilder()({
    typeName: 'Tenants',
    key: tenantId,
  });

  const value = useMemo(() => {
    return {
      tenantId,
      appointmentId,
      tenantCacheId,
      appointmentCacheId,
    };
  }, [appointmentCacheId, appointmentId, tenantCacheId, tenantId]);

  return (
    <AppointmentContext.Provider value={value}>
      {children}
    </AppointmentContext.Provider>
  );
};
