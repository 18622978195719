/* eslint-disable react-hooks/rules-of-hooks */
import { urlSafeDate } from '@eluve/date-utils';
import { useSession } from '@eluve/session-helpers';
import { Navigate } from 'react-router-dom';

import React from 'react';

export interface AppIndexPageProps {}

export const AppIndexPage: React.FC<AppIndexPageProps> = () => {
  const session = useSession();
  const {
    isEluveAdmin,
    allowedTenantIds,
    'https://hasura.io/jwt/claims': claims,
  } = session!;
  const tenantId = claims['x-hasura-tenant-id'];

  // If the session is already scoped to a specific tenant redirect to their home page
  if (tenantId) {
    return <Navigate to={`tenants/${tenantId}/home?date=${urlSafeDate()}`} />;
  }

  if (!isEluveAdmin) {
    // If its a regular user and they are a member of only a single tenant redirect to their home page
    if (allowedTenantIds.length === 1) {
      return (
        <Navigate
          to={`tenants/${allowedTenantIds[0]}/home?date=${urlSafeDate()}`}
        />
      );
    }

    // If they have access to multiple tenants redirect to the tenant selection page
    return <Navigate to="/tenant/switch" />;
  }

  return <div>Placeholder</div>;
};
