import React, { useEffect, useState } from 'react';
import { useIsNewSession, useAppointmentStore } from '@eluve/smart-blocks';
import { P } from '@eluve/components';

export const HelperText: React.FC = () => {
  const isNewSession = useIsNewSession();
  const isInSession = useAppointmentStore((state) => state.isInSession);

  const [fadeHelperText, setFadeHelperText] = useState<boolean>(false);

  // TODO: This should just be replaced with an on mount animation through either CSS
  // or something like Framer Motion instead of using an effect
  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeHelperText(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  if (!isNewSession || isInSession) {
    return null;
  }

  return (
    <P
      className={`text-gray-10 text-lg text-center transition-opacity ease-in duration-700 opacity-100 ${
        fadeHelperText ? 'opacity-100' : 'opacity-0'
      }`}
    >
      Start your session and Eluve will generate your SOAP notes!
    </P>
  );
};
