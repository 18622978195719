import { useCallback, useState } from 'react';

/**
 * Utility hook that simplifies dealing with state related to dialog/modal lifecycles
 */
export const useDialog = (initialOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const openDialog = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const toggle = useCallback(() => {
    setIsOpen((old) => !old);
  }, [setIsOpen]);

  return {
    isOpen,
    closeDialog,
    openDialog,
    toggle,
    setIsOpen,
  };
};
