import { FCC, useToast } from '@eluve/components';
import { useRolesFromSession } from '@eluve/session-helpers';
import { Navigate } from 'react-router-dom';

export const TenantAdminGuard: FCC = ({ children }) => {
  const roles = useRolesFromSession();
  const isTenantAdmin = roles.includes('tenant-admin');

  const { toast } = useToast();

  if (!isTenantAdmin) {
    toast({
      title: 'Error',
      description: 'Access denied',
      variant: 'destructive',
    });
    return <Navigate to="/" replace />;
  }

  return children;
};
