import { FCC } from '@eluve/components';
import { CornerDownRight } from 'lucide-react';

const indent = (depth: number) => `${depth * 2}rem`;

export const DepthContainer: FCC<{ depth: number }> = ({ children, depth }) => {
  return (
    <div
      style={{ paddingLeft: indent(depth) }}
      className="flex gap-2 items-top w-full"
    >
      {depth > 0 && <CornerDownRight className="mt-4 h-6 w-6" />}
      {children}
    </div>
  );
};
