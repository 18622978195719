import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { initPostMessenger } from './utils/post-messenger';
import { useUserLocations } from '@eluve/smart-blocks';

export const AppLayout: React.FC = () => {
  const { locations } = useUserLocations();
  useEffect(() => {
    if (locations) {
      const ehrs = locations
        .filter(
          (location) =>
            location.externalEhr?.domain && location.externalEhr?.vendor,
        )
        .reduce(
          (acc, location) => {
            acc[location.externalEhr!.id] = {
              vendor: location.externalEhr!.vendor!,
              domain: location.externalEhr!.domain!,
            };
            return acc;
          },
          {} as { [key: string]: { vendor: string; domain: string } },
        );
      initPostMessenger(Object.values(ehrs));
    }
  }, [locations]);

  return <Outlet />;
};
