import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import SuperTokens from 'supertokens-auth-react';
import ThirdPartyEmailPassword from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import { Toaster } from '@eluve/components';
import { ApiClientContextProvider } from '@eluve/api-client-provider';
import * as Sentry from '@sentry/react';
import { reactRouter } from './router';
import { appConfig } from './config';

SuperTokens.init({
  appInfo: {
    appName: 'eluve',
    websiteDomain: appConfig.VITE_SUPERTOKENS_WEBSITE_DOMAIN,
    websiteBasePath: '/login',
    apiDomain: appConfig.VITE_API_DOMAIN,
    apiBasePath: '/api/auth',
  },
  recipeList: [
    Session.init(),
    ThirdPartyEmailPassword.init({
      useShadowDom: false,
      signInAndUpFeature: {
        providers: [ThirdPartyEmailPassword.Google.init()],
      },
    }),
  ],
});

Sentry.init({
  dsn: 'https://d1cb9656d1b6d1c6bfd495a2ffeb43f7@o4506339234480128.ingest.sentry.io/4506384339763200',
  // See https://docs.sentry.io/platforms/javascript/configuration/integrations/ for available integrations.
  integrations: [],
  // See https://docs.sentry.io/platforms/javascript/performance/ for Performance Monitoring options

  // https://docs.sentry.io/platforms/javascript/configuration/options/#attach-stacktrace
  attachStacktrace: true,
  environment: appConfig.VITE_ENV,
  beforeSend: (event) => {
    const url = window.location.pathname;
    const regex = /\/tenant\/([a-fA-F0-9-]+)\//;
    const match = url.match(regex);
    const tenantId = match ? match[1] : null;
    event.tags = {
      ...event.tags,
      tenantId,
    };
    return event;
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <ApiClientContextProvider baseUrl={appConfig.VITE_API_DOMAIN}>
      <RouterProvider router={reactRouter} />
    </ApiClientContextProvider>
    <Toaster />
  </StrictMode>,
);
