'use client';
import { useSuspenseQuery } from '@apollo/client';
import { GetNewAppointmentDetailsDocument } from '@eluve/client-gql-operations';
import React from 'react';
import { ActiveAppointmentLayout } from './ActiveAppointmentLayout';
import { SignedAppointmentLayout } from './SignedAppointmentLayout';
import { Navigate, useParams } from 'react-router-dom';

export const AppointmentLayout: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const {
    data: { appointmentsByPk: appointment },
  } = useSuspenseQuery(GetNewAppointmentDetailsDocument, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  if (appointment === null) {
    return <Navigate to="/404" />;
  }

  const interaction = appointment.doctorInteraction;
  const isNoteSigned = Boolean(interaction?.noteSignedAt);

  if (!isNoteSigned) {
    return (
      <ActiveAppointmentLayout
        key={appointment.id}
        appointmentId={appointmentId}
      />
    );
  }

  return <SignedAppointmentLayout appointmentId={appointmentId} />;
};
