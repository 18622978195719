'use client';
import {
  Card,
  CardContent,
  CardHeader,
  P,
  Skeleton,
  cn,
} from '@eluve/components';
import {
  EluveEditor,
  EluveEditorHandle,
  extensions,
} from '@eluve/eluve-editor';
import { AppointmentSummaryKeys } from '@eluve/utils';
import { Editor as EditorClass } from '@tiptap/core';
import { Extension } from '@tiptap/react';
import capitalize from 'lodash/capitalize';
import { Loader2 } from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import './SummarySectionMarkdown.css';

export interface SummarySectionMarkdownProps {
  className?: string;
  summaryKey: AppointmentSummaryKeys;
  sectionTitle: string;
  content?: string;
  loading?: boolean;
  handleContentChange: (
    key: AppointmentSummaryKeys,
    newContent: string,
  ) => void;
  disabled: boolean;
  billingCodesEnabled: boolean;
}

export const SummarySectionMarkdown: React.FC<SummarySectionMarkdownProps> = ({
  className,
  summaryKey,
  sectionTitle: title,
  content,
  loading,
  handleContentChange,
  disabled,
  billingCodesEnabled = false,
}) => {
  const editorRef = useRef<EluveEditorHandle | null>(null);

  // TODO(jesse): This should be debounced so we don't spam
  // updates across the tree with every keystroke
  const onEditorContentUpdated = (
    editor?: EditorClass | undefined,
  ): void | Promise<void> => {
    if (editor) {
      const content = editor.storage.markdown.getMarkdown();
      handleContentChange(summaryKey, content);
    }
  };

  useEffect(() => {
    editorRef?.current?.setEditable(!loading && !disabled);
  }, [editorRef, disabled, loading]);

  const additionalExtensions: Extension[] = [];
  if (billingCodesEnabled) {
    additionalExtensions.push(
      extensions.BillingCodes.configure({
        html: true,
        HTMLAttributes: {
          class: 'billing-code bg-secondary',
        },
      }) as Extension,
    );
  }

  return (
    <div className={cn('viewer', className)}>
      <Card className="w-full rounded-xl border-gray-4">
        <CardHeader className="p-0 px-5 border-b rounded-t-xl bg-gray-1 h-10 flex flex-row justify-between items-center justify-items-center tracking-normal">
          <P>
            {title
              .split('_')
              .map((s) => capitalize(s))
              .join(' ')}
          </P>
          {loading && <Loader2 className="animate-spin h-5 text-brand-8" />}
        </CardHeader>
        <CardContent className="flex flex-col justify-between rounded-b-xl p-4 gap-5 bg-white">
          {loading && !content && (
            <>
              <Skeleton className="h-3" />
              <Skeleton className="h-3" />
            </>
          )}

          {((loading && content) || !loading) && (
            <EluveEditor
              ref={editorRef}
              content={content}
              onUpdate={onEditorContentUpdated}
              extensions={additionalExtensions}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};
