import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const supportedAppointmentVendorsSchema = z.enum(['JANE']);

export type SupportedAppointmentVendors = z.infer<
  typeof supportedAppointmentVendorsSchema
>;

export const appointmentsContract = c.router(
  {
    importAppointments: {
      method: 'POST',
      path: '/',
      responses: {
        201: z.array(
          z.object({
            id: z.string(),
          }),
        ),
      },
      body: z.object({
        data: z.record(z.unknown()),
        vendor: supportedAppointmentVendorsSchema,
        domain: z.string(),
      }),
      summary: 'Import appointments',
    },
  },
  {
    pathPrefix: '/appointments',
  },
);
