'use client';

import React, { useState } from 'react';
import { H3 } from '@eluve/components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { format, add } from 'date-fns';

export interface DateNavigatorProps {
  startingDate?: Date;
  onDateChanged?: (newDate: Date) => void;
}

export const DateNavigator: React.FC<DateNavigatorProps> = ({
  onDateChanged,
  startingDate,
}) => {
  const [date, setDate] = useState(startingDate ? startingDate : new Date());

  const changeDay = (days: number) => {
    const newDate = add(date, { days });
    setDate(newDate);
    onDateChanged?.(newDate);
  };

  return (
    <div className="flex items-center w-full mr-2 justify-between">
      <H3>{format(date, 'eeee, MMMM	do')}</H3>
      <div className="flex flex-row">
        <div>
          <ChevronLeft
            className="h-6 w-6 p-1 text-muted-foreground cursor-pointer hover:bg-gray-3 rounded-full"
            onClick={() => changeDay(-1)}
          />
        </div>
        <div>
          <ChevronRight
            className="h-6 w-6 p-1 text-muted-foreground cursor-pointer hover:bg-gray-3 rounded-full"
            onClick={() => changeDay(1)}
          />
        </div>
      </div>
    </div>
  );
};
