'use client';
import { Button, Card, Input, P } from '@eluve/components';
import {
  useAppointmentStore,
  useAppointmentId,
  useAppointmentActivity,
} from '@eluve/smart-blocks';
import React, { useState } from 'react';
import './ActiveAppointmentLayout.css';
import { useExternalPatientId } from './useExternalPatientId';
import { useSetAppointmentStartedAt } from './useSetAppointmentStartedAt';

export interface EluveAdminPanelProps {}

export const EluveAdminPanel: React.FC<EluveAdminPanelProps> = () => {
  const { setAppointmentStartedAt } = useSetAppointmentStartedAt();
  const {
    syncToEHR,
    regenerateSummary,
    uploadFileToGCS,
    handleSessionStop,
    handleSessionStart,
  } = useAppointmentStore(
    ({
      syncToEHR,
      regenerateSummary,
      uploadFileToGCS,
      handleSessionStart,
      handleSessionStop,
    }) => ({
      handleSessionStart,
      handleSessionStop,
      syncToEHR,
      regenerateSummary,
      uploadFileToGCS,
    }),
  );
  const appointmentId = useAppointmentId();

  const patientExternalId = useExternalPatientId();
  const { isSummarizationInProgress, isTranscriptionInProgress } =
    useAppointmentActivity();

  let eluveExtExists = false;
  if (typeof document !== 'undefined') {
    eluveExtExists = Boolean(document.getElementById('eluveExtExists'));
  }

  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadFileStatus, setUploadFileStatus] = useState<string>('');

  const uploadFile = async (): Promise<void> => {
    setIsUploadingFile(true);
    setUploadFileStatus('Uploading file...');
    handleSessionStart();
    if (selectedFile) {
      await uploadFileToGCS(selectedFile, 'audio/m4a', 'm4a', 0);
    }
    setIsUploadingFile(false);
    setUploadFileStatus('File Successfully Uploaded!');
    await setAppointmentStartedAt({
      variables: {
        id: appointmentId,
      },
    });
    await handleSessionStop();
  };

  const handleFileInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedFile(e.currentTarget.files?.[0] ?? null);
  };

  return (
    <Card className="w-full rounded-2xl border-gray-4 bg-white relative overflow-hidden h-fit p-8">
      <div className="flex flex-col md:flex-row md:items-center gap-4">
        <form encType="multipart/form-data">
          <Input
            type="file"
            disabled={isUploadingFile}
            name="file"
            onChange={handleFileInputChange}
          />
        </form>

        <Button
          type="button"
          disabled={isUploadingFile || !selectedFile}
          onClick={uploadFile}
        >
          Upload
        </Button>

        <div className="text-small text-gray-10">{uploadFileStatus}</div>

        <Button
          type="button"
          disabled={
            isUploadingFile ||
            isSummarizationInProgress ||
            isTranscriptionInProgress
          }
          onClick={() => regenerateSummary()}
        >
          Regenerate Summary
        </Button>
        {eluveExtExists && patientExternalId ? (
          <Button onClick={() => syncToEHR({ sign: false })}>
            Sync to EHR
          </Button>
        ) : (
          <div>
            {!eluveExtExists && <P>Extension not detected</P>}
            {!patientExternalId && <P>External EHR patient not selected</P>}
          </div>
        )}
      </div>
    </Card>
  );
};
