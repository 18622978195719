import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, DataTable, P, PageTitle } from '@eluve/components';
import { ArrowUpDown } from 'lucide-react';
import { GetTenantUsersDocument } from '@eluve/client-gql-operations';
import { ColumnDef } from '@tanstack/react-table';
import { RoleSelector } from './RoleSelector';

export type TenantUserRow = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  role: string;
  lastAccessedAt: string;
  tenantId: string;
};

const columns: ColumnDef<TenantUserRow>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          ID <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{row.original.id}</P>;
    },
  },
  {
    accessorKey: 'firstName',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          First <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{row.original.firstName}</P>;
    },
  },
  {
    accessorKey: 'lastName',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Last
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{row.original.lastName}</P>;
    },
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Email <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{row.original.email}</P>;
    },
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Created At <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{new Date(row.original.createdAt).toLocaleString()}</P>;
    },
  },
  {
    accessorKey: 'lastAccessedAt',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Last Signed In <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <P>
          {Date.parse(row.original.lastAccessedAt)
            ? new Date(row.original.lastAccessedAt).toLocaleString()
            : '- -'}
        </P>
      );
    },
  },
  {
    accessorKey: 'role',
    header: ({ column }) => {
      return (
        <Button
          className="w-[200px]"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Tenant-Admin? <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <RoleSelector
          tenantId={row.original.tenantId}
          userId={row.original.id}
        />
      );
    },
  },
];

export const AdminTenantUsers: React.FC = () => {
  const { tenantId } = useParams();
  const { data } = useQuery(GetTenantUsersDocument, {
    variables: {
      tenantId: tenantId!,
    },
    skip: !tenantId,
  });

  const rows = (data?.tenantUsers ?? []).map<TenantUserRow>((t) => {
    return {
      id: t.userId,
      firstName: t.user?.firstName ?? '',
      lastName: t.user?.lastName ?? '',
      email: t.user?.email ?? '',
      createdAt: t.createdAt,
      role: t.role ?? '',
      lastAccessedAt: t.lastAccessedAt,
      tenantId: t.tenantId,
    };
  });

  return (
    <div>
      <PageTitle>Users</PageTitle>
      <DataTable data={rows} columns={columns} />
    </div>
  );
};
