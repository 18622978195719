'use client';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  H3,
  P,
} from '@eluve/components';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { useMutation, useSubscription, useSuspenseQuery } from '@apollo/client';
import { SessionSchema } from '@eluve/api-contract';
import { EluveLogo } from '@eluve/blocks';
import {
  GetCoreTenantDataDocument,
  InsertAnonymousAppointmentDocument,
  ListenCoreTenantDataDocument,
} from '@eluve/client-gql-operations';
import { useToast } from '@eluve/components';
import { MessageResponse } from '@eluve/post-messenger';
import { useSession, useUserIdFromSession } from '@eluve/session-helpers';
import {
  useConfigStore,
  useTenantName,
  useUserLocations,
} from '@eluve/smart-blocks';
import { format } from 'date-fns';
import { Loader2, ShieldCheck } from 'lucide-react';
import { redirectToAuth } from 'supertokens-auth-react';
import Session from 'supertokens-auth-react/recipe/session';
import ThirdPartyEmailPassword from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { postMessenger } from '../../utils/post-messenger';

// TODO(jesse): This is just a placeholder. Introduce a simple global state for making
// sure components have access to the session data they need.
const useSessionData = () => {
  const [email, setEmail] = useState('');
  const [userInitials, setUserInitials] = useState('EL');

  useEffect(() => {
    const get = async () => {
      const rawSession = await Session.getAccessTokenPayloadSecurely();
      const session = SessionSchema.parse(rawSession);
      setEmail(session.email);
      setUserInitials(
        `${session.firstName?.[0] ?? ''}${session.lastName?.[0] ?? ''}`,
      );
    };

    get();
  }, []);

  return {
    email,
    userInitials,
  };
};

export const TenantLayout: React.FC = () => {
  const { tenantId = '' } = useParams() as { tenantId: string };
  const session = useSession();
  const { isEluveAdmin } = session;
  const userId = useUserIdFromSession();
  const { toast } = useToast();
  const [dataImporting, setDataImporting] = useState<boolean>(false);
  const { config } = useConfigStore(tenantId, userId);
  const { locations } = useUserLocations();

  useSuspenseQuery(GetCoreTenantDataDocument, {
    variables: {
      tenantId: tenantId,
    },
    skip: !tenantId,
  });

  useSubscription(ListenCoreTenantDataDocument, {
    variables: {
      tenantId: tenantId,
    },
    skip: !tenantId,
  });

  const tenantName = useTenantName(tenantId);

  const { email, userInitials } = useSessionData();

  const logoutClicked = async () => {
    await ThirdPartyEmailPassword.signOut();
    redirectToAuth();
  };

  const [insertAnonymousAppointment] = useMutation(
    InsertAnonymousAppointmentDocument,
  );

  const navigate = useNavigate();

  const startAnonymousSession = async () => {
    const startDate = new Date();

    const { data, errors } = await insertAnonymousAppointment({
      variables: {
        startDate: startDate.toISOString(),
        name: `Session started at ${format(startDate, 'h:mm a')}`,
        locationId: config.activeLocationId,
      },
    });

    const appointmentId = data?.insertAppointmentsOne?.id;
    if (appointmentId) {
      navigate(`/tenant/${tenantId}/appointments/${appointmentId}`);
      return;
    }
    console.log('Error creating new appointment', errors);
    return;
  };

  const importDataFromEhr = async () => {
    if (!document.getElementById('eluveExtExists')) {
      toast({
        duration: 2000,
        title: 'Eluve Chrome Extension not installed',
        description:
          'Please install the Eluve Chrome Extension and refresh this page',
        variant: 'destructive',
      });
      return;
    }
    if (!locations?.length) {
      toast({
        duration: 2000,
        title: 'No locations found',
        description:
          'Ask your admin to add a location for you to import data from',
        variant: 'destructive',
      });
      return;
    }
    const externalEhrMapping = (locations || [])
      .filter((location) => location.externalEhr)
      .reduce(
        (acc, location) => {
          acc[location.externalEhr!.id] = location.externalEhr;
          return acc;
        },
        {} as { [key: string]: any },
      );
    const externalEhrs = Object.values(externalEhrMapping);

    if (!externalEhrs.length) {
      toast({
        duration: 2000,
        title: 'No EHR associations',
        description:
          'Locations are not associated with an external EHR. Please ask your admin to associate a location with an EHR.',
        variant: 'destructive',
      });
      return;
    }

    setDataImporting(true);
    try {
      const importResults = await Promise.all(
        externalEhrs.map(async (externalEhr) => {
          const importResponse = await postMessenger.sendRequest<
            MessageResponse<{ id: string }[]>
          >('FETCH_EHR_DATA', externalEhr, { timeout: 10000 });
          return {
            ...importResponse,
            externalEhr,
          };
        }),
      );

      if (importResults.every((result) => result.ok)) {
        toast({
          duration: 2000,
          variant: 'success',
          title: 'Success!',
          description: `Imported from ${importResults.map((result) =>
            [result.externalEhr?.domain].join(', '),
          )}`,
        });
        return;
      }
      toast({
        duration: 2000,
        title: 'Could not import data :(',
        description: importResults.find((result) => result.error)?.error,
        variant: 'destructive',
      });
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Refresh the page and try again.';
      toast({
        duration: 2000,
        title: 'Could not import data :(',
        description: errorMessage,
        variant: 'destructive',
      });
    } finally {
      setDataImporting(false);
    }
  };

  return (
    <div className="h-full bg-gray-3">
      <div className="flex flex-col h-content w-full items-center bg-gray-3">
        <div className="sticky w-full top-0 z-40 flex h-16 shrink-0 justify-center gap-x-4 border-b border-gray-6 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 bg-white">
          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 container items-center">
            <a href="/" className="flex h-16 shrink-0 items-center">
              <EluveLogo />
            </a>

            {tenantName && <H3 className="font-normal">{tenantName}</H3>}

            <div className="flex-1"></div>
            <div className="flex flex-row items-center gap-x-4 lg:gap-x-6">
              {/* Temp fix: tenantId isn't available from settings page. So instead of breaking, it is better to not show this button*/}
              {tenantId && (
                <div className="flex flex-row items-center gap-x-4 lg:gap-x-6">
                  <Button
                    className="flex items-center gap-x-2 w-36"
                    variant="secondary"
                    onClick={importDataFromEhr}
                  >
                    {!dataImporting ? (
                      <div className="flex flex-row items-center">
                        <span className="sr-only">Import EHR Data</span>
                        Import EHR Data
                      </div>
                    ) : (
                      <div className="flex justify-center items-center">
                        <Loader2 className="animate-spin h-6 w-6 text-blue-500" />
                      </div>
                    )}
                  </Button>

                  <div
                    className="block h-6 w-px bg-gray-5"
                    aria-hidden="true"
                  />

                  <Button variant="secondary" onClick={startAnonymousSession}>
                    <span className="sr-only">Start a recording session</span>
                    New Session
                  </Button>
                  <div
                    className="block h-6 w-px bg-gray-5"
                    aria-hidden="true"
                  />
                </div>
              )}

              {/* Profile dropdown */}
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div className="flex h-9 px-2 rounded-sm bg-brand-12 justify-center items-center font-medium text-white">
                    {userInitials}
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="mr-2 mt-4 border-0 p-4"
                  data-side="bottom"
                >
                  <DropdownMenuItem className="flex flex-col items-start bg-gray-2 pointer-events-none border-none mb-4">
                    <P className="font-light">Signed in as</P>
                    <P className="font-normal">{email}</P>
                  </DropdownMenuItem>
                  <Link to="/settings">
                    <DropdownMenuItem className="profile-dropdown-item cursor-pointer focus:bg-brand-2 focus:text-brand-9">
                      Settings
                    </DropdownMenuItem>
                  </Link>
                  <DropdownMenuItem className="profile-dropdown-item cursor-pointer focus:bg-brand-2 focus:text-brand-9">
                    Contact Us
                  </DropdownMenuItem>
                  {isEluveAdmin && (
                    <>
                      <DropdownMenuSeparator />
                      <Link to="/admin">
                        <DropdownMenuItem className=" bg-yellow-500 hover:bg-yellow-600 cursor-pointer">
                          <ShieldCheck className="mr-2 h-4 w-4" />
                          <span>Admin</span>
                        </DropdownMenuItem>
                      </Link>
                    </>
                  )}
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    className="cursor-pointer text-red-500 focus:bg-red-100 focus:text-red-500"
                    onClick={logoutClicked}
                  >
                    Log Out
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>

        <main className="h-full w-full justify-center">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
