import { sessionBasedClientFactory, typePolicies } from '@eluve/apollo-client';
import {
  createBrowserRouter,
  Navigate,
  NavLink,
  Outlet,
} from 'react-router-dom';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { SsoPage } from './routes/SsoPage';
import { LoginPage } from './routes/LoginPage';
import { ApolloProvider, InMemoryCache } from '@apollo/client';
import { AppIndexPage } from './routes/app/AppIndexPage';
import { TenantLayout } from './routes/app/tenant/$tenantId/TenantLayout';
import { AppLayout } from './routes/app/AppLayout';
import { HomePage } from './routes/app/tenant/$tenantId/HomePage';
import { AdminLayout } from './routes/app/admin/AdminLayout';
import { WaitListPage } from './routes/WaitlistPage';
import { AdminTenants } from './routes/app/admin/tenant/AdminTenants';
import { AdminTenant } from './routes/app/admin/tenant/$tenantId/AdminTenant';
import { AdminAppointments } from './routes/app/admin/tenant/$tenantId/appointments/AdminAppointments';
import { AdminAppointment } from './routes/app/admin/tenant/$tenantId/appointments/$appointmentId/AdminAppointment';
import { AdminAuditTrail } from './routes/app/admin/AdminAuditTrail';
import { AdminTenantUsers } from './routes/app/admin/tenant/$tenantId/users/AdminTenantUsers';
import { NotFoundPage } from './routes/NotFoundPage';
import { SettingsPage } from './routes/app/settings/SettingsPage';
import { appConfig } from './config';
import { AppointmentPage } from './routes/app/tenant/$tenantId/appointments/Appointment.Page';
import { AdminTenantLayout } from './routes/app/admin/tenant/$tenantId/AdminTenantLayout';
import { TenantGuard } from './routes/app/tenant/$tenantId/TenantGuard';
import { SingularEntityRedirect } from './routes/app/SingularEntityRedirect';
import { TenantAdminGuard } from './routes/app/tenant/$tenantId/TenantAdminGuard';
import { LayersPage } from './routes/app/tenant/$tenantId/admin/LayersPage';
import { FeatureFlagged } from '@eluve/smart-blocks';
import { LocationsPage } from './routes/app/tenant/$tenantId/admin/LocationsPage';
import { LocationPage } from './routes/app/tenant/$tenantId/admin/LocationPage';
import clsx from 'clsx';
import { Suspense } from 'react';

const adminApolloClient = sessionBasedClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  desiredRole: 'eluve-admin',
  cacheInstance: new InMemoryCache({
    typePolicies,
  }),
});

const apolloClient = sessionBasedClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  cacheInstance: new InMemoryCache({
    typePolicies,
  }),
});

export const reactRouter = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/sso',
    element: <SsoPage />,
  },
  {
    path: '/waitlist',
    element: <WaitListPage />,
  },
  {
    path: '/',
    element: (
      <SessionAuth>
        <ApolloProvider client={apolloClient}>
          <AppLayout />
        </ApolloProvider>
      </SessionAuth>
    ),
    children: [
      {
        index: true,
        element: <AppIndexPage />,
      },
      {
        path: 'tenant/*',
        element: <SingularEntityRedirect entityName="tenant" />,
      },
      {
        path: 'tenants',
        element: <Outlet />,
        children: [
          {
            path: ':tenantId',
            element: (
              <TenantGuard>
                <Suspense fallback={'Loading...'}>
                  <TenantLayout />
                </Suspense>
              </TenantGuard>
            ),
            children: [
              {
                index: true,
                element: <Navigate to="home" replace />,
              },
              {
                path: 'home',
                element: <HomePage />,
              },
              {
                path: 'admin',
                element: (
                  <TenantAdminGuard>
                    <Outlet />
                  </TenantAdminGuard>
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="layers" replace />,
                  },
                  {
                    element: (
                      <div className="grid">
                        <div className="container mt-2 flex items-center gap-4">
                          <NavLink
                            className={({ isActive }) =>
                              clsx('underline', { 'font-bold': isActive })
                            }
                            to="layers"
                          >
                            Layers
                          </NavLink>
                          <NavLink
                            className={({ isActive }) =>
                              clsx('underline', { 'font-bold': isActive })
                            }
                            to="locations"
                          >
                            Locations
                          </NavLink>
                        </div>
                        <Outlet />
                      </div>
                    ),
                    children: [
                      {
                        path: 'layers',
                        element: (
                          <FeatureFlagged flag="LOCATIONS">
                            <LayersPage />
                          </FeatureFlagged>
                        ),
                      },
                      {
                        path: 'locations',
                        element: (
                          <FeatureFlagged flag="LOCATIONS">
                            <LocationsPage />
                          </FeatureFlagged>
                        ),
                      },
                    ],
                  },
                  {
                    path: 'locations/:locationId',
                    element: (
                      <FeatureFlagged flag="LOCATIONS">
                        <LocationPage />
                      </FeatureFlagged>
                    ),
                  },
                ],
              },
              {
                path: 'appointment/*',
                element: <SingularEntityRedirect entityName="appointment" />,
              },
              {
                path: 'appointments/:appointmentId',
                element: <AppointmentPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'settings',
        element: <TenantLayout />,
        children: [
          {
            path: '',
            element: <SettingsPage />,
          },
        ],
      },
      {
        path: 'admin',
        element: <AdminLayout />,
        children: [
          {
            index: true,
            element: (
              <ApolloProvider client={adminApolloClient}>
                <AdminTenants />
              </ApolloProvider>
            ),
          },
          {
            path: 'audit-trail',
            element: (
              <ApolloProvider client={adminApolloClient}>
                <AdminAuditTrail />
              </ApolloProvider>
            ),
          },
          {
            path: 'tenant/*',
            element: <SingularEntityRedirect entityName="tenant" />,
          },
          {
            path: 'tenants/:tenantId',
            element: (
              <TenantGuard>
                <AdminTenantLayout />
              </TenantGuard>
            ),
            children: [
              {
                index: true,
                element: <AdminTenant />,
              },
              {
                path: 'appointment/*',
                element: <SingularEntityRedirect entityName="appointment" />,
              },
              {
                path: 'appointments',
                element: <AdminAppointments />,
              },
              {
                path: 'appointments/:appointmentId',
                element: <AdminAppointment />,
              },
              {
                path: 'users',
                element: <AdminTenantUsers />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);
