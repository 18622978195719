import { useFragment } from '@apollo/client';
import { useCacheIdBuilder } from '@eluve/apollo-client';
import {
  TenantLayersFragmentDoc,
  TenantLocationsFragmentDoc,
} from '@eluve/client-gql-operations';
import maxBy from 'lodash/maxBy';

export const useAppointmentSelectableLocations = (tenantId: string) => {
  const from = useCacheIdBuilder()({
    typeName: 'Tenants',
    key: tenantId,
  });

  const { data: layerData, complete: layerDataComplete } = useFragment({
    fragment: TenantLayersFragmentDoc,
    fragmentName: 'TenantLayers',
    from,
  });

  const { data: locationData, complete: locationDataComplete } = useFragment({
    fragment: TenantLocationsFragmentDoc,
    fragmentName: 'TenantLocations',
    from,
  });

  if (!layerDataComplete || !locationDataComplete) {
    return [];
  }

  const deepestLayer =
    maxBy(layerData?.layers ?? [], (l) => l.depth)?.depth ?? 0;

  const locations = locationData.locations;
  const selectableLocations = locations.filter((l) => l.depth === deepestLayer);

  const options = selectableLocations.map((sl) => {
    // Check to see if there's a parent location to help build a breadcrumb label
    const parentLocation = locations.find(
      (parent) =>
        parent.depth === sl.depth - 1 &&
        parent.depth >= 1 &&
        sl.path.startsWith(parent.path),
    );

    const locationLabel = parentLocation
      ? `${parentLocation.name} > ${sl.name}`
      : sl.name;

    return {
      id: sl.id,
      label: locationLabel,
    };
  });

  return options;
};
