'use client';
import { ApolloError, useFragment, useMutation } from '@apollo/client';
import {
  AutoComplete,
  AutoCompleteOption,
  SessionStatusBanner,
} from '@eluve/blocks';
import {
  AppointmentStatusFragmentDoc,
  CreateNewPatientDocument,
  MaxChunkNumFragmentDoc,
  PatientsFragmentDoc,
  UpdateAppointmentPatientDocument,
} from '@eluve/client-gql-operations';
import {
  Card,
  CardContent,
  CardHeader,
  H4,
  P,
  useToast,
} from '@eluve/components';
import { SimpleMediaRecorder } from '@eluve/media-components';
import { getPatientName } from '@eluve/utils';
import { format } from 'date-fns';
import { Pencil } from 'lucide-react';
import React, { useState } from 'react';
import {
  useAppointmentCacheId,
  useAppointmentContext,
} from './appointment.context';
import { useAppointmentStore } from './appointment.store';
import { useAppointmentPatient } from './useAppointmentPatient';
import { useIsNewSession } from './useIsNewSession';
import { usePatients } from './usePatients';
import { useUploadFile } from './useUploadFile';
import { AppointmentLocationSelector } from './AppointmentLocationSelector';

export interface SessionRecordingWithPatientDetailsCardProps {
  appointmentId: string;
  currentAppointmentPatientId?: string;
  patientFirstName?: string;
  patientLastName?: string | null;
  startTime: Date;
  location?: {
    name?: string;
    id?: string;
  };
}

export const SessionRecordingWithPatientDetailsCard: React.FC<
  SessionRecordingWithPatientDetailsCardProps
> = ({
  currentAppointmentPatientId,
  patientFirstName,
  patientLastName,
  startTime,
  location,
}) => {
  const from = useAppointmentCacheId();
  const { tenantCacheId, appointmentId } = useAppointmentContext();
  const isNewSession = useIsNewSession();

  const { uploadFileToGCS } = useUploadFile();

  // Subscribe to the portions of backend state that this component and its children
  // should be concerned with
  const { data: appointmentStatus } = useFragment({
    fragment: AppointmentStatusFragmentDoc,
    from,
  });

  const { data: maxChunk } = useFragment({
    fragment: MaxChunkNumFragmentDoc,
    from,
  });

  const selectedPatient = useAppointmentPatient();
  const selectedPatientExternalEhrId =
    selectedPatient?.external_patients_info?.[0]?.externalPatientId;

  const latestChunkNumber =
    maxChunk.transcriptsAggregate?.aggregate?.max?.chunkNum ?? 0;

  // Extract from the appointment store the parts that are needed for this component
  // and its children
  const {
    handleSessionPause,
    handleSessionStart,
    handleSessionStop,
    isInSession,
  } = useAppointmentStore(
    ({
      handleSessionStart,
      handleSessionPause,
      handleSessionStop,
      isInSession,
    }) => ({
      handleSessionStart,
      handleSessionPause,
      handleSessionStop,
      isInSession,
    }),
  );

  const [editPatientMode, setEditPatientMode] = useState<boolean>(
    !currentAppointmentPatientId,
  );

  const [updateAppointmentPatient] = useMutation(
    UpdateAppointmentPatientDocument,
  );

  const [createNewPatient] = useMutation(CreateNewPatientDocument, {
    update: (cache, { data }) => {
      cache.updateFragment(
        {
          fragment: PatientsFragmentDoc,
          id: tenantCacheId,
          fragmentName: 'patients',
        },
        (existing) => {
          if (!existing || !data?.insertPatientsOne) {
            return existing;
          }

          return {
            ...existing,
            patients: [...existing.patients, data.insertPatientsOne],
          };
        },
      );
    },
  });

  const { toast } = useToast();

  const userPatients = usePatients();

  const performUpdateAppointmentPatient = async (value: AutoCompleteOption) => {
    setEditPatientMode(false);
    const patientId = value.value;

    if (currentAppointmentPatientId !== patientId) {
      await updateAppointmentPatient({
        variables: {
          id: appointmentId,
          patientId,
        },
        optimisticResponse: {
          updateAppointmentsByPk: {
            __typename: 'Appointments',
            id: appointmentId,
            patient: {
              __typename: 'Patients',
              id: patientId,
            },
          },
        },
      });
    }
  };

  const onCreatePatient = async (fullName: string): Promise<void> => {
    const nameParts = fullName.split(' ');
    const firstName = nameParts.shift() || null;
    const lastName = nameParts.join(' ') || null;

    if (!firstName) {
      toast({
        title: 'Cannot create patient without a first name',
        variant: 'destructive',
      });
      return;
    }

    try {
      const { data } = await createNewPatient({
        variables: {
          firstName,
          lastName,
        },
        optimisticResponse: {
          insertPatientsOne: {
            __typename: 'Patients',
            id: 'temp',
            firstName,
            lastName,
            external_patients_info: [],
          },
        },
      });

      const { firstName: first, lastName: last, id } = data!.insertPatientsOne!;

      await performUpdateAppointmentPatient({
        value: id,
        label: getPatientName(first, last),
      });
    } catch (e) {
      toast({
        title: `Error creating patient: ${(e as ApolloError)?.message}`,
        variant: 'destructive',
      });
    }
  };

  return (
    <div>
      <Card className="w-full rounded-xl border-gray-4 min-h-900">
        <CardHeader className="space-y-0 p-0 px-5 border-b rounded-t-xl bg-gray-1 h-10 flex flex-row justify-between items-center justify-items-center tracking-normal">
          <div>
            <P>Session Details</P>
          </div>
          {!isNewSession && appointmentStatus?.status !== 'COMPLETED' && (
            <div>
              <SessionStatusBanner isInSession={isInSession} />
            </div>
          )}
        </CardHeader>
        <CardContent className="flex flex-col md:flex-row justify-between rounded-b-xl px-5 py-7 gap-5 bg-white">
          <div className="flex flex-col h-full justify-between md:w-96">
            <div className="pr-14 grid gap-1">
              <P>PATIENT</P>
              {!editPatientMode && (
                <div>
                  <div className="flex flex-row items-center gap-2">
                    <H4>{getPatientName(patientFirstName, patientLastName)}</H4>
                    <Pencil
                      className="cursor-pointer h-4 w-4 text-brand-8"
                      onClick={() => setEditPatientMode(true)}
                    />{' '}
                  </div>

                  {selectedPatientExternalEhrId && (
                    <P>{`ID no: ${selectedPatientExternalEhrId}`}</P>
                  )}
                </div>
              )}
              {editPatientMode && (
                <div className="border rounded-lg">
                  <AutoComplete
                    placeholder="Search Patients"
                    disabled={false}
                    value={
                      !selectedPatient?.id
                        ? undefined
                        : {
                            value: selectedPatient.id,
                            label: getPatientName(
                              selectedPatient!.firstName,
                              selectedPatient!.lastName,
                            ),
                          }
                    }
                    onValueChange={performUpdateAppointmentPatient}
                    createNewPatient={onCreatePatient}
                    options={userPatients?.map((patient) => {
                      return {
                        value: patient.id,
                        label: [patient.firstName, patient.lastName]
                          .filter((v) => v)
                          .join(' '),
                        dob: patient.dob,
                        firstName: patient.firstName,
                        lastName: patient.lastName,
                        externalEhrId: patient.externalEhrId,
                        phoneNumber: patient.phoneNumber,
                      };
                    })}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <P>LOCATION</P>
            <AppointmentLocationSelector />
          </div>

          <div className="flex flex-col gap-1">
            <P>DATE</P>
            <P className="text-gray-12">{format(startTime, 'MM/dd/yyyy')}</P>
          </div>

          <div className="flex flex-col gap-1">
            <P>TIME</P>
            <P className="text-gray-12 justify-end">
              {format(startTime, 'h:mm a')}
            </P>
          </div>

          <div className="mr-4 md:w-80 flex md:justify-items-end md:items-end md:justify-end self-center">
            <SimpleMediaRecorder
              isInSession={isInSession}
              isNewSession={isNewSession}
              uploadFileToGCS={uploadFileToGCS}
              handleSessionStart={handleSessionStart}
              handleSessionStop={handleSessionStop}
              handleSessionPause={handleSessionPause}
              isActive={['ACTIVE', 'NOT_STARTED'].includes(
                appointmentStatus?.status ?? '',
              )}
              isCompleted={appointmentStatus?.status === 'COMPLETED'}
              latestChunkNumber={latestChunkNumber}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
