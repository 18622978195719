import { useQuery } from '@apollo/client';
import { GetFullAuditTrailDocument } from '@eluve/client-gql-operations';
import { Button, DataTable, H1, P, PageTitle } from '@eluve/components';
import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';

type TenantRow = {
  id: string;
  operation: string;
  createdAt: string;
  data: Record<string, unknown> | null;
  tableName: string | null;
  userId?: string;
  userName: string;
  userEmail: string | null;
};

const columns: ColumnDef<TenantRow>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          ID <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'operation',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Operation <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Created At <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Created At <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'tableName',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Table <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'userId',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          User ID <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'userName',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          User Name <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'userEmail',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          User Email <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'data',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Data <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return <P>{JSON.stringify(row.original.data)}</P>;
    },
  },
];

export const AdminAuditTrail: React.FC = () => {
  const { data: auditTrail } = useQuery(GetFullAuditTrailDocument);

  const rows = useMemo(
    () =>
      (auditTrail?.auditTrail ?? []).map<TenantRow>((t) => {
        return {
          id: t.id,
          operation: t.operation,
          createdAt: t.createdAt,
          data: t.data,
          tableName: t.tableName,
          userId: t.user?.id,
          userName: [t.user?.firstName, t.user?.lastName]
            .filter(Boolean)
            .join(' '),
          userEmail: t.user?.email ?? null,
        };
      }),
    [auditTrail],
  );

  return (
    <div>
      <PageTitle>Complete Audit Trail</PageTitle>
      <div>
        <DataTable data={rows} columns={columns} />
      </div>
    </div>
  );
};
