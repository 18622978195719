import { useApiClient } from '@eluve/api-client-provider';
import { FCC, useToast } from '@eluve/components';
import { useTenantIdFromSession } from '@eluve/session-helpers';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const TenantGuard: FCC = ({ children }) => {
  const apiClient = useApiClient();
  const { toast } = useToast();

  const { pathname } = useLocation();
  const { tenantId: tenantRouteId } = useParams() as { tenantId: string };

  const navigate = useNavigate();
  const tenantSessionId = useTenantIdFromSession();

  const isTenantSwitchNeeded = tenantRouteId !== tenantSessionId;

  useEffect(() => {
    const handleTenantSwitch = async () => {
      try {
        const { status } = await apiClient.auth.createTenantSession({
          body: {
            tenantId: tenantRouteId,
          },
        });

        // If we didn't successfully switch tenants, redirect to the root of the
        // app or admin page to let the normal flow determine where the user should get sent
        if (status !== 201) {
          const redirectTo = pathname.includes('/admin') ? '/admin' : '/';
          navigate(redirectTo);
          toast({
            title: 'Error',
            description: 'Access denied',
            variant: 'destructive',
          });
        } else if (status === 201) {
          console.log(
            `Successfully switched session to tenant ${tenantRouteId}`,
          );
        }
      } catch (e) {
        navigate('/');
      }
    };

    if (isTenantSwitchNeeded) {
      void handleTenantSwitch();
    }
  }, [
    apiClient.auth,
    isTenantSwitchNeeded,
    navigate,
    tenantRouteId,
    pathname,
    toast,
  ]);

  if (isTenantSwitchNeeded) {
    return null;
  }

  return children;
};
