import { useFragment } from '@apollo/client';
import { useCacheIdBuilder } from '@eluve/apollo-client';
import { AdminTenantFeatureFlagsFragmentDoc } from '@eluve/client-gql-operations';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  DataTable,
} from '@eluve/components';
import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { TenantFeatureFlagToggle } from './TenantFeatureFlagToggle';
import { FeatureFlagsEnum } from '@eluve/graphql-types';

type TableRow = {
  name: FeatureFlagsEnum;
  isEnabled: boolean;
  tenantId: string;
};

const colDefs: ColumnDef<TableRow>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'isEnabled',
    header: 'Is Enabled',
    cell: ({
      row: {
        original: { tenantId, name },
      },
    }) => {
      return <TenantFeatureFlagToggle tenantId={tenantId} flag={name} />;
    },
  },
];

export const TenantFeatureFlags: React.FC<{ tenantId: string }> = ({
  tenantId,
}) => {
  const from = useCacheIdBuilder()({
    typeName: 'Tenants',
    key: tenantId,
  });

  const result = useFragment({
    fragment: AdminTenantFeatureFlagsFragmentDoc,
    fragmentName: 'AdminTenantFeatureFlags',
    from,
  });

  const rows = (result.data?.featureFlags ?? []).map<TableRow>((flag) => ({
    isEnabled: flag?.isEnabled ?? false,
    name: flag!.flag!,
    tenantId: flag!.tenantId!,
  }));

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Feature Flags</CardTitle>
          <CardDescription>
            Control which features this Tenant has access to
          </CardDescription>
        </CardHeader>
        <CardContent></CardContent>
      </Card>
      <DataTable data={rows} columns={colDefs} />
    </>
  );
};
