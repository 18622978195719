import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const gcsContract = c.router(
  {
    generateSignedUrl: {
      method: 'POST',
      path: '/presigned',
      responses: {
        201: z.object({
          url: z.string(),
          gcsFilePath: z.string(),
        }),
      },
      body: z.object({
        userUploadId: z.string(),
        fileExt: z.string(),
      }),
      summary: 'Requests a presigned GCS Url for uploading a file',
    },
  },
  {
    pathPrefix: '/gcs',
  },
);
