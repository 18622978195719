import { useFragment } from '@apollo/client';
import { PatientsFragmentDoc } from '@eluve/client-gql-operations';
import { format } from 'date-fns';
import { useAppointmentContext } from './appointment.context';

type Patient = {
  firstName: string;
  id: string;
  externalEhrId?: string | null;
  lastName?: string | null;
  dob?: string | null;
  phoneNumber?: string | null;
};

type ExternalPatientInfoRawData = {
  mobile_phone?: string;
  home_phone?: string;
  work_phone?: string;
  [key: string]: unknown;
};

const getPhoneNumber = (
  rawData: ExternalPatientInfoRawData | null,
): string | undefined => {
  return rawData?.mobile_phone || rawData?.home_phone || rawData?.work_phone;
};

export const usePatients = () => {
  const from = useAppointmentContext().tenantCacheId;
  const { data, complete } = useFragment({
    fragment: PatientsFragmentDoc,
    from,
    fragmentName: 'patients',
  });

  if (!complete) {
    return [];
  }

  const patients = (data?.patients ?? []).map<Patient>((patient) => {
    const externalPatientInfo = patient.external_patients_info[0];
    const dateOfBirth = externalPatientInfo?.rawData?.dob;
    const dob = dateOfBirth ? format(new Date(dateOfBirth), 'MM/dd/yyyy') : '';
    const phoneNumber = getPhoneNumber(externalPatientInfo?.rawData);
    const externalEhrId = externalPatientInfo?.externalPatientId;

    return {
      firstName: patient.firstName,
      lastName: patient.lastName,
      id: patient.id,
      dob,
      externalEhrId,
      phoneNumber,
    };
  });

  return patients;
};
