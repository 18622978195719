import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

type Config = {
  activeLocationId: string | null;
};

type ConfigStore = {
  config: Config;
  setConfig: (updated: Config) => void;
};

type Persist = (
  config: StateCreator<ConfigStore>,
  options: PersistOptions<ConfigStore>,
) => StateCreator<ConfigStore>;

export const useConfigStore = (tenantId?: string, userId?: string) =>
  create<ConfigStore, []>(
    (persist as Persist)(
      (set): ConfigStore => ({
        config: { activeLocationId: null },
        setConfig: (updated: Config) =>
          set((state) => ({ config: { ...state.config, ...updated } })),
      }),
      {
        name: ['eluve', 'config', tenantId, userId].filter(Boolean).join('-'),
        storage: createJSONStorage(() => localStorage),
      },
    ),
  )();
