import { useFragment } from '@apollo/client';
import { AppointmentStatusFragmentDoc } from '@eluve/client-gql-operations';
import { useAppointmentCacheId } from '@eluve/smart-blocks';

export const useAppointmentStatus = () => {
  const cacheId = useAppointmentCacheId();

  const { data, complete } = useFragment({
    fragment: AppointmentStatusFragmentDoc,
    from: cacheId,
  });

  const appointmentStatus = data.status;

  if (!complete) {
    throw Error('Missing appointment status fragment');
  }

  return appointmentStatus;
};
