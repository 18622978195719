'use client';
import { useFragment, useQuery } from '@apollo/client';
import noop from 'lodash/noop';
import { PatientDetailsCard, SummarySectionMarkdown } from '@eluve/blocks';
import {
  GetLatestSummaryForAppointmentIdDocument,
  SignedAppointmentLayoutFragmentDoc,
} from '@eluve/client-gql-operations';
import { P } from '@eluve/components';
import {
  SummaryFeedbackModal,
  useAppointmentCacheId,
} from '@eluve/smart-blocks';
import React, { useEffect, useState } from 'react';
import './SignedAppointmentLayout.css';
import { AppointmentSummaryKeys } from '@eluve/utils';
import { BillingCodesSection } from './BillingCodesSection';
import { appConfig } from '../../../../../config';

const summaryKeyToSectionTitleMap: Record<string, string> = {
  SUBJECTIVE: 'Subjective',
  OBJECTIVE: 'Objective',
  ASSESSMENT: 'Assessment',
  PLAN: 'Plan',
};

export const SignedAppointmentLayout: React.FC<{ appointmentId: string }> = ({
  appointmentId,
}) => {
  const [summary, setSummary] = useState<Record<string, string> | null>(null);
  const from = useAppointmentCacheId();

  const {
    complete,
    data: appointment,
    missing,
  } = useFragment({
    fragment: SignedAppointmentLayoutFragmentDoc,
    from,
    fragmentName: 'SignedAppointmentLayout',
  });

  // TODOO(jesse): Handle this appropriately
  if (!complete) {
    throw Error(`Invariant violation: ${missing}`);
  }

  const interaction = appointment.doctorInteraction!;

  const { data: summariesData } = useQuery(
    GetLatestSummaryForAppointmentIdDocument,
    {
      variables: {
        appointmentId,
      },
    },
  );

  useEffect(() => {
    const incomingSummary = summariesData?.combinedSummaries?.[0]?.summary;

    if (incomingSummary) {
      // converting Record<string, any> to Record<string, string>
      const entries = Object.entries(incomingSummary).map(([key, value]) => [
        key,
        typeof value === 'string' ? value : String(value),
      ]);
      setSummary(Object.fromEntries(entries));
    } else {
      setSummary(null);
    }
  }, [summariesData]);

  let externalPatientId = '';
  const externalPatientsInfo = appointment?.patient?.external_patients_info;
  if (externalPatientsInfo?.length) {
    // Right now, assuming that there is only one external patient info per patient
    externalPatientId = externalPatientsInfo[0].externalPatientId;
  }

  return (
    <div className="signed-layout-view flex flex-col justify-center items-center">
      <div className="w-full max-w-[850px] flex flex-col gap-10 pt-10 mb-20 pb-24">
        <PatientDetailsCard
          appointmentStatus="signed"
          date={new Date(appointment.startDate)}
          patientId={appointment?.patient?.id}
          externalPatientId={externalPatientId}
          patientFirstName={appointment?.patient?.firstName}
          patientLastName={appointment?.patient?.lastName}
          userFirstName={interaction.user?.firstName}
          userLastName={interaction.user?.lastName}
          signedAt={
            interaction.noteSignedAt ? new Date(interaction.noteSignedAt) : null
          }
          externalChartUrl={interaction.externalChartUrl}
        />

        <div className="flex flex-col gap-2">
          {Object.entries(summaryKeyToSectionTitleMap)
            .filter(([summaryKey]) => summary?.[summaryKey])
            .map(([summaryKey, sectionTitle]) => {
              const summaryValue = summary?.[summaryKey];
              return (
                <SummarySectionMarkdown
                  className="summary-section-markdown-container"
                  key={summaryKey}
                  summaryKey={summaryKey as AppointmentSummaryKeys}
                  sectionTitle={sectionTitle}
                  content={summaryValue}
                  disabled={true}
                  handleContentChange={noop}
                  billingCodesEnabled={appConfig.VITE_BILLING_CODES_ENABLED}
                />
              );
            })}

          {interaction.additionalNotes && (
            <SummarySectionMarkdown
              className="summary-section-markdown-container"
              key="AdditionalNotes"
              // TODO: This should be presentational only and doens't need to reuse
              // the same component. The fact that we're passing in noop is a red flag
              summaryKey={`AdditionalNotes` as any}
              sectionTitle="Additional Notes"
              content={interaction.additionalNotes}
              disabled={true}
              handleContentChange={noop}
              billingCodesEnabled={appConfig.VITE_BILLING_CODES_ENABLED}
            />
          )}

          <BillingCodesSection />

          <div className="flex flex-row w-full justify-end items-center gap-2">
            <P className="text-gray-10 font-normal">
              Was this medical summary helpful?
            </P>
            <SummaryFeedbackModal appointmentId={appointmentId} />
          </div>
        </div>
      </div>
    </div>
  );
};
