import {
  ApolloCacheEntityId,
  ModifyApolloCacheArgs,
  NormalizedQueryRoot,
  ValidQueryRoots,
} from './types';
import isObject from 'lodash/isObject';
import pick from 'lodash/pick';
import {
  ApolloCache,
  NormalizedCacheObject,
  Reference,
  useApolloClient,
} from '@apollo/client';
import { Modifiers } from '@apollo/client/cache/core/types/common';
import { typePolicies } from './policies';

export const buildCacheEntityId = <T extends keyof NormalizedQueryRoot>(
  cache: ApolloCache<NormalizedCacheObject>,
  entityId: ApolloCacheEntityId<T>,
) => {
  const { key, typeName } = entityId;
  const keyParts = isObject(key) ? key : { id: key };

  const expectedKeyFields = (typePolicies[typeName]?.keyFields ?? [
    'id',
  ]) as unknown as string[];

  const hasRequiredKeyFields = expectedKeyFields.every(
    (key) => key in keyParts,
  );
  if (!hasRequiredKeyFields) {
    throw new Error(
      `Incorrect keyFields specified for '${typeName}'. Expected '${expectedKeyFields}' but got '${Object.keys(
        keyParts,
      )}'`,
    );
  }

  const validatedKeyParts = pick(keyParts, expectedKeyFields);

  const cacheId = cache.identify({
    __typename: typeName,
    ...validatedKeyParts,
  });

  if (!cacheId) {
    throw new Error(`Could not identify cacheId for '${typeName}'`);
  }

  return cacheId;
};

export const useCacheIdBuilder = () => {
  const client = useApolloClient();
  return <T extends keyof NormalizedQueryRoot>(
    entityId: ApolloCacheEntityId<T>,
  ) =>
    buildCacheEntityId(
      client.cache as ApolloCache<NormalizedCacheObject>,
      entityId,
    );
};

export const getRefFromEntityId = <T extends ValidQueryRoots>(
  cache: ApolloCache<NormalizedCacheObject>,
  entityId: ApolloCacheEntityId<T>,
): Reference => {
  const id = buildCacheEntityId(cache, entityId);
  return { __ref: id };
};

export function modifyApolloCache<T extends ValidQueryRoots>(
  cache: ApolloCache<NormalizedCacheObject>,
  args: ModifyApolloCacheArgs<T>,
) {
  const { modify, ...rest } = args;

  const cacheId = buildCacheEntityId(cache, rest);

  return cache.modify({
    id: cacheId,
    fields: modify as unknown as Modifiers,
  });
}

export function clearEntityFromApolloCache<T extends ValidQueryRoots>(
  cache: ApolloCache<NormalizedCacheObject>,
  entityKey: ApolloCacheEntityId<T>,
) {
  const cacheId = buildCacheEntityId(cache, entityKey);
  return cache.evict({ id: cacheId });
}
