import { useQuery } from '@apollo/client';
import { SummarySectionMarkdown, TranscriptionCard } from '@eluve/blocks';
import {
  GetAdminTenantAppointmentSummaryDocument,
  GetLatestSummaryForAppointmentIdDocument,
} from '@eluve/client-gql-operations';
import {
  Card,
  CardContent,
  CardHeader,
  DataTable,
  P,
  PageTitle,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@eluve/components';
import { useAppointmentName } from '@eluve/smart-blocks';
import { AppointmentSummaryKeys } from '@eluve/utils';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import noop from 'lodash/noop';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type TenantRow = {
  id: string;
  name: string;
  patientId: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  startDate: string;
  endDate: string;
  createdAt: string;
};

type SummaryFeedbackRow = {
  type: string | null;
  comment: string | null;
  tags: string[];
  createdAt: string;
  rating: number | null;
};

const formatTimestampToLocale = (timestamp?: string | null): string => {
  return timestamp ? dayjs(timestamp).format('MMMM D, YYYY h:mm A') : '';
};

const feedbackColumns: ColumnDef<SummaryFeedbackRow>[] = [
  {
    accessorKey: 'type',
    header: () => {
      return <div>Type</div>;
    },
    cell: ({ row }) => {
      return <div>{row.original.type}</div>;
    },
  },
  {
    accessorKey: 'rating',
    header: () => {
      return <div>Rating</div>;
    },
    cell: ({ row }) => {
      return <div>{row.original.rating}</div>;
    },
  },
  {
    accessorKey: 'comment',
    header: () => {
      return <div>Comment</div>;
    },
    cell: ({ row }) => {
      return <div>{row.original.comment}</div>;
    },
  },
  {
    accessorKey: 'tags',
    header: () => {
      return <div>Tags</div>;
    },
    cell: ({ row }) => {
      return <div>{row.original.tags.join(', ')}</div>;
    },
  },
  {
    accessorKey: 'createdAt',
    header: () => {
      return <div>Created At</div>;
    },
    cell: ({ row }) => {
      return <div>{row.original.createdAt}</div>;
    },
  },
];

const columns: ColumnDef<TenantRow>[] = [
  {
    accessorKey: 'patientId',
    header: () => {
      return <div>Patient ID</div>;
    },
    cell: ({ row }) => {
      return <P>{row.original.patientId}</P>;
    },
  },
  {
    accessorKey: 'doctorsId',
    header: () => {
      return <div>Doctor ID</div>;
    },
    cell: ({ row }) => {
      return <P>{row.original.userId}</P>;
    },
  },
  {
    accessorKey: 'doctorsName',
    header: () => {
      return <div>Doctor</div>;
    },
    cell: ({ row }) => {
      return (
        <P>
          {row.original.userFirstName} {row.original.userLastName}
        </P>
      );
    },
  },
  {
    accessorKey: 'doctorsEmail',
    header: () => {
      return <div>Doctor Email</div>;
    },
    cell: ({ row }) => {
      return <P>{row.original.userEmail}</P>;
    },
  },
  {
    accessorKey: 'startDate',
    header: () => {
      return <div>Start Date</div>;
    },
    cell: ({ row }) => {
      return <P>{row.original.startDate}</P>;
    },
  },
  {
    accessorKey: 'endDate',
    header: () => {
      return <div>End Date</div>;
    },
    cell: ({ row }) => {
      return <P>{row.original.endDate}</P>;
    },
  },
  {
    accessorKey: 'createdAt',
    header: () => {
      return <div>Created At</div>;
    },
    cell: ({ row }) => {
      return <P>{row.original.createdAt}</P>;
    },
  },
];

type Tab = 'transcript' | 'summary' | 'draft';

export const AdminAppointment: React.FC = () => {
  const { appointmentId } = useParams() as { appointmentId: string };
  const [tab, setTab] = useState<Tab>('summary');
  const [summary, setSummary] = useState<Record<string, string> | null>(null);
  const [doctorDraft, setDoctorDraft] = useState<Record<string, string> | null>(
    null,
  );

  const appointmentName = useAppointmentName(appointmentId);

  const { data } = useQuery(GetAdminTenantAppointmentSummaryDocument, {
    variables: {
      appointmentId,
    },
  });

  const { data: latestSummariesData } = useQuery(
    GetLatestSummaryForAppointmentIdDocument,
    {
      variables: {
        appointmentId: appointmentId!,
      },
      skip: !appointmentId,
    },
  );

  const rows = useMemo(() => {
    return [
      {
        name: data?.appointmentsByPk?.name ?? '',
        id: data?.appointmentsByPk?.id ?? '',
        patientId: data?.appointmentsByPk?.patient?.id ?? '',
        userId: data?.appointmentsByPk?.user?.id ?? '',
        userFirstName: data?.appointmentsByPk?.user?.firstName ?? '',
        userLastName: data?.appointmentsByPk?.user?.lastName ?? '',
        userEmail: data?.appointmentsByPk?.user?.email ?? '',
        startDate: formatTimestampToLocale(data?.appointmentsByPk?.startDate),
        endDate: formatTimestampToLocale(data?.appointmentsByPk?.endDate),
        createdAt: formatTimestampToLocale(data?.appointmentsByPk?.createdAt),
      },
    ];
  }, [data]);

  useEffect(() => {
    const incomingSummary =
      latestSummariesData?.combinedSummaries?.[0]?.summary;

    if (incomingSummary) {
      // converting Record<string, any> to Record<string, string>
      const entries = Object.entries(incomingSummary).map(([key, value]) => [
        key,
        typeof value === 'string' ? value : String(value),
      ]);
      setDoctorDraft(Object.fromEntries(entries));
    } else {
      setDoctorDraft(null);
    }
  }, [latestSummariesData]);

  useEffect(() => {
    const incomingSummary = data?.appointmentsByPk?.llmSummaries[0]?.summary;

    if (incomingSummary) {
      // converting Record<string, any> to Record<string, string>
      const entries = Object.entries(incomingSummary).map(([key, value]) => [
        key,
        typeof value === 'string' ? value : String(value),
      ]);
      setSummary(Object.fromEntries(entries));
    } else {
      setSummary(null);
    }
  }, [data]);

  const summaryKeyToSectionTitleMap: Record<string, string> = {
    SUBJECTIVE: 'Subjective',
    OBJECTIVE: 'Objective',
    ASSESSMENT: 'Assessment',
    PLAN: 'Plan',
  };

  return (
    <div>
      <PageTitle>{appointmentName}</PageTitle>
      <DataTable data={rows} columns={columns} />

      <Card className="w-full rounded-xl border-gray-4 min-h-900 mt-5">
        <CardHeader className="space-y-0 p-0 px-5 border-b rounded-t-xl bg-gray-1 h-10 flex flex-row justify-between items-center justify-items-center tracking-normal">
          <div>
            <P>Details</P>
          </div>
        </CardHeader>
        <CardContent>
          <div>
            <div>
              <P>
                Note signed at:{' '}
                {formatTimestampToLocale(
                  data?.appointmentsByPk?.doctorInteraction?.noteSignedAt,
                )}
              </P>
            </div>
            <div>
              <P>
                {data?.appointmentsByPk?.doctorInteraction
                  ?.externalChartUrl && (
                  <a
                    className="text-blue-600"
                    href={
                      data?.appointmentsByPk?.doctorInteraction
                        ?.externalChartUrl
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    View External Chart
                  </a>
                )}
              </P>
            </div>
            <div>
              <P>
                EHR Sync Status:{' '}
                {
                  data?.appointmentsByPk?.doctorInteraction
                    ?.externalEhrSyncStatus
                }
              </P>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className="w-full rounded-xl border-gray-4 min-h-900 mt-5">
        <CardHeader className="space-y-0 p-0 px-5 border-b rounded-t-xl bg-gray-1 h-10 flex flex-row justify-between items-center justify-items-center tracking-normal">
          <div>
            <P>Summary and Transcript</P>
          </div>
        </CardHeader>
        <CardContent>
          <Tabs value={tab}>
            <TabsList className="w-full bg-transparent">
              <TabsTrigger
                value="summary"
                onClick={() => setTab('summary')}
                className="px-10 m-2 bg-gray-4"
              >
                LLM Summary
              </TabsTrigger>
              <TabsTrigger
                value="draft"
                onClick={() => setTab('draft')}
                className="px-10 m-2 bg-gray-4"
              >
                Doctor Draft
              </TabsTrigger>
              <TabsTrigger
                value="transcript"
                onClick={() => setTab('transcript')}
                className="px-10 m-2 bg-gray-4"
              >
                Transcript
              </TabsTrigger>
            </TabsList>
            <TabsContent value="transcript">
              <div className="flex flex-col m-2">
                <div className="flex flex-col gap-2">
                  Number of transcripts:{' '}
                  {data?.appointmentsByPk?.transcripts?.length}
                </div>
                <div className="flex flex-col gap-2">
                  Word count:{' '}
                  {(data?.appointmentsByPk?.transcripts || []).reduce(
                    (totalWords, transcript) => {
                      if (transcript && transcript.transcript) {
                        const words = transcript.transcript.split(/\s+/);
                        return totalWords + words.length;
                      } else {
                        return totalWords;
                      }
                    },
                    0,
                  )}
                </div>
              </div>
              <TranscriptionCard
                transcript={
                  data?.appointmentsByPk?.transcripts
                    .map((t) => t.transcript)
                    .join(' ') ?? ''
                }
                transcriptionInProgress={false}
              />
            </TabsContent>
            <TabsContent value="draft">
              <div className="flex flex-col gap-2">
                {Object.entries(summaryKeyToSectionTitleMap)
                  .filter(([summaryKey]) => doctorDraft?.[summaryKey])
                  .map(([key, sectionTitle]) => {
                    const summaryValue = doctorDraft?.[key];
                    const summaryKey = key as AppointmentSummaryKeys;
                    return (
                      <SummarySectionMarkdown
                        className="summary-section-markdown-container"
                        key={summaryKey}
                        summaryKey={summaryKey}
                        sectionTitle={sectionTitle}
                        content={summaryValue}
                        disabled={true}
                        handleContentChange={noop}
                        billingCodesEnabled={true}
                      />
                    );
                  })}

                {data?.appointmentsByPk?.doctorInteraction?.additionalNotes && (
                  <SummarySectionMarkdown
                    className="summary-section-markdown-container"
                    key="AdditionalNotes"
                    summaryKey={`AdditionalNotes` as any}
                    sectionTitle="Additional Notes"
                    content={
                      data?.appointmentsByPk?.doctorInteraction?.additionalNotes
                    }
                    disabled={true}
                    handleContentChange={noop}
                    billingCodesEnabled={true}
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value="summary">
              <div className="flex flex-col gap-2">
                {Object.entries(summaryKeyToSectionTitleMap)
                  .filter(([summaryKey]) => summary?.[summaryKey])
                  .map(([key, sectionTitle]) => {
                    const summaryValue = summary?.[key];
                    const summaryKey = key as AppointmentSummaryKeys;
                    return (
                      <SummarySectionMarkdown
                        className="summary-section-markdown-container"
                        key={summaryKey}
                        summaryKey={summaryKey}
                        sectionTitle={sectionTitle}
                        content={summaryValue}
                        disabled={true}
                        handleContentChange={noop}
                        billingCodesEnabled={true}
                      />
                    );
                  })}

                {data?.appointmentsByPk?.doctorInteraction?.additionalNotes && (
                  <SummarySectionMarkdown
                    className="summary-section-markdown-container"
                    key="AdditionalNotes"
                    // TODO this should get cleaned up and use a different component
                    summaryKey={`AdditionalNotes` as any}
                    sectionTitle="Additional Notes"
                    content={
                      data?.appointmentsByPk?.doctorInteraction?.additionalNotes
                    }
                    disabled={true}
                    handleContentChange={noop}
                    billingCodesEnabled={true}
                  />
                )}
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>

      <Card className="w-full rounded-xl border-gray-4 min-h-900 mt-5">
        <CardHeader className="space-y-0 p-0 px-5 border-b rounded-t-xl bg-gray-1 h-10 flex flex-row justify-between items-center justify-items-center tracking-normal">
          <div>
            <P>Feedback Details</P>
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-2 m-5">
            <P>
              Number of feedbacks:{' '}
              {data?.appointmentsByPk?.summaryFeedbacks.length || 0}
            </P>
          </div>
          <div className="m-4">
            {Boolean(data?.appointmentsByPk?.summaryFeedbacks.length) && (
              <DataTable
                data={
                  data?.appointmentsByPk?.summaryFeedbacks.map((sf) => ({
                    comment: sf.comment,
                    type: sf.type,
                    createdAt: formatTimestampToLocale(sf.createdAt),
                    tags: (sf.summaryFeedbackTags || []).map(
                      (sft) => sft.feedbackTag.name,
                    ),
                    rating: sf.rating,
                  })) || []
                }
                columns={feedbackColumns}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
