import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-use';

export const SingularEntityRedirect: React.FC<{ entityName: string }> = ({
  entityName,
}) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Navigate
      to={(pathname ?? '').replace(`/${entityName}`, `/${entityName}s`)}
      replace={true}
    />
  );
};
